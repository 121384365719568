import React from 'react';
import { func } from 'prop-types';
import { Container, Row, Col } from '@nutkit/component-grid';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import { Payment, Portfolio } from '@nm-utils-lib-web/routes';
import { Heading } from '@nutkit/component-text';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';

import { ISAAllowance } from '../IsaAllowance';
import LayoutWithSideMenu from '../common/LayoutWithSideMenu';

const TRANSLATION_NAMESPACE = 'dashboard.details';

export const ISADetails = ({ t }) => {
  const { customerUuid } = useGetAuthenticationMetadata();

  return (
    <LayoutWithSideMenu data-qa="isa-details">
      {() => (
        <Container>
          <Row>
            <Col md={{ size: '12' }} lg={{ size: '8', offset: '2' }}>
              <Section tagName={sectionTagNames.DIV} stackSpacing={stackSpacing.XL}>
                <Heading noStack> {t(`${TRANSLATION_NAMESPACE}.isa.heading`)}</Heading>
              </Section>
              <ISAAllowance.Container
                customerId={customerUuid}
                payInNowLink={Payment.paymentPayInNowPath()}
                openNewIsaLink={Portfolio.SISA_OPEN_TRANSFER_PATH}
                data-qa="isa-allowance"
              />
            </Col>
          </Row>
        </Container>
      )}
    </LayoutWithSideMenu>
  );
};

ISADetails.propTypes = {
  t: func.isRequired
};

export default ISADetails;
