import { stringify } from 'qs';
import { Payment, Portfolio, Promotions } from '@nm-utils-lib-web/routes';
import { upperCaseWrapperTypes } from '@nm-customer/common/constants';
import { handleError } from '@nm-customer/common';

import { tabsBySlug, ACTIVE_PAYMENT_TAB_PARAM } from '../constants/TabConstants';
import THUNDERHEAD_WRAPPER_TYPES from '../constants/thunderheadWrapperTypes';

const { LISA, GIA_ISA, PENSION, JISA } = upperCaseWrapperTypes;
const ctaPathMap = {
  Increase_DD: ({ increaseAmount, potId, wrapperType }) => {
    const wrapper = THUNDERHEAD_WRAPPER_TYPES[wrapperType];

    if ([LISA, GIA_ISA].includes(wrapper)) {
      return {
        href: `${global.NutmegConfig.DASHBOARD_HOST}${Payment.paymentsMonthlyPaymentsPath(
          potId
        )}?monthly=${increaseAmount}`
      };
    }

    if (wrapper === JISA) {
      return {
        href: `${Payment.jisaMonthlyPaymentsPath(potId)}?monthly=${increaseAmount}`,
        isInternal: true
      };
    }

    if (wrapper === PENSION) {
      return {
        href: Payment.pensionMonthlyPaymentsPath(potId),
        isInternal: true
      };
    }

    return null;
  },
  One_Off_Payment: ({ potId, wrapperType, paymentTab }) => {
    const wrapper = THUNDERHEAD_WRAPPER_TYPES[wrapperType];
    const activePaymentTab = Object.keys(tabsBySlug).includes(paymentTab) ? paymentTab : undefined;

    if ([LISA, GIA_ISA].includes(wrapper)) {
      return {
        href: `${Payment.paymentPayInNowPath(potId)}${stringify(
          { [ACTIVE_PAYMENT_TAB_PARAM]: activePaymentTab },
          { addQueryPrefix: true }
        )}`
      };
    }

    if (wrapper === JISA) {
      return {
        href: `${Payment.jisaCombinedPaymentPath(potId)}${stringify(
          { [ACTIVE_PAYMENT_TAB_PARAM]: activePaymentTab },
          { addQueryPrefix: true }
        )}`,
        isInternal: true
      };
    }

    return null;
  },
  Invite_Friends: () => ({
    href: `${global.NutmegConfig.DASHBOARD_HOST}${Promotions.REFER_A_FRIEND_DASHBOARD}`
  }),
  New_DD: () => ({
    href: `${global.NutmegConfig.DASHBOARD_HOST}${Payment.paymentsMonthlyPaymentsListPath()}`
  }),
  Open_ISA: () => ({
    href: Portfolio.PRODUCT_SISA_INFORMATION_PATH,
    isInternal: true
  }),
  Transfer_ISA: () => ({
    href: Payment.ISA_TRANSFER_PATH,
    isInternal: true
  }),
  External_Browser: ({ url }) => ({
    href: url,
    isExternal: true
  }),
  Internal_Browser: ({ url }) => ({
    href: url
  }),
  Blog_Index: ({ url }) => ({
    href: url,
    isExternal: true
  })
};

const getCtaPath = configuration => {
  try {
    const { typeDestination, destinations } = configuration;

    return ctaPathMap[typeDestination](destinations);
  } catch (error) {
    const typeDestination = configuration?.typeDestination;

    try {
      handleError(error, `PromptCard: could not generate CTA path with typeDestination: ${typeDestination}`);
    } catch (errorToDiscard) {
      // Nothing to see here
    }

    return null;
  }
};

export default getCtaPath;
