import React, { useMemo } from 'react';
import { string, func } from 'prop-types';
import Loading from '@nutkit/component-loading';
import DownloadButton, { mimeTypes } from '@nutkit/component-download-button';
import { buttonSizes, buttonCtaTypes } from '@nutkit/component-button';
import { unparse } from 'papaparse';
import { useTranslation } from '@nm-utils-lib-web/translations';

import InvestmentList from '../InvestmentList';
import useGetPortfolioHoldings from '../../../../hooks/useGetPortfolioHoldings/useGetPortfolioHoldings';

const INVESTMENTS_FILE_NAME = 'investments.csv';
const POUND_SYMBOL = '\u00A3';
const PERCENT_SYMBOL = '\u0025';
const BOM = '\uFEFF';
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioInvestments';

const InvestmentListActual = ({ customerUuid, onDownloadList, ...otherProps }) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetPortfolioHoldings({
    customerUuid
  });
  const investmentsStringCSV = useMemo(
    () =>
      unparse(
        data.map(({ security, percentage, value, amount, price, name, description }) => ({
          Code: security,
          Name: name,
          'No. Units': amount,
          Price: POUND_SYMBOL + price,
          Value: POUND_SYMBOL + value,
          Allocation: parseFloat((percentage * 100).toFixed(2)) + PERCENT_SYMBOL,
          Description: description
        }))
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.length]
  );
  const investments = useMemo(
    () =>
      data &&
      data.map(({ security: code, percentage: allocation, value, amount, price, ...rest }) => ({
        code,
        allocation: parseFloat((allocation * 100).toFixed(2)),
        actualValues: {
          amount,
          price,
          value
        },
        ...rest
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.length]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <InvestmentList
      error={error}
      title={
        investmentsStringCSV && (
          <DownloadButton
            data-qa="investment-list-actual-download"
            size={buttonSizes.SM}
            cta={buttonCtaTypes.LINK}
            onClick={onDownloadList}
            file={{
              name: INVESTMENTS_FILE_NAME,
              type: mimeTypes.CSV,
              data: BOM + investmentsStringCSV
            }}
          >
            {t(`${TRANSLATION_NAMESPACE}.downloadListLabel`)}
          </DownloadButton>
        )
      }
      investments={investments}
      isActual
      {...otherProps}
    />
  );
};

InvestmentListActual.propTypes = {
  onDownloadList: func,
  customerUuid: string.isRequired
};

InvestmentListActual.defaultProps = {
  onDownloadList: null
};

export default InvestmentListActual;
