import handleError from '../errorHandler';

import basePotViewService from './basePotViewService';

export const getSystemPots = async ({ customerUuid, type }) => {
  const URL = `/v1/customers/${customerUuid}/system_pots?type=${type}`;

  try {
    const { data } = await basePotViewService.get(URL);

    return data;
  } catch (error) {
    handleError(error);
  }
};
