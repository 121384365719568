import React, { useState } from 'react';
import { string } from 'prop-types';
import Panel from '@nutkit/component-panel';
import ErrorBoundary from '@nm-utils-lib-web/error-boundary';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { MetaTitle } from '@nm-utils-lib-web/meta-title';

import PortfolioValuation from '../PortfolioValuation';
import PortfolioFeesBreakdown from '../PortfolioFeesBreakdown';
import PortfolioValuationChart from '../PortfolioValuationChart';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioValue';

const PortfolioValueLayout = ({ customerUuid }) => {
  const { t } = useTranslation();
  const [hasPageError, setPageError] = useState();
  const translationMetaTitle = `${t('global.common.organisation.appTitle')} - ${t(
    'dashboard.common.metaTitles.overallPortfolioValue'
  )}`;

  return (
    <>
      <MetaTitle title={translationMetaTitle} />

      <Panel>
        <ErrorBoundary
          fallback={
            <Notification
              data-qa="portfolio-valuation__error-boundary-notification"
              level={notificationLevels.ERROR}
              dismissable={false}
              alternative
            >
              {t(`${TRANSLATION_NAMESPACE}.error.message`)}
            </Notification>
          }
        >
          <>
            {hasPageError ? (
              <Notification
                data-qa="portfolio-valuation__error-notification"
                level={notificationLevels.ERROR}
                dismissable={false}
                alternative
              >
                {t(`${TRANSLATION_NAMESPACE}.error.message`)}
              </Notification>
            ) : (
              <>
                <PortfolioValuation customerUuid={customerUuid} onError={setPageError} />
                <PortfolioValuationChart customerUuid={customerUuid} onError={setPageError} />
                <PortfolioFeesBreakdown customerUuid={customerUuid} onError={setPageError} />
              </>
            )}
          </>
        </ErrorBoundary>
      </Panel>
    </>
  );
};

PortfolioValueLayout.propTypes = {
  customerUuid: string.isRequired
};

export default PortfolioValueLayout;
