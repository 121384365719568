import { useCallback } from 'react';

const useActions = (actions, dispatch) =>
  useCallback(
    () =>
      Object.keys(actions).reduce((result, current) => {
        const action = actions[current];

        result[current] = (...params) => dispatch(action(...params));

        return result;
      }, {}),
    [actions, dispatch]
  )();

export default useActions;
