import React from 'react';
import { string, func } from 'prop-types';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { ValuationChartWithMarketHighlights, LOCATION_CONTEXTS } from '@nm-portfolio-lib-web/valuation-chart';
import { NoChartData } from '@nm-portfolio-lib-web/common/components';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioValuationChart';

const PortfolioValuationChart = ({ customerUuid, onError }) => {
  const { t } = useTranslation();

  return (
    <ValuationChartWithMarketHighlights
      data-qa="portfolio-valuation__chart-with-highlights"
      customerUuid={customerUuid}
      chartHeight={354}
      noStack
      onError={onError}
      renderNoValuation={() => (
        <NoChartData
          data-qa="portfolio-valuation__no-chart-data"
          label={t(`${TRANSLATION_NAMESPACE}.noChartData.text`)}
        />
      )}
      locationContext={LOCATION_CONTEXTS.PORTFOLIO_DIG_DEEPER}
    />
  );
};

PortfolioValuationChart.propTypes = {
  customerUuid: string.isRequired,
  onError: func.isRequired
};

PortfolioValuationChart.defaultProps = {};

export default PortfolioValuationChart;
