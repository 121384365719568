import React from 'react';
import { func, string } from 'prop-types';
import { Route } from 'react-router-dom';
import { MetaTitle } from '@nm-utils-lib-web/meta-title';
import { ProtectedRoute } from '@nm-utils-lib-web/authentication';
import { useTranslation } from '@nm-utils-lib-web/translations';

export const MetaTitleProtectedRoute = ({ render, titleTranslationKey, ...props }) => {
  const { t } = useTranslation();
  let translationMetaTitle = t('global.common.organisation.appTitle');

  if (titleTranslationKey) {
    translationMetaTitle = `${t('dashboard.common.metaTitles.organisationName')} - ${t(titleTranslationKey)}`;
  }

  return (
    <Route
      {...props}
      render={() => (
        <>
          <MetaTitle title={translationMetaTitle} />
          <ProtectedRoute render={render} />
        </>
      )}
    />
  );
};

MetaTitleProtectedRoute.propTypes = {
  render: func.isRequired,
  titleTranslationKey: string
};

MetaTitleProtectedRoute.defaultProps = {
  titleTranslationKey: undefined
};
