import handleError from '../errorHandler';

import basePotHoldingsService from './basePotHoldingsService';

export const getPortfolioHoldings = async ({ customerUuid }) => {
  const URL = `/customers/${customerUuid}/holdings`;

  try {
    const { data } = await basePotHoldingsService.get(URL);

    return data;
  } catch (error) {
    handleError(error);
  }
};
