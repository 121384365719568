import * as ISA_ALLOWANCE_EVENT_TYPES from './eventTypes';
import * as ISA_ALLOWANCE_EVENT_CATEGORIES from './eventCategories';

const eventMap = {
  [ISA_ALLOWANCE_EVENT_TYPES.ON_SISA_ALLOWANCE_PAY_IN_LINK_CLICKED]: {
    name: ISA_ALLOWANCE_EVENT_TYPES.ON_SISA_ALLOWANCE_PAY_IN_LINK_CLICKED,
    properties: {
      category: ISA_ALLOWANCE_EVENT_CATEGORIES.ISA_ALLOWANCE
    }
  },
  [ISA_ALLOWANCE_EVENT_TYPES.ON_LISA_ALLOWANCE_PAY_IN_LINK_CLICKED]: {
    name: ISA_ALLOWANCE_EVENT_TYPES.ON_LISA_ALLOWANCE_PAY_IN_LINK_CLICKED,
    properties: {
      category: ISA_ALLOWANCE_EVENT_CATEGORIES.ISA_ALLOWANCE
    }
  },
  [ISA_ALLOWANCE_EVENT_TYPES.ON_SISA_ALLOWANCE_OPEN_LINK_CLICKED]: {
    name: ISA_ALLOWANCE_EVENT_TYPES.ON_SISA_ALLOWANCE_OPEN_LINK_CLICKED,
    properties: {
      category: ISA_ALLOWANCE_EVENT_CATEGORIES.ISA_ALLOWANCE
    }
  }
};

export default eventMap;
