import {
  SET_ALLOWANCE,
  SET_INTENT,
  SET_AUTO_FILL,
  SET_AUTO_RENEW,
  SET_TRANSFER_TYPE,
  SET_TRANSFER_OPEN_ISA,
  SET_TRANSFER_DATA
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_INTENT:
      return { ...state, intent: action.payload };
    case SET_ALLOWANCE:
      return { ...state, allowance: action.payload };
    case SET_AUTO_FILL:
      return { ...state, settings: { ...state.settings, autoFill: action.payload } };
    case SET_AUTO_RENEW:
      return { ...state, settings: { ...state.settings, autoRenew: action.payload } };
    case SET_TRANSFER_TYPE:
      return { ...state, transferType: action.payload };
    case SET_TRANSFER_OPEN_ISA:
      return { ...state, openISA: action.payload };
    case SET_TRANSFER_DATA:
      return { ...state, transferData: action.payload };
    default:
      return state;
  }
};

export default reducer;
