import React from 'react';
import { bool, number, string } from 'prop-types';
import Inline, { inlineSpaces } from '@nutkit/component-inline';
import Align, { alignItemsRules, justifyContentRules } from '@nutkit/component-align';
import Image from '@nutkit/component-image';
import Thumbnail, { thumbnailSizes } from '@nutkit/component-thumbnail';
import Amount, { amountStyle, amountSizes, amountTypes } from '@nutkit/component-amount';
import { Text, tagNames, textSizes } from '@nutkit/component-text';

import { PRODUCT_EMPTY_TOTAL_AMOUNT } from '../../constants/TotalAmount';

export const ProductSelectOptionTitle = ({ imageSource, total, label, isPresent }) => (
  <Align alignItems={alignItemsRules.CENTER} justifyContent={justifyContentRules.SPACE_BETWEEN}>
    <Inline space={inlineSpaces.SM}>
      <Thumbnail size={thumbnailSizes.XS}>
        <Image src={imageSource} alt={label} />
      </Thumbnail>
      <Text tagName={tagNames.SPAN} textSize={textSizes.XS}>
        {label}
      </Text>
      {''}
    </Inline>
    {isPresent ? (
      <Text tagName={tagNames.SPAN} textSize={textSizes.XS}>
        <Amount
          maxDecimals={0}
          style={amountStyle.CURRENCY}
          value={total}
          type={amountTypes.CONTRIBUTION}
          size={amountSizes.XS}
          noLineHeight
          noColor
        />
      </Text>
    ) : (
      PRODUCT_EMPTY_TOTAL_AMOUNT
    )}
  </Align>
);

ProductSelectOptionTitle.propTypes = {
  imageSource: string.isRequired,
  total: number.isRequired,
  label: string.isRequired,
  isPresent: bool.isRequired
};
