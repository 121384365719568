import React from 'react';
import { PropTypes } from 'prop-types';

import { NAVIGATION_EVENTS as EVENT } from '../constants';

const TRANSLATION_NAMESPACE = 'common.controls';

/*
 * Component that abstracts away from consumers of journeyNavigation
 * the xstate implementation.
 * Note that you can always overwrite these default actions in the component
 * <JourneyNavigation {...this.props.journeyNavigation} continueButton={continueButton}/>
 */
const withJourneyNavigation = WrappedComponent => {
  let wrapped = props => {
    const { location, match, history, journey } = props;
    const { send, ...journeyProps } = journey;
    /*
     * We construct the context in which the xstate event took place
     * thus we are serving to xstate the container state,
     */
    const context = { location, match, history, ...journeyProps };
    const navigationDefault = {
      continueButton: {
        label: props.t(`${TRANSLATION_NAMESPACE}.continueButton`),
        onClick: () => send(EVENT.CONTINUE, context)
      },
      backButton: {
        label: props.t(`${TRANSLATION_NAMESPACE}.backButton`),
        onClick: () => send(EVENT.BACK, context)
      },
      showErrorMessage: props.errorMessage
    };

    return <WrappedComponent journeyNavigation={navigationDefault} {...props} />;
  };

  wrapped.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    match: PropTypes.shape({ isExact: PropTypes.bool, path: PropTypes.string }).isRequired,
    journey: PropTypes.shape({ send: PropTypes.func }).isRequired,
    errorMessage: PropTypes.string
  };
  wrapped.defaultProps = {
    errorMessage: ''
  };

  return wrapped;
};

withJourneyNavigation.propTypes = {
  WrappedComponent: PropTypes.element.isRequired
};
export default withJourneyNavigation;
