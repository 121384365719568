import React from 'react';
import { string } from 'prop-types';
import Panel from '@nutkit/component-panel';
import { Heading, headingLevels } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { MetaTitle } from '@nm-utils-lib-web/meta-title';

import DetailedPortfolioAllocation from '../DetailedPortfolioAllocation';
import InvestmentListActual from '../InvestmentListActual';
import { PORTFOLIO_DIG_DEEPER_EVENTS } from '../../events';

const trackDigDeeperShowMoreLess = PORTFOLIO_DIG_DEEPER_EVENTS.Dig_Deeper_Investments_Show_More_Less;
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioInvestments';

const PortfolioAllocationContainer = ({ customerUuid }) => {
  const { t } = useTranslation();
  const translationMetaTitle = `${t('global.common.organisation.appTitle')} - ${t(
    'dashboard.common.metaTitles.overallPortfolioAllocation'
  )}`;

  return (
    <>
      <MetaTitle title={translationMetaTitle} />

      <DetailedPortfolioAllocation customerUuid={customerUuid} />
      <Panel>
        <Heading data-qa="portfolio-web__investment-list-title" level={headingLevels.TWO}>
          {t(`${TRANSLATION_NAMESPACE}.heading`)}
        </Heading>
        <InvestmentListActual customerUuid={customerUuid} onListExpand={() => trackDigDeeperShowMoreLess()} />
      </Panel>
    </>
  );
};

export default PortfolioAllocationContainer;

PortfolioAllocationContainer.propTypes = {
  customerUuid: string.isRequired
};
