import React from 'react';
import { func, bool, string, element } from 'prop-types';
import useGetHeadroom from '@nm-portfolio-lib-web/common/hooks/useGetHeadroom';
import { Trans } from '@nm-utils-lib-web/translations';
import Amount, { amountStyle } from '@nutkit/component-amount';
import Bold, { boldWeights } from '@nutkit/component-bold';
import { Text } from '@nutkit/component-text';

import { getSISAEmptyStateDescriptionErrorKey, getSISAEmptyStateDescriptionSuccessKey } from '../../../helpers';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.sisaTab';

const EmptyStateDescription = ({ t, eligibleGIA, eligibleSISA, userUuid, learMore, onError }) => {
  const { data: headroomData, error: headroomError } = useGetHeadroom({
    customerUuid: userUuid,
    onError
  });

  if (!eligibleSISA) {
    return <Text>{t(`${TRANSLATION_NAMESPACE}.emptyState.giaOnly.description`)}</Text>;
  }

  if (headroomError) {
    return <Text>{t(getSISAEmptyStateDescriptionErrorKey(TRANSLATION_NAMESPACE, eligibleGIA, eligibleSISA))}</Text>;
  }

  return (
    <Text>
      {!!headroomData && (
        <Trans
          i18nKey={getSISAEmptyStateDescriptionSuccessKey(TRANSLATION_NAMESPACE, eligibleGIA, eligibleSISA)}
          components={[
            <Bold weight={boldWeights.BOLD}>
              {' '}
              <Amount
                value={headroomData.currentDefaultAllowances.isa}
                style={amountStyle.CURRENCY}
                bolderWeight
                minDecimals={0}
              />
            </Bold>
          ]}
        />
      )}{' '}
      {learMore}
    </Text>
  );
};

EmptyStateDescription.propTypes = {
  t: func.isRequired,
  learMore: element,
  eligibleGIA: bool.isRequired,
  eligibleSISA: bool.isRequired,
  userUuid: string.isRequired,
  onError: func.isRequired
};

EmptyStateDescription.defaultProps = {
  learMore: undefined
};

export default EmptyStateDescription;
