export const parseSearchParameters = searchString => {
  if (searchString === '') {
    return null;
  }

  return searchString
    .slice(1)
    .split('&')
    .map(pair => pair.split('='))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};
