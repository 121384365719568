import React from 'react';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import { GenericError } from '@nm-ui-shared-lib-web/generic-error';
import { Portfolio } from '@nm-utils-lib-web/routes';
import Loading from '@nutkit/component-loading';
import { useGetUser } from '@nm-customer/common/hooks';
import { useTranslation } from '@nm-utils-lib-web/translations';

const withUserDetails = WrappedComponent => {
  const WithUserDetails = props => {
    const { t } = useTranslation();
    const { customerUuid } = useGetAuthenticationMetadata();
    const { data: userDetails, isLoading, error } = useGetUser({ customerUuid });

    if (isLoading) {
      return <Loading />;
    }

    if (error && !isLoading) {
      return <GenericError data-qa="withUserDetails__generic-error" headerLogoHref={Portfolio.DASHBOARD_PATH} />;
    }

    return <WrappedComponent {...props} t={t} userDetails={userDetails} />;
  };

  return WithUserDetails;
};

export default withUserDetails;
