import { withTranslation } from '@nm-utils-lib-web/translations';
import flowright from 'lodash.flowright';
import { withErrorBoundary } from '@nm-utils-lib-web/error-boundary';

import PortfolioSummary from './PortfolioSummary';

export default flowright(
  withErrorBoundary({ contextualDescription: 'Boundary error in Portfolio Summary' }),
  withTranslation()
)(PortfolioSummary);
