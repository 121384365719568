import {
  OPEN_DASHBOARD_PROMPT_CARD,
  DISPLAY_DASHBOARD_PROMPT_CARD,
  DISMISS_DASHBOARD_PROMPT_CARD
} from './journeyEvents';

const openDashboardPromptCardPayload = ({ eventProperties, userProperties }) => ({
  name: OPEN_DASHBOARD_PROMPT_CARD,
  properties: eventProperties,
  options: {
    context: {
      traits: userProperties
    }
  }
});
const displayDashboardPromptCardPayload = ({ eventProperties, userProperties }) => ({
  name: DISPLAY_DASHBOARD_PROMPT_CARD,
  properties: eventProperties,
  options: {
    context: {
      traits: userProperties
    }
  }
});
const dismissDashboardPromptCardPayload = ({ eventProperties, userProperties }) => ({
  name: DISMISS_DASHBOARD_PROMPT_CARD,
  properties: eventProperties,
  options: {
    context: {
      traits: userProperties
    }
  }
});

export { openDashboardPromptCardPayload, displayDashboardPromptCardPayload, dismissDashboardPromptCardPayload };
