import qs from 'qs';

import {
  NAVIGATION_ACTIONS as ACTIONS,
  JOURNEY_INTERNAL_STATES as JOURNEY_STATE,
  NAVIGATION_EVENTS
} from '../constants';
import ROUTES from '../../constants/routes';
import { getStateProperty } from '../../hooks/useStore/storeMap';
import { getQueryParams } from '../../utils/queryParams';
import { MANUAL_TRANSFER } from '../../constants/queryParams';

export const DEFAULT_GUARD = () => true;

/*
 * addTransition() is used to add one transition rule in the xstate machine
 * First argument is the target, the guard is defaulted to true, so we can specify just
 * the target for unconditional transitions. Finally we can add as many actions as we want
 */

export const addTransition = (view, guard = DEFAULT_GUARD, ...additionalActions) => {
  const sendGuard = (ctx, evt, meta) => guard(evt, meta, ctx);

  return {
    target: view,
    cond: sendGuard,
    actions: [ACTIONS.CHANGE_VIEW, ACTIONS.UPDATE_LOCATION_CONTEXT, ...additionalActions]
  };
};

export const addRedirectionTransition = (view, guard = DEFAULT_GUARD, ...additionalActions) => {
  const sendGuard = (ctx, evt, meta) => guard(evt, meta, ctx);

  return {
    target: view,
    cond: sendGuard,
    actions: [ACTIONS.REDIRECT_VIEW, ACTIONS.UPDATE_LOCATION_CONTEXT, ...additionalActions]
  };
};

export const getInitialState = () => {
  // For each known route, we match the current location
  let routesRules = Object.entries(ROUTES).map(([viewID, value]) => ({
    cond: ctx => {
      const { baseUrl = '', location } = ctx;

      return location.pathname === `${baseUrl}${value.path}`;
    },
    target: viewID
  }));

  // No match is found by route, redirect also the machine state.
  routesRules.push({ target: JOURNEY_STATE.REDIRECTING_BACKWARD });

  return routesRules;
};

export const createQueryString = (params, state) => {
  if (!params || params.length < 1) throw 'no params was provided';

  return params.reduce((result, param, index) => {
    const propertyValue = encodeURIComponent(getStateProperty(param, state));

    result += `${param}=${propertyValue}`;

    if (index < params.length - 1) result += '&';

    return result;
  }, '');
};

export const getNextQueryString = (updateParams, removeParams, event, queryString = window.location.search) => {
  const { type, state } = event;
  const nextParams = qs.parse(queryString, { ignoreQueryPrefix: true });

  if (type === NAVIGATION_EVENTS.CONTINUE) {
    removeParams &&
      removeParams.forEach(param => {
        delete nextParams[param];
      });

    updateParams &&
      updateParams.forEach(param => {
        nextParams[param] = getStateProperty(param, state);
      });
  }

  return qs.stringify(nextParams, { addQueryPrefix: false });
};

export const isTransferResponseAutomated = transferData => !transferData.fileId && transferData.status;

export const isManualTransfer = search => !!search && getQueryParams(search)[MANUAL_TRANSFER] === 'true';

export const appendParams = (queryStr, params = {}) =>
  qs.stringify(Object.assign(qs.parse(queryStr, { ignoreQueryPrefix: true }), params));
