import React from 'react';
import { Switch } from 'react-router-dom';
import { shape, func, string, bool } from 'prop-types';
import { Layout } from '@nutkit/layouts';
import { ScrollToTop } from '@nm-utils-lib-web/scroll-to';
import { ProductSelect } from '@nm-portfolio-lib-web/product-select';
import { Portfolio } from '@nm-utils-lib-web/routes';

import { isOnboarding } from '../../utils/isOnboarding';
import Header from '../Header';
import Footer from '../Footer';
import MetaTitleRoute from '../MetaTitleRoute';

const TRANSLATION_NAMESPACE = 'dashboard.common';

const ProductSelection = props => {
  const { isLISATransferEnabled, location, userDetails, shouldShowWealthServicesBanner } = props;

  return (
    <ScrollToTop>
      <Header isOnboarding={isOnboarding(location)} />

      <Layout>
        <Switch>
          <MetaTitleRoute
            exact
            path={Portfolio.PRODUCT_SELECTION_PATH}
            titleTranslationKey={`${TRANSLATION_NAMESPACE}.metaTitles.productSelection`}
            render={() => (
              <ProductSelect.ProductSelection
                {...props}
                isOnboarding={isOnboarding(location)}
                userUuid={userDetails.userUuid}
                data-qa="product-selection"
                shouldShowWealthServicesBanner={shouldShowWealthServicesBanner}
              />
            )}
          />
          <MetaTitleRoute
            exact
            titleTranslationKey={`${TRANSLATION_NAMESPACE}.metaTitles.informationSISA`}
            path={Portfolio.PRODUCT_SISA_INFORMATION_PATH}
            render={() => <ProductSelect.InformationSISA {...props} data-qa="information-sisa" />}
          />
          <MetaTitleRoute
            exact
            titleTranslationKey={`${TRANSLATION_NAMESPACE}.metaTitles.informationGIA`}
            path={Portfolio.PRODUCT_GIA_INFORMATION_PATH}
            render={() => <ProductSelect.InformationGIA {...props} data-qa="information-gia" />}
          />
          <MetaTitleRoute
            exact
            titleTranslationKey={`${TRANSLATION_NAMESPACE}.metaTitles.informationJISAContainer`}
            path={Portfolio.PRODUCT_JISA_INFORMATION_PATH}
            render={() => <ProductSelect.InformationJISAContainer {...props} data-qa="information-jisa" />}
          />
          <MetaTitleRoute
            exact
            titleTranslationKey={`${TRANSLATION_NAMESPACE}.metaTitles.informationLISA`}
            path={Portfolio.PRODUCT_LISA_INFORMATION_PATH}
            render={() => (
              <ProductSelect.InformationLISA
                {...props}
                isLisaTransfersEnabled={isLISATransferEnabled}
                data-qa="information-lisa"
              />
            )}
          />
        </Switch>
      </Layout>

      <Footer />
    </ScrollToTop>
  );
};

ProductSelection.propTypes = {
  history: shape({
    push: func
  }).isRequired,
  location: shape({
    search: string
  }).isRequired,
  userDetails: shape({
    userUuid: string,
    nino: string
  }).isRequired,
  shouldShowWealthServicesBanner: bool,
  isLISATransferEnabled: bool
};

ProductSelection.defaultProps = {
  shouldShowWealthServicesBanner: false,
  isLISATransferEnabled: false
};

export default ProductSelection;
