import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { NOT_FOUND } from 'http-status-codes';
import ErrorBoundary from '@nm-utils-lib-web/error-boundary';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Section, { stackSpacing } from '@nutkit/component-section';
import { WRAPPER_TYPES as wrapperTypes, INVESTING_FOR_INCOME, CASH } from '@nm-pot/common/constants';
import Navigation from '@nutkit/component-navigation';
import { useGetCurrentProducts } from '@nm-pot/common/hooks';
import useGetEligibleProducts from '@nm-portfolio-lib-web/common/hooks/useGetEligibleProducts';
import prismicImageUUIDs from '@nm-portfolio-lib-web/product-select/constants/imagesUuid';
import useGetISAStatus from '@nm-portfolio-lib-web/common/hooks/useGetISAStatus';
import { ISA_STATUS_ERRORS } from '@nm-portfolio-lib-web/common/services/serviceConstants';
import Loading from '@nutkit/component-loading';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import { Portfolio } from '@nm-utils-lib-web/routes';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { IncomingTransfers } from '@nm-payments/transfers/components/IncomingTransfers';

import { parseSearchParameters } from '../../helpers/parseSearchParameters';
import useGetSystemPots from '../../hooks/useGetSystemPots/useGetSystemPots';
import { INCOME_CASH } from '../../constants/systemPotTypes';

import {
  isTabAvailable,
  getEligibleProducts,
  getTabsSortComparator,
  getSISATabLabelKey,
  isEligible,
  isEligibilityUnknown,
  getTotalByWrapperType,
  getAllTabsTotalValue
} from './helpers';
import { useAvailableDrafts } from './hooks';
import SISATab from './components/SISATab';
import JISATab from './components/JISATab';
import LISATab from './components/LISATab';
import PensionTab from './components/PensionTab';
import IncomeTab from './components/IncomeTab';
import CashPotsSection from './components/CashPotsSection';
import DraftPotsSection from './components/DraftPotsSection';
import ProductSelect from './components/ProductSelect';
import { ProductNavigationItem } from './components/ProductNavigationItem';
import InvestTowardsNewGoal from './components/InvestTowardsNewGoal';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';
const ACTIVE_PRODUCT_QUERY_PARAM = 'active-product-tab';
const ALL_TAB_ID = 'all';
const INCOME_TAB = 'income';
const baseProductTabs = [
  {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: wrapperTypes.SISA }),
    component: SISATab,
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET][wrapperTypes.SISA],
    id: wrapperTypes.SISA
  },
  {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: wrapperTypes.LISA }),
    component: LISATab,
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET][wrapperTypes.LISA],
    id: wrapperTypes.LISA
  },
  {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: wrapperTypes.JISA }),
    component: JISATab,
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET][wrapperTypes.JISA],
    id: wrapperTypes.JISA
  },
  {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: wrapperTypes.PENSION }),
    component: PensionTab,
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET][wrapperTypes.PENSION],
    id: wrapperTypes.PENSION
  }
];

const ProductList = ({ t, userUuid, location, shouldShowLisaTransfersInTab }) => {
  const isDraftPotsFlagEnabled = useFlag(Flags.DASHBOARD_DRAFT_POTS);
  const isAllProductsTabFlagEnabled = useFlag(Flags.ALL_PRODUCTS_TAB);
  const isLISATransferEnabled = useFlag(Flags.LISA_TRANSFERS);
  const isIncomePortfolioEnabled = useFlag(Flags.FE_INCOME_PORTFOLIOS_EI);
  const { matchesCondition: isLessThanMediumBreakpoint } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);
  const { isLoading: productsLoading, error: productsError, getPots, ...allProducts } = useGetCurrentProducts({
    customerUuid: userUuid,
    useAllStatusesInRequest: true
  });
  const {
    isLoading: incomeCashSystemPotLoading,
    error: incomeCashSystemPotError,
    data: incomeCashSystemPot
  } = useGetSystemPots({
    customerUuid: userUuid,
    type: INCOME_CASH,
    shouldMakeRequest: isIncomePortfolioEnabled
  });
  const products = {
    ...allProducts,
    sisa: {
      ...allProducts.sisa,
      data: allProducts.sisa?.data.filter(({ status }) => status !== 'SAVED')
    }
  };

  if (!isIncomePortfolioEnabled) {
    products.sisa.data = products.sisa?.data?.filter(
      ({ investmentStrategy }) => investmentStrategy !== INVESTING_FOR_INCOME
    );
  }

  let productTabs = baseProductTabs;

  if (isIncomePortfolioEnabled) {
    productTabs = [
      {
        url: Portfolio.getDashboardWithParamsPath({ activeProductTab: INCOME_TAB }),
        component: IncomeTab,
        id: INCOME_TAB
      },
      ...productTabs
    ];
    const incomeProducts = products.sisa?.data.filter(
      ({ investmentStrategy }) => investmentStrategy === INVESTING_FOR_INCOME
    );
    const cashProducts = products.sisa?.data.filter(({ investmentStrategy }) => investmentStrategy === CASH);
    const sisaProducts = products.sisa?.data.filter(
      ({ investmentStrategy }) => investmentStrategy !== INVESTING_FOR_INCOME && investmentStrategy !== CASH
    );

    products.sisa = { isPresent: !!sisaProducts.length, data: sisaProducts };
    products.income = { isPresent: !!incomeProducts.length, data: incomeProducts };
    products.cash = { isPresent: !!cashProducts.length, data: cashProducts };
  }
  const { isLoading: draftsLoading, drafts, error: draftsError, getDrafts } = useAvailableDrafts({
    customerUuid: userUuid,
    shouldMakeRequest: isDraftPotsFlagEnabled
  });
  const {
    data: eligibleProductsData,
    error: eligibleProductsError,
    isLoading: eligibleProductsLoading
  } = useGetEligibleProducts({ customerUuid: userUuid, isLISATransferEnabled });
  const { data: isaStatusData, error: isaStatusError, isaStatus, isLoading: isaStatusLoading } = useGetISAStatus({
    customerUuid: userUuid
  });

  if (
    eligibleProductsLoading ||
    (productsLoading && !productsError) ||
    (isaStatusLoading && !isaStatusError) ||
    (incomeCashSystemPotLoading && !incomeCashSystemPotError) ||
    (draftsLoading && !drafts && !draftsError)
  ) {
    return <Loading data-qa="product-list__loading" />;
  }

  if (productsError || (isaStatusError && isaStatusError !== ISA_STATUS_ERRORS[NOT_FOUND])) {
    return (
      <Notification level={notificationLevels.ERROR} dismissable={false} data-qa="product-list__error-notification">
        {t('dashboard.common.error.defaultText')}
      </Notification>
    );
  }

  const statuses = {
    [wrapperTypes.SISA]: isaStatus,
    [wrapperTypes.LISA]: isaStatusData && isaStatusData.byWrapper && isaStatusData.byWrapper[wrapperTypes.LISA]
  };
  const eligibleProducts = getEligibleProducts(eligibleProductsData, eligibleProductsError);
  const visibleDrafts = isEligibilityUnknown(eligibleProducts) ? undefined : drafts;
  const availableTabs = productTabs
    .filter(item => isTabAvailable(item.id, eligibleProducts, products, statuses))
    .sort(getTabsSortComparator(products, visibleDrafts))
    .map(item => {
      return {
        ...item,
        total: getTotalByWrapperType(products[item.id].data),
        isPresent: products[item.id].isPresent,
        label: t(
          `${TRANSLATION_NAMESPACE}.${
            item.id === wrapperTypes.SISA ? getSISATabLabelKey(eligibleProducts, products, isaStatus) : item.id
          }.label`
        )
      };
    });
  const allTab = {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: ALL_TAB_ID }),
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET].all,
    id: ALL_TAB_ID,
    label: t(`${TRANSLATION_NAMESPACE}.all.label`),
    total: getAllTabsTotalValue(availableTabs),
    isPresent: true
  };

  if (!availableTabs.length) {
    return eligibleProductsError ? (
      <Notification
        level={notificationLevels.ERROR}
        dismissable={false}
        data-qa="product-list__error-notification-eligibility"
      >
        {t('dashboard.common.error.defaultText')}
      </Notification>
    ) : null;
  }

  if (availableTabs.length > 1 && isAllProductsTabFlagEnabled) {
    availableTabs.unshift(allTab);
  }

  const searchTabId = location.search && parseSearchParameters(location.search)[ACTIVE_PRODUCT_QUERY_PARAM];
  const { id: activeTabId, component: ActiveTabComponent } =
    availableTabs.find(item => item.id === searchTabId) || availableTabs[0];

  return (
    <ErrorBoundary
      fallback={
        <Notification level={notificationLevels.ERROR} dismissable={false}>
          {t('dashboard.common.error.defaultText')}
        </Notification>
      }
      contextualDescription="[Dashboard] Product List – Unable to render products"
    >
      {availableTabs.length > 1 &&
        (isLessThanMediumBreakpoint ? (
          <ProductSelect
            data-qa="product-list__navigation-select"
            products={availableTabs}
            selectedProductId={activeTabId}
          />
        ) : (
          <Navigation data-qa="product-list__navigation">
            {availableTabs.map(tab => (
              <ProductNavigationItem
                isActive={tab.id === activeTabId}
                key={tab.label}
                data-qa={`navigation-item__${tab.id}`}
                {...tab}
              />
            ))}
          </Navigation>
        ))}
      <Section data-qa={`product-list__${activeTabId}`}>
        {activeTabId === ALL_TAB_ID ? (
          <>
            <IncomingTransfers wrapperType={null} customerUuid={userUuid} />
            {availableTabs
              .filter(tab => tab.id !== ALL_TAB_ID)
              .map(({ component: ProductComponent, id }) => (
                <Section stackSpacing={stackSpacing.LG} key={id}>
                  <ProductComponent
                    pots={products[id].data}
                    systemPot={isIncomePortfolioEnabled ? incomeCashSystemPot.systemPots[0] : undefined}
                    isPresent={products[id].isPresent}
                    drafts={
                      !isIncomePortfolioEnabled ? visibleDrafts && visibleDrafts[id] && visibleDrafts[id].data : []
                    }
                    isDraftPresent={
                      !isIncomePortfolioEnabled && visibleDrafts && visibleDrafts[id] && visibleDrafts[id].isPresent
                    }
                    updatePots={getPots}
                    updateDrafts={getDrafts}
                    userUuid={userUuid}
                    eligibilityUnknown={isEligibilityUnknown(eligibleProducts)}
                    isEligible={isEligible(eligibleProducts, id)}
                    {...(id === wrapperTypes.SISA
                      ? {
                          eligibleSISA: isEligible(eligibleProducts, wrapperTypes.SISA),
                          eligibleGIA: isEligible(eligibleProducts, wrapperTypes.GIA),
                          isaStatus,
                          tabLabelKey: getSISATabLabelKey(eligibleProducts, products, isaStatus)
                        }
                      : {})}
                    withHeading={!isIncomePortfolioEnabled}
                    withGroupHeading={isIncomePortfolioEnabled && (id === wrapperTypes.SISA || id === INCOME_TAB)}
                    shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
                    onError={() => {}}
                  />
                </Section>
              ))}
            {isIncomePortfolioEnabled && (
              <>
                <Section stackSpacing={stackSpacing.LG}>
                  <CashPotsSection userUuid={userUuid} pots={products.cash?.data} />
                </Section>
                <Section stackSpacing={stackSpacing.LG}>
                  <DraftPotsSection userUuid={userUuid} drafts={visibleDrafts} updateDrafts={getDrafts} />
                </Section>
              </>
            )}
            <InvestTowardsNewGoal
              userUuid={userUuid}
              isPensionEligible={isEligible(eligibleProducts, wrapperTypes.PENSION)}
              isPensionPresent={products[wrapperTypes.PENSION]?.isPresent}
              eligibilityUnknown={isEligibilityUnknown(eligibleProducts)}
              eligibleSISA={isEligible(eligibleProducts, wrapperTypes.SISA)}
              eligibleGIA={isEligible(eligibleProducts, wrapperTypes.GIA)}
              isLisaPresent={products[wrapperTypes.LISA]?.isPresent}
              isLisaDraftPresent={
                visibleDrafts && visibleDrafts[wrapperTypes.LISA] && visibleDrafts[wrapperTypes.LISA]?.isPresent
              }
              isLisaEligible={isEligible(eligibleProducts, wrapperTypes.LISA)}
              isJisaEligible={isEligible(eligibleProducts, wrapperTypes.JISA)}
              shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
            />
          </>
        ) : (
          <ActiveTabComponent
            pots={products[activeTabId].data}
            isPresent={products[activeTabId].isPresent}
            drafts={
              !isIncomePortfolioEnabled
                ? visibleDrafts && visibleDrafts[activeTabId] && visibleDrafts[activeTabId].data
                : []
            }
            isDraftPresent={
              !isIncomePortfolioEnabled &&
              visibleDrafts &&
              visibleDrafts[activeTabId] &&
              visibleDrafts[activeTabId].isPresent
            }
            updatePots={getPots}
            updateDrafts={getDrafts}
            userUuid={userUuid}
            eligibilityUnknown={isEligibilityUnknown(eligibleProducts)}
            isEligible={isEligible(eligibleProducts, activeTabId)}
            {...(activeTabId === wrapperTypes.SISA
              ? {
                  eligibleSISA: isEligible(eligibleProducts, wrapperTypes.SISA),
                  eligibleGIA: isEligible(eligibleProducts, wrapperTypes.GIA),
                  isaStatus,
                  tabLabelKey: getSISATabLabelKey(eligibleProducts, products, isaStatus)
                }
              : {})}
            shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
            onError={() => {}}
          />
        )}
      </Section>
    </ErrorBoundary>
  );
};

ProductList.propTypes = {
  t: func.isRequired,
  userUuid: string.isRequired,
  location: shape({
    search: string
  }).isRequired,
  shouldShowLisaTransfersInTab: bool.isRequired
};

export default ProductList;
