import qs from 'qs';

const convertTypes = {
  number: val => Number(val),
  string: val => String(val),
  object: val => qs.parse(val, { ignoreQueryPrefix: true }),
  boolean: val => val == 'true',
  array: val => val.split(',')
};

export default convertTypes;
