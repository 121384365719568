import {
  DISMISS_FEATURE_INTRO,
  DISPLAY_FEATURE_INTRO,
  CLICK_CTA_PRIMARY_FEATURE_INTRO,
  CLICK_CTA_SECONDARY_FEATURE_INTRO
} from './journeyEvents';

const dismissFeatureIntroPayload = ({ eventProperties, userProperties }) => ({
  name: DISMISS_FEATURE_INTRO,
  properties: eventProperties,
  options: {
    context: {
      traits: userProperties
    }
  }
});
const displayFeatureIntroPayload = ({ eventProperties, userProperties }) => ({
  name: DISPLAY_FEATURE_INTRO,
  properties: eventProperties,
  options: {
    context: {
      traits: userProperties
    }
  }
});
const clickCtaPrimaryFeatureIntroPayload = ({ eventProperties, userProperties }) => ({
  name: CLICK_CTA_PRIMARY_FEATURE_INTRO,
  properties: eventProperties,
  options: {
    context: {
      traits: userProperties
    }
  }
});
const clickCtaSecondaryFeatureIntroPayload = ({ eventProperties, userProperties }) => ({
  name: CLICK_CTA_SECONDARY_FEATURE_INTRO,
  properties: eventProperties,
  options: {
    context: {
      traits: userProperties
    }
  }
});

export {
  dismissFeatureIntroPayload,
  displayFeatureIntroPayload,
  clickCtaPrimaryFeatureIntroPayload,
  clickCtaSecondaryFeatureIntroPayload
};
