// @TODO - add tests and move to utils-lib-web and refactor <Amount/>
// @TODO - check if the function duplicated in the other places

import { amountStyle } from '@nutkit/component-amount';
import { getTokens } from '@nutkit/react-utils';

function getAmount(value) {
  const tokens = getTokens();

  return Number(value).toLocaleString(tokens.NK_AMOUNT_LOCALE, {
    style: amountStyle.CURRENCY,
    currency: tokens.NK_AMOUNT_CURRENCY,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  });
}

export default getAmount;
