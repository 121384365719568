import React from 'react';
import { bool, number, string, shape } from 'prop-types';
import { buttonCtas, linkSizes } from '@nutkit/component-link';
import Align from '@nutkit/component-align';
import Button from '@nutkit/component-button';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import Amount, { amountStyle, amountSizes } from '@nutkit/component-amount';
import { Text, textStyles, textAligns } from '@nutkit/component-text';
import { Col } from '@nutkit/component-grid';
import Box from '@nutkit/component-box';
import { Payment, Portfolio } from '@nm-utils-lib-web/routes';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { useTokens } from '@nutkit/react-utils';

import { handleTrackOpenLink, handleTrackPayInLink } from '../helpers/tracking';

const TRANSLATION_NAMESPACE = 'dashboard.isaAllowance.contributions';

const ISAContributionsBox = ({ wrapperType, isCurrentPeriod, headroomData, untilJISAHeld }) => {
  const { t } = useTranslation();
  const { tokens } = useTokens();
  const dataQa = `ISA-contributions__Box-${wrapperType}`;

  return (
    <Col md="6" data-qa={dataQa}>
      <Box
        stripeColor={wrapperType === wrapperTypes.SISA ? tokens.NK_WRAPPER_SISA_COLOR : tokens.NK_WRAPPER_LISA_COLOR}
        noStack={!isCurrentPeriod && !untilJISAHeld}
      >
        <Text textStyle={textStyles.TEXT_2} noStack={isCurrentPeriod && !!headroomData} data-qa={`${dataQa}-title`}>
          {t(`${TRANSLATION_NAMESPACE}.${wrapperType}.boxTitle`)}
        </Text>

        {headroomData ? (
          <Amount
            value={headroomData.contributions}
            size={amountSizes.MD}
            style={amountStyle.CURRENCY}
            data-qa={`${dataQa}-current-period-contributions`}
            minDecimals={0}
          />
        ) : null}

        {headroomData && isCurrentPeriod ? (
          <Text textStyle={textStyles.TEXT_2} data-qa={`${dataQa}-remaining-text`} noStack={!isCurrentPeriod}>
            <Trans
              components={[
                <Amount
                  value={headroomData.headroom}
                  style={amountStyle.CURRENCY}
                  size={amountSizes.XS}
                  data-qa={`${dataQa}-remaining-amount`}
                  minDecimals={0}
                />
              ]}
              i18nKey={`${TRANSLATION_NAMESPACE}.contributionRemainingText`}
            />
          </Text>
        ) : null}

        {headroomData && headroomData.headroom > 0 && isCurrentPeriod && (
          <Align xs={{ textAlign: textAligns.RIGHT }}>
            <LinkWithTracking
              eventPayload={handleTrackPayInLink(wrapperType)}
              size={linkSizes.SM}
              button
              buttonCta={buttonCtas.PRIMARY}
              href={Payment.paymentPayInNowPath()}
              data-qa={`${dataQa}-pay-in-button`}
            >
              {t(`${TRANSLATION_NAMESPACE}.payInLabel`)}
            </LinkWithTracking>
          </Align>
        )}

        <Align xs={{ textAlign: textAligns.RIGHT }}>
          {wrapperType === wrapperTypes.SISA && !headroomData ? (
            <LinkWithTracking
              isInternal
              eventPayload={handleTrackOpenLink()}
              size={linkSizes.SM}
              button
              buttonCta={buttonCtas.PRIMARY}
              href={Portfolio.SISA_OPEN_TRANSFER_PATH}
              data-qa={`${dataQa}-open-button`}
            >
              {t(`${TRANSLATION_NAMESPACE}.${wrapperType}.buttonValue`)}
            </LinkWithTracking>
          ) : null}

          {wrapperType === wrapperTypes.LISA && !headroomData ? (
            <Button size={linkSizes.SM} disabled data-qa={`${dataQa}-open-button-disabled`}>
              {t(`${TRANSLATION_NAMESPACE}.${wrapperType}.buttonValue`)}
            </Button>
          ) : null}
        </Align>
      </Box>
    </Col>
  );
};

ISAContributionsBox.propTypes = {
  wrapperType: string.isRequired,
  isCurrentPeriod: bool.isRequired,
  headroomData: shape({
    contributions: number,
    headroom: number
  }),
  untilJISAHeld: bool
};

ISAContributionsBox.defaultProps = {
  headroomData: null,
  untilJISAHeld: false
};

export default ISAContributionsBox;
