import React from 'react';
import { number, func, string } from 'prop-types';
import Notification from '@nutkit/component-notification';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';
import Inline, { inlineSpaces } from '@nutkit/component-inline';
import { Text, tagNames, textWeights } from '@nutkit/component-text';
import Amount, { amountStyle } from '@nutkit/component-amount';
import { Payment, Pot } from '@nm-utils-lib-web/routes';
import { Trans } from '@nm-utils-lib-web/translations';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import qs from 'qs';

import { unallocatedCashLinkClickedPayload } from '../../../../tracking/events/unallocatedCashEvents';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.unallocatedCash';

export const UnallocatedCash = ({ potUuid, amount, t }) => (
  <Notification dismissable={false}>
    <Section tagName={sectionTagNames.DIV} stackSpacing={stackSpacing.NONE}>
      <Section stackSpacing={stackSpacing.XL}>
        <Trans
          i18nKey={`${TRANSLATION_NAMESPACE}.select.label`}
          components={[
            <Text tagName={tagNames.SPAN} textWeight={textWeights.BOLD}>
              <Amount value={amount} style={amountStyle.CURRENCY} bolderWeight noColor minDecimals={0} />
            </Text>
          ]}
        />
      </Section>

      <Inline space={[inlineSpaces.NONE, inlineSpaces.LG]} wrap>
        <LinkWithTracking
          href={`${Payment.transferBetweenPotsPath()}${qs.stringify(
            {
              fromFundUuid: potUuid
            },
            { addQueryPrefix: true }
          )}`}
          aria-label={t(`${TRANSLATION_NAMESPACE}.transfer.ariaLabel`)}
          data-qa="unallocated-cash__link_transfer"
          eventPayload={unallocatedCashLinkClickedPayload({
            link: t(`${TRANSLATION_NAMESPACE}.transfer.label`)
          })}
          isInternal
        >
          {t(`${TRANSLATION_NAMESPACE}.transfer.label`)}
        </LinkWithTracking>
        <LinkWithTracking
          href={`${Pot.getPotNewPath({ wrapperType: WRAPPER_TYPES.SISA })}`}
          aria-label={t(`${TRANSLATION_NAMESPACE}.create.ariaLabel`)}
          data-qa="unallocated-cash__link_create"
          eventPayload={unallocatedCashLinkClickedPayload({ link: t(`${TRANSLATION_NAMESPACE}.create.label`) })}
        >
          {t(`${TRANSLATION_NAMESPACE}.create.label`)}
        </LinkWithTracking>
        <LinkWithTracking
          href={Payment.WITHDRAW_UNALLOCATED_CASH}
          aria-label={t(`${TRANSLATION_NAMESPACE}.withdraw.ariaLabel`)}
          data-qa="unallocated-cash__link_withdraw"
          eventPayload={unallocatedCashLinkClickedPayload({ link: t(`${TRANSLATION_NAMESPACE}.withdraw.label`) })}
        >
          {t(`${TRANSLATION_NAMESPACE}.withdraw.label`)}
        </LinkWithTracking>
      </Inline>
    </Section>
  </Notification>
);

UnallocatedCash.propTypes = {
  amount: number.isRequired,
  potUuid: string.isRequired,
  t: func.isRequired
};

UnallocatedCash.defaultProps = {};

export default UnallocatedCash;
