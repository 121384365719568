import React from 'react';
import { func, bool, string } from 'prop-types';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Button, { buttonCtaTypes, buttonSizes } from '@nutkit/component-button';
import { Text, Heading, headingLevels } from '@nutkit/component-text';
import { Modal } from '@nutkit/component-modal';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { event } from '@nm-utils-lib-web/analytics';

import { reallocateMoneyModalClickedPayload } from '../../../../tracking/events/productList';
import { TRANSLATION_KEYS } from '../../constants/PendingTransfers';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.cancelPendingTransfersModal';

export const CancelPendingTransfersModal = ({
  isOpen,
  isLoading,
  isError,
  closeModal,
  t,
  onSubmit,
  'data-qa': dataQa,
  translationKey
}) => (
  <Modal isOpen={isOpen} toggle={closeModal} data-qa={dataQa}>
    <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.heading.${translationKey}`)}</Heading>
    {translationKey === TRANSLATION_KEYS.INTRA_FUND_TRANSFER || translationKey === TRANSLATION_KEYS.MULTIPLE ? (
      <Text>{t(`${TRANSLATION_NAMESPACE}.paragraph.${translationKey}`)}</Text>
    ) : null}
    {isError && (
      <Notification data-qa={`${dataQa}__error-notification`} level={notificationLevels.ERROR} dismissable={false}>
        {t(`${TRANSLATION_NAMESPACE}.errorMessage`)}
      </Notification>
    )}
    <ButtonGroup align={buttonGroupAlignments.RIGHT}>
      <Button
        data-qa={`${dataQa}__cancel-button`}
        cta={buttonCtaTypes.SECONDARY}
        size={buttonSizes.SM}
        onClick={() => {
          event(reallocateMoneyModalClickedPayload({ action: 'cancel' }));
          closeModal();
        }}
      >
        {t(`${TRANSLATION_NAMESPACE}.cancelButton`)}
      </Button>
      <Button
        data-qa={`${dataQa}__submit-button`}
        size={buttonSizes.SM}
        onClick={() => {
          event(reallocateMoneyModalClickedPayload({ action: 'confirm' }));
          onSubmit();
        }}
        isLoading={isLoading}
      >
        {t(`${TRANSLATION_NAMESPACE}.submitButton.${translationKey}`)}
      </Button>
    </ButtonGroup>
  </Modal>
);

CancelPendingTransfersModal.propTypes = {
  isOpen: bool.isRequired,
  isLoading: bool.isRequired,
  isError: bool.isRequired,
  closeModal: func.isRequired,
  t: func.isRequired,
  onSubmit: func.isRequired,
  'data-qa': string,
  translationKey: string.isRequired
};

CancelPendingTransfersModal.defaultProps = {
  'data-qa': 'pending-transfers__cancel-modal'
};

export default CancelPendingTransfersModal;
