import React from 'react';
import { arrayOf, object, bool, func, string, oneOf } from 'prop-types';
import { Text, Heading, headingLevels, textSizes } from '@nutkit/component-text';
import Button, { buttonCtaTypes, buttonSizes } from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Modal } from '@nutkit/component-modal';
import { RadioGroup, RadioInput } from '@nutkit/component-radio';
import Checkbox from '@nutkit/component-checkbox';
import Section, { stackSpacing } from '@nutkit/component-section';
import { useTranslation } from '@nm-utils-lib-web/translations';

import { FILTER_TYPES } from '../../constants/FilterTypes';
import { setValuationChartFilterPreference } from '../../helpers/valuationChartFilterDetails';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.portfolioValuation.filtersModal';

export const SetValuationFilterModal = ({
  isOpen,
  closeModal,
  'data-qa': dataQa,
  filterChoice,
  initialPotsSelection,
  pots,
  showAllFilterType
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState(filterChoice);
  const [selectedPots, setSelectedPots] = React.useState(initialPotsSelection);

  const onRadioChangeHandler = ({ currentTarget }) => {
    setFilter(currentTarget.value);
  };

  const onCheckboxChangeHandler = ({ id, isChecked }) => {
    let changeSelectedPots = selectedPots.slice();

    if (isChecked) {
      changeSelectedPots.push(id);
      setSelectedPots(changeSelectedPots);
    } else {
      setSelectedPots(changeSelectedPots.filter(pot => pot !== id));
    }
  };

  const handleCloseModal = () => {
    setSelectedPots(initialPotsSelection);
    setFilter(filterChoice);
    closeModal();
  };

  const getPotsDetailsForTracking = pots => {
    let selectedWrappers = [],
      selectedPotNames = [];

    pots.forEach(pot => {
      selectedPotNames.push(pot.name);
      if (selectedWrappers.indexOf(pot.wrapper.key) === -1) {
        selectedWrappers.push(pot.wrapper.key);
      }
    });

    return {
      selectedPotNames: selectedPotNames.toString(),
      selectedWrappers: selectedWrappers.toString()
    };
  };

  const updateForCustomFilter = () => {
    let appliedFilter = { type: FILTER_TYPES.all };

    if (selectedPots.length === 0) {
      if (showAllFilterType) {
        setFilter(FILTER_TYPES.all);
        setValuationChartFilterPreference[FILTER_TYPES.all]();
      } else {
        appliedFilter.type = FILTER_TYPES.active;
        setFilter(FILTER_TYPES.active);
        setValuationChartFilterPreference[FILTER_TYPES.active]();
      }
    } else if (selectedPots.length === pots.length) {
      appliedFilter.type = FILTER_TYPES.active;
      setFilter(FILTER_TYPES.active);
      setSelectedPots([]);
      setValuationChartFilterPreference[FILTER_TYPES.active]();
    } else {
      const selectedPotsDetails = pots.filter(({ uuid }) => selectedPots.includes(uuid));
      const { selectedWrappers, selectedPotNames } = getPotsDetailsForTracking(selectedPotsDetails);

      appliedFilter.type = FILTER_TYPES.custom;
      appliedFilter.selectedPotNames = selectedPotNames;
      appliedFilter.selectedWrappers = selectedWrappers;

      setValuationChartFilterPreference[FILTER_TYPES.custom](selectedPots);
    }

    return appliedFilter;
  };

  const handleApplyButton = () => {
    let appliedFilter = { type: FILTER_TYPES.all };

    if (filter === FILTER_TYPES.all) {
      setValuationChartFilterPreference[FILTER_TYPES.all]();
      setSelectedPots([]);
    } else if (filter === FILTER_TYPES.active) {
      appliedFilter.type = FILTER_TYPES.active;
      setValuationChartFilterPreference[FILTER_TYPES.active]();
      setSelectedPots([]);
    } else {
      appliedFilter = updateForCustomFilter();
    }
    closeModal(appliedFilter);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCloseModal} data-qa={dataQa}>
      <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
      <Section stackSpacing={stackSpacing.XL}>
        <RadioGroup
          name="valuation-chart-filters"
          data-qa={`${dataQa}__filters`}
          onChange={onRadioChangeHandler}
          value={filter}
        >
          {showAllFilterType ? (
            <RadioInput
              value={FILTER_TYPES.all}
              label={
                <Text noStack textSize={textSizes.SM}>
                  {t(`${TRANSLATION_NAMESPACE}.filter.allPots`)}
                </Text>
              }
              data-qa={`${dataQa}__all-pots`}
              key="all-pots"
            />
          ) : null}
          <RadioInput
            value={FILTER_TYPES.active}
            label={
              <Text noStack textSize={textSizes.SM}>
                {t(`${TRANSLATION_NAMESPACE}.filter.allActivePots`)}
              </Text>
            }
            data-qa={`${dataQa}__all-active-pots`}
            key="all-active-pots"
          />
          <RadioInput
            value={FILTER_TYPES.custom}
            label={
              <Text noStack textSize={textSizes.SM}>
                {t(`${TRANSLATION_NAMESPACE}.filter.customSelection.label`)}
              </Text>
            }
            data-qa={`${dataQa}__custom-selection`}
            key="custom-selection"
          />
        </RadioGroup>

        {filter === FILTER_TYPES.custom && pots && (
          <div data-qa={`${dataQa}__active-pots`}>
            <Text noStack>{t(`${TRANSLATION_NAMESPACE}.filter.customSelection.hint`)}</Text>
            {pots.map(({ name, uuid }) => (
              <Checkbox
                data-qa={`${dataQa}__checkbox-${uuid}`}
                onChange={onCheckboxChangeHandler}
                isChecked={selectedPots.includes(uuid)}
                id={uuid}
                label={<Text noStack>{name}</Text>}
                key={uuid}
                noStack
              />
            ))}
          </div>
        )}
      </Section>

      <ButtonGroup stackOnMobile align={buttonGroupAlignments.RIGHT}>
        <Button
          onClick={handleCloseModal}
          size={buttonSizes.SM}
          cta={buttonCtaTypes.SECONDARY}
          data-qa={`${dataQa}__cancel-button`}
        >
          {t(`${TRANSLATION_NAMESPACE}.cancelButton.label`)}
        </Button>
        <Button onClick={handleApplyButton} size={buttonSizes.SM} data-qa={`${dataQa}__confirm-button`}>
          {t(`${TRANSLATION_NAMESPACE}.confirmButton.label`)}
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

SetValuationFilterModal.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
  filterChoice: oneOf(Object.values(FILTER_TYPES)).isRequired,
  pots: arrayOf(object).isRequired,
  initialPotsSelection: arrayOf(string),
  'data-qa': string,
  showAllFilterType: bool.isRequired
};

SetValuationFilterModal.defaultProps = {
  initialPotsSelection: [],
  'data-qa': 'portfolio-valuation__filters_modal'
};

export default SetValuationFilterModal;
