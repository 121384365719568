import { action } from '@nm-portfolio-lib-web/common/utils/helpers/action';

import {
  SET_INTENT,
  SET_ALLOWANCE,
  SET_AUTO_FILL,
  SET_AUTO_RENEW,
  SET_TRANSFER_TYPE,
  SET_TRANSFER_OPEN_ISA,
  SET_TRANSFER_DATA,
  SET_NEXT_STEP
} from './constants';

const actions = {
  setIntent: action(SET_INTENT),
  setAllowance: action(SET_ALLOWANCE),
  setAutoFill: action(SET_AUTO_FILL),
  setAutoRenew: action(SET_AUTO_RENEW),
  setTransfer: action(SET_TRANSFER_TYPE),
  setOpenISA: action(SET_TRANSFER_OPEN_ISA),
  setTransferData: action(SET_TRANSFER_DATA),
  setNextStep: action(SET_NEXT_STEP)
};

export default actions;
