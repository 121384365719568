import React from 'react';
import { bool, func, string } from 'prop-types';
import { ValuationChartWithMarketHighlights, LOCATION_CONTEXTS } from '@nm-portfolio-lib-web/valuation-chart';
import { NoChartData } from '@nm-portfolio-lib-web/common/components';
import { Heading, headingLevels } from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import Align, { textAlignments } from '@nutkit/component-align';
import MenuWithTrigger from '@nutkit/component-menu';
import { MenuItemLinkWithTracking } from '@nm-utils-lib-web/analytics';
import { Row, Col } from '@nutkit/component-grid';
import { Portfolio } from '@nm-utils-lib-web/routes';
import Section, { sectionTagNames, stackSpacing } from '@nutkit/component-section';
import useGetPots from '@nm-pot/common/hooks/useGetPots';
import { POT_STATUSES } from '@nm-pot/common/constants';
import Loading from '@nutkit/component-loading';
import { getSWRNetworkStatus, STATES as networkStates } from '@nm-utils-lib-web/network/swr';
import Button, { buttonCtaTypes } from '@nutkit/component-button';
import { Trans } from '@nm-utils-lib-web/translations';
import Icon, { IconFilter } from '@nutkit/component-icon';
import { useTokens } from '@nutkit/react-utils';

import {
  chartMenuItemClicked,
  chartFilteringClicked,
  chartFilteringCanceled,
  chartFilteringApplied
} from '../../tracking/events/portfolioValuationChart';

import getValuationChartFilter, {
  isValuationChartFilterVisible,
  isNoValuationForAllPots,
  setValuationChartFilterPreference
} from './helpers/valuationChartFilterDetails';
import { getInactivePots, getActivePots } from './helpers/getPotsForChartValuation';
import SetValuationFilterModal from './components/SetValuationFilterModal';
import { FILTER_TYPES } from './constants/FilterTypes';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.portfolioValuation';

export const PortfolioValuationChart = ({ userUuid, isSideMenuOpen, t }) => {
  const { tokens } = useTokens();
  const { data: potItems, isValidating: isValidatingPots, error: potsError } = useGetPots({
    customerUuid: userUuid,
    statuses: [POT_STATUSES.ACTIVE, POT_STATUSES.CLOSING, POT_STATUSES.CLOSED],
    useAllStatusesInRequest: true
  });
  const [chart, setChart] = React.useState(null);
  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = () => {
    chartFilteringClicked();
    setModalOpen(true);
  };

  const closeModal = trackFilterApplied => {
    if (trackFilterApplied) {
      chartFilteringApplied({
        option: trackFilterApplied.type,
        ...(trackFilterApplied.selectedPotNames && { selectedPots: trackFilterApplied.selectedPotNames }),
        ...(trackFilterApplied.selectedWrappers && { wrapperTypes: trackFilterApplied.selectedWrappers })
      });
    } else {
      chartFilteringCanceled();
    }
    setModalOpen(false);
  };
  const SIDE_MENU_TRANSITION_DURATION = parseInt(tokens.NK_SIDE_MENU_TRANSITION_DURATION);
  const networkStatus = getSWRNetworkStatus({
    data: potItems,
    error: potsError,
    isValidating: isValidatingPots
  });
  const isLoadingPots = [networkStates.INITIAL, networkStates.LOADING, networkStates.REVALIDATING].includes(
    networkStatus
  );
  const inactiveInvestedAndClosingPots = getInactivePots(potItems);
  const activePots = getActivePots(potItems);
  const isValuationChartFilterButtonVisible =
    !potsError && isValuationChartFilterVisible(activePots, inactiveInvestedAndClosingPots);
  const valuationChartFilter =
    isValuationChartFilterButtonVisible &&
    getValuationChartFilter({
      inactivePots: inactiveInvestedAndClosingPots,
      activePots,
      t
    });
  const isNoValuationState =
    valuationChartFilter && isNoValuationForAllPots(!!chart, valuationChartFilter, inactiveInvestedAndClosingPots);

  !isLoadingPots && !potsError && !isValuationChartFilterButtonVisible && setValuationChartFilterPreference.default();

  React.useLayoutEffect(() => {
    setTimeout(() => chart && chart.reflow(), SIDE_MENU_TRANSITION_DURATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSideMenuOpen]);

  if (isLoadingPots) return <Loading data-qa="portfolio-valuation__filter-loading" />;

  return (
    <>
      <Section stackSpacing={stackSpacing.LG} tagName={sectionTagNames.DIV}>
        <Panel>
          <Row>
            <Col xs={{ size: 9 }}>
              <Heading level={headingLevels.TWO}>
                {isValuationChartFilterButtonVisible && !isNoValuationState ? (
                  <Trans
                    data-qa="portfolio-valuation__filter-content"
                    components={[
                      <Button
                        onClick={openModal}
                        cta={buttonCtaTypes.LINK}
                        data-qa="portfolio-valuation__filter-button"
                      >
                        <Icon component={IconFilter} />
                      </Button>
                    ]}
                  >
                    {valuationChartFilter.filterLabel}
                  </Trans>
                ) : (
                  t(`${TRANSLATION_NAMESPACE}.heading.defaultLabel`)
                )}
              </Heading>
            </Col>
            <Col xs={{ size: 3 }}>
              <Align
                xs={{
                  textAlign: textAlignments.RIGHT
                }}
              >
                <MenuWithTrigger
                  dropdownAriaLabel={t(`${TRANSLATION_NAMESPACE}.menu.ariaLabel`)}
                  triggerAriaLabel={isOpen =>
                    isOpen
                      ? t(`${TRANSLATION_NAMESPACE}.menu.trigger.open.ariaLabel`)
                      : t(`${TRANSLATION_NAMESPACE}.menu.trigger.closed.ariaLabel`)
                  }
                >
                  <>
                    <MenuItemLinkWithTracking
                      data-qa="portfolio-valuation-and-fees-link"
                      onTrack={() =>
                        chartMenuItemClicked({
                          entry: t(`${TRANSLATION_NAMESPACE}.menu.details.valuationLink.label`)
                        })
                      }
                      aria-label={t(`${TRANSLATION_NAMESPACE}.menu.details.valuationLink.ariaLabel`)}
                      href={Portfolio.PORTFOLIO_DETAILS_VALUE_PATH}
                    >
                      {t(`${TRANSLATION_NAMESPACE}.menu.details.valuationLink.label`)}
                    </MenuItemLinkWithTracking>
                    <MenuItemLinkWithTracking
                      data-qa="portfolio-allocation-link"
                      onTrack={() =>
                        chartMenuItemClicked({
                          entry: t(`${TRANSLATION_NAMESPACE}.menu.details.allocationLink.label`)
                        })
                      }
                      aria-label={t(`${TRANSLATION_NAMESPACE}.menu.details.allocationLink.ariaLabel`)}
                      href={Portfolio.PORTFOLIO_DETAILS_ALLOCATION_PATH}
                    >
                      {t(`${TRANSLATION_NAMESPACE}.menu.details.allocationLink.label`)}
                    </MenuItemLinkWithTracking>
                  </>
                </MenuWithTrigger>
              </Align>
            </Col>
          </Row>

          <ValuationChartWithMarketHighlights
            customerUuid={userUuid}
            highchartsCallback={setChart}
            potUuids={valuationChartFilter ? valuationChartFilter.selectedPots : undefined}
            // The height was reduced from 450 to 354 to make the pots tabs visible on smaller screens
            chartHeight={354}
            noStack
            renderNoValuation={() => (
              <NoChartData
                data-qa="portfolio-valuation__no-chart-data"
                label={t(`${TRANSLATION_NAMESPACE}.noChartData.text`)}
              />
            )}
            data-qa="portfolio-valuation__valuation-chart"
            locationContext={LOCATION_CONTEXTS.PORTFOLIO_DASHBOARD}
          />
        </Panel>
      </Section>
      {valuationChartFilter && (
        <SetValuationFilterModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          pots={activePots}
          filterChoice={valuationChartFilter.type}
          initialPotsSelection={
            valuationChartFilter.type === FILTER_TYPES.custom ? valuationChartFilter.selectedPots : []
          }
          showAllFilterType={inactiveInvestedAndClosingPots.length > 0}
        />
      )}
    </>
  );
};

PortfolioValuationChart.propTypes = {
  userUuid: string.isRequired,
  isSideMenuOpen: bool.isRequired,
  t: func.isRequired
};

export default PortfolioValuationChart;
