export const getEligibleProducts = (eligibleProductsData, eligibleProductsError) => {
  if (eligibleProductsError) {
    return null;
  }

  return eligibleProductsData.wrappers ? eligibleProductsData.wrappers.map(item => item.toLowerCase()) : [];
};

export const isEligible = (eligibleProducts, wrapperType) =>
  !!eligibleProducts && eligibleProducts.includes(wrapperType);

export const isEligibilityUnknown = eligibleProducts => eligibleProducts === null;
