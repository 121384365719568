import React from 'react';
import { shape, func, string } from 'prop-types';
import { ScrollToTop } from '@nm-utils-lib-web/scroll-to';
import { Container, Row, Col } from '@nutkit/component-grid';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';

import { StartASISA } from '../StartASISA';
import Header from '../Header';
import Footer from '../Footer';

const CreateOrTransferSISA = props => {
  const {
    location,
    match,
    history,
    userDetails: { userUuid, nino }
  } = props;
  const flexibleOnboardingFlag = useFlag(Flags.FLEXIBLE_ONBOARDING);

  return (
    <ScrollToTop>
      <Header />
      <Container>
        <Row>
          <Col md={{ size: '10', offset: '1' }} lg={{ size: '8', offset: '2' }}>
            <StartASISA.Container
              flexible={flexibleOnboardingFlag}
              location={location}
              match={match}
              history={history}
              userNino={nino}
              userUuid={userUuid}
            />
          </Col>
        </Row>
      </Container>
      <Footer />
    </ScrollToTop>
  );
};

CreateOrTransferSISA.propTypes = {
  userDetails: shape({
    userUuid: string,
    nino: string
  }).isRequired,
  match: shape({
    url: string.isRequired
  }).isRequired,
  location: shape({
    search: string
  }).isRequired,
  history: shape({
    push: func.isRequired
  }).isRequired
};

export default CreateOrTransferSISA;
