export const PAY_BY_CARD = 'Pay by card';
export const BANK_TRANSFER = 'Bank transfer';
export const ISA_TRANSFER = 'ISA transfer';
export const POT_TRANSFER = 'Pot transfer';
export const FRIENDS_AND_FAMILY = 'Friends and family';

export const tabsBySlug = {
  'pay-by-card': PAY_BY_CARD,
  'bank-transfer': BANK_TRANSFER,
  'isa-transfer': ISA_TRANSFER,
  'pot-transfer': POT_TRANSFER,
  'friends-and-family': FRIENDS_AND_FAMILY
};

export const ACTIVE_PAYMENT_TAB_PARAM = 'active-payment-tab';
