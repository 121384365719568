import { event } from '@nm-utils-lib-web/analytics';
import { LOCATION_CONTEXTS } from '@nm-portfolio-lib-web/valuation-chart';

import { FILTER_TYPES } from '../../components/PortfolioValuationChart/constants/FilterTypes';

export const defaultProps = {
  location: LOCATION_CONTEXTS.PORTFOLIO_DASHBOARD,
  category: 'Valuation_View'
};
export const mapFilterOptions = {
  [FILTER_TYPES.all]: 'all_active_and_inactive',
  [FILTER_TYPES.active]: 'all_active',
  [FILTER_TYPES.custom]: 'custom'
};
export const CHART_MENU_ITEM_CLICKED = 'Chart_Menu_Item_Clicked';
export const VALUATION_CHART_FILTERING_SELECTED = 'Valuation_Chart_Filtering_Selected';
export const VALUATION_CHART_FILTERING_CANCELED = 'Valuation_Chart_Filtering_Canceled';
export const VALUATION_CHART_FILTERING_APPLIED = 'Valuation_Chart_Filtering_Applied';
export const chartMenuItemClicked = ({ entry }) =>
  event({
    name: CHART_MENU_ITEM_CLICKED,
    properties: {
      ...defaultProps,
      entry
    }
  });
export const chartFilteringClicked = () =>
  event({
    name: VALUATION_CHART_FILTERING_SELECTED,
    properties: {
      ...defaultProps
    }
  });
export const chartFilteringCanceled = () =>
  event({
    name: VALUATION_CHART_FILTERING_CANCELED,
    properties: {
      ...defaultProps
    }
  });
export const chartFilteringApplied = ({ option, wrapperTypes, selectedPots }) =>
  event({
    name: VALUATION_CHART_FILTERING_APPLIED,
    properties: {
      ...defaultProps,
      option: mapFilterOptions[option],
      wrapperTypes,
      selectedPots
    }
  });
