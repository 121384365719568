import React from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import Select from '@nutkit/component-select';
import Section, { stackSpacing } from '@nutkit/component-section';
import fetchImage, { imageSizes } from '@nm-marketing/prismic-utils';

import { ProductSelectOptionTitle, ProductSelectOptionSelectedTitle } from '../ProductSelectOptionTitle';

const getOptionWithImage = ({ id, image, ...rest }) => {
  const imageSource = fetchImage(window.NutmegConfig.PRISMIC_TARGET, image, imageSizes.MD, 'png', {
    fit: 'fill',
    fill: 'solid'
  });

  return {
    id,
    title: <ProductSelectOptionTitle imageSource={imageSource} {...rest} />,
    selectedTitle: <ProductSelectOptionSelectedTitle imageSource={imageSource} {...rest} />
  };
};

const ProductSelect = ({ products, selectedProductId, history: { push }, 'data-qa': dataQa }) => {
  const onProductSelect = id => {
    const { url } = products.find(({ id: productId }) => productId === id);

    push(url);
  };

  return (
    <Section stackSpacing={stackSpacing.SM}>
      <Select
        noStack
        onSelect={onProductSelect}
        options={products.map(product => getOptionWithImage(product))}
        restrictHeight
        value={selectedProductId}
        data-qa={dataQa}
      />
    </Section>
  );
};

ProductSelect.propTypes = {
  products: arrayOf(
    shape({
      url: string.isRequired,
      label: string.isRequired,
      image: string.isRequired,
      id: string.isRequired,
      total: number.isRequired,
      isPresent: bool.isRequired
    })
  ).isRequired,
  selectedProductId: string.isRequired,
  history: shape({
    push: func.isRequired
  }).isRequired,
  'data-qa': string
};

ProductSelect.defaultProps = {
  'data-qa': 'product-select'
};

export default ProductSelect;
