import React from 'react';
import { arrayOf, object, number, bool, func, string } from 'prop-types';
import { Trans } from '@nm-utils-lib-web/translations';
import { event } from '@nm-utils-lib-web/analytics';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';
import { Heading, headingLevels } from '@nutkit/component-text';
import Bold, { boldWeights } from '@nutkit/component-bold';
import Button, { buttonCtaTypes, buttonSizes } from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Modal } from '@nutkit/component-modal';
import DescriptionBlock from '@nutkit/component-description-block';
import Amount, { amountStyle } from '@nutkit/component-amount';
import Notification, { notificationLevels } from '@nutkit/component-notification';

import { modalConfirmPendingWithdrawalsPayload } from '../../../../tracking/events/productList';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.pendingWithdrawal.modal';

export const CancelPendingWithdrawalModal = ({
  amount,
  isOpen,
  closeModal,
  pendingWithdrawalPots,
  cancelPendingWithdrawals,
  isCancelling,
  isError,
  hasPendingWrapperWithdrawals,
  t,
  'data-qa': dataQa
}) => (
  <Modal isOpen={isOpen} toggle={closeModal} data-qa={dataQa}>
    <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
    <Section stackSpacing={stackSpacing.LG} tagName={sectionTagNames.DIV}>
      {hasPendingWrapperWithdrawals ? (
        t(`${TRANSLATION_NAMESPACE}.wrapper.description`)
      ) : (
        <Trans
          i18nKey={`${TRANSLATION_NAMESPACE}.description`}
          components={[
            <Bold weight={boldWeights.BOLD}>
              <Amount value={amount} style={amountStyle.CURRENCY} bolderWeight minDecimals={0} />
            </Bold>
          ]}
        />
      )}
    </Section>
    {!hasPendingWrapperWithdrawals && (
      <Section stackSpacing={stackSpacing.LG} tagName={sectionTagNames.DIV}>
        {pendingWithdrawalPots.map(({ pending: { withdrawals }, name, uuid }) => {
          return (
            <DescriptionBlock
              data-qa={`${dataQa}__pots`}
              key={uuid}
              label={`Pending withdrawal (${name}):`}
              description={
                <Amount value={`-${withdrawals}`} style={amountStyle.CURRENCY} bolderWeight minDecimals={0} />
              }
            />
          );
        })}
      </Section>
    )}
    {isError && (
      <Notification level={notificationLevels.ERROR} dismissable={false} data-qa={`${dataQa}__error-notification`}>
        {t(`${TRANSLATION_NAMESPACE}.errorMessage`)}
      </Notification>
    )}
    <ButtonGroup stackOnMobile align={buttonGroupAlignments.RIGHT}>
      <Button
        onClick={() => {
          event(modalConfirmPendingWithdrawalsPayload({ action: 'cancel' }));
          closeModal();
        }}
        size={buttonSizes.SM}
        cta={buttonCtaTypes.SECONDARY}
        disabled={isCancelling}
        data-qa={`${dataQa}__cancel-button`}
      >
        {t(`${TRANSLATION_NAMESPACE}.cancelButton.label`)}
      </Button>
      <Button
        onClick={() => {
          event(modalConfirmPendingWithdrawalsPayload({ action: 'confirm' }));
          cancelPendingWithdrawals();
        }}
        size={buttonSizes.SM}
        isLoading={isCancelling}
        data-qa={`${dataQa}__confirm-button`}
      >
        {t(`${TRANSLATION_NAMESPACE}.confirmButton.label`)}
      </Button>
    </ButtonGroup>
  </Modal>
);

CancelPendingWithdrawalModal.propTypes = {
  pendingWithdrawalPots: arrayOf(object).isRequired,
  amount: number.isRequired,
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
  cancelPendingWithdrawals: func.isRequired,
  isCancelling: bool.isRequired,
  isError: bool.isRequired,
  hasPendingWrapperWithdrawals: bool,
  t: func.isRequired,
  'data-qa': string
};

CancelPendingWithdrawalModal.defaultProps = {
  hasPendingWrapperWithdrawals: undefined,
  'data-qa': 'pending-withdrawal__modal'
};

export default CancelPendingWithdrawalModal;
