import React, { useState } from 'react';
import { func, string, number, object, shape, oneOfType, bool } from 'prop-types';
import Panel from '@nutkit/component-panel';
import { Heading, Text, headingLevels } from '@nutkit/component-text';
import Bold from '@nutkit/component-bold';
import Disclosure from '@nutkit/component-disclosure';
import { CurrencyInput } from '@nutkit/component-input';
import { Trans } from '@nm-utils-lib-web/translations';
import Amount, { amountStyle } from '@nutkit/component-amount';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import debounce from 'lodash.debounce';
import { JourneyNavigation } from '@nm-portfolio-lib-web/common/components';
import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import { getErrorMessage } from '@nm-portfolio-lib-web/common/helpers';
import { Flags, useVariant, Variants } from '@nm-utils-lib-web/flags';

import getAmount from '../../utils/getAmount';
import useDefineInitialSettings from '../../../../hooks/useDefineInitialSettings';

const TRANSLATION_NAMESPACE = 'start.setAllowanceSISA';
const DEBOUNCE_DELAY = 200;

const SetAllowanceSISA = props => {
  const {
    t,
    'data-qa': dataQa,
    MIN_ALLOWANCE_LIMIT,
    MAX_ALLOWANCE_LIMIT,
    setAmount,
    amount,
    autoFill,
    autoRenew,
    journeyNavigation,
    userUuid,
    isaSettingsOnboardingAndVariantB
  } = props;
  const isaSettingsVariant = useVariant(Flags.ISA_SETTINGS_TEST);
  const VARIANT_NAMESPACE =
    isaSettingsVariant?.name === Variants.VARIANT_B
      ? `${TRANSLATION_NAMESPACE}.${isaSettingsVariant.name}`
      : TRANSLATION_NAMESPACE;
  const { defineInitialSettings, isLoading, error } = useDefineInitialSettings();
  const convertedMinAllowanceLimit = getAmount(MIN_ALLOWANCE_LIMIT);
  const convertedMaxAllowanceLimit = getAmount(MAX_ALLOWANCE_LIMIT);
  const title = t(`${VARIANT_NAMESPACE}.headers.title`);
  const subTitle = t(`${TRANSLATION_NAMESPACE}.headers.subTitle`);
  const descriptionKey = `${TRANSLATION_NAMESPACE}.content.limitDescription`;
  const amountLabel = t(`${TRANSLATION_NAMESPACE}.content.amountLabel`);
  const whyLabel = t(`${TRANSLATION_NAMESPACE}.content.whyLabel`);
  const whyDescriptionKey = `${TRANSLATION_NAMESPACE}.content.whyDescription`;
  const validationMessage = t(`${TRANSLATION_NAMESPACE}.content.validationMessage`, {
    minAllowance: convertedMinAllowanceLimit,
    maxAllowance: convertedMaxAllowanceLimit
  });
  const [outOfLimit, setOutOfLimit] = useState(!isValid(amount));

  function isValid(value) {
    return value >= MIN_ALLOWANCE_LIMIT && value <= MAX_ALLOWANCE_LIMIT;
  }

  const onAmountChange = debounce(amount => {
    setAmount(amount);
    setOutOfLimit(!isValid(amount));
  }, DEBOUNCE_DELAY);

  const submitInitialSettings = () => {
    defineInitialSettings({
      userUuid,
      wrapperType: wrapperTypes.SISA,
      payload: { allowance: amount, autoFill, autoRenew },
      onComplete: journeyNavigation.continueButton.onClick
    });
  };

  if (error) {
    return (
      <Notification dismissable={false} level={notificationLevels.ERROR} data-qa={`${dataQa}-error`}>
        {t(getErrorMessage())}
      </Notification>
    );
  }

  return (
    <>
      <Heading>{title}</Heading>
      <Panel>
        <Heading level={headingLevels.TWO}>{subTitle}</Heading>
        <Text>
          <Trans
            i18nKey={descriptionKey}
            components={[
              <Bold>
                {descriptionKey}
                <Amount value={MAX_ALLOWANCE_LIMIT} style={amountStyle.CURRENCY} minDecimals={0} bolderWeight />
              </Bold>
            ]}
          />
        </Text>
        <CurrencyInput
          label={amountLabel}
          onChange={onAmountChange}
          value={amount}
          error={outOfLimit && validationMessage}
          min={MIN_ALLOWANCE_LIMIT}
          max={MAX_ALLOWANCE_LIMIT}
          data-qa={`${dataQa}-amount`}
          noDecimals
        />
        <Disclosure summary={whyLabel} open={false} striped data-qa={`${dataQa}-disclosure-why`}>
          <Trans
            i18nKey={whyDescriptionKey}
            components={[
              <Text>
                {whyDescriptionKey}
                <Amount value={MAX_ALLOWANCE_LIMIT} style={amountStyle.CURRENCY} minDecimals={0} />
              </Text>
            ]}
          />
        </Disclosure>
        <JourneyNavigation
          {...journeyNavigation}
          dataQa={`${dataQa}-navigation`}
          continueButton={{
            ...journeyNavigation.continueButton,
            onClick: submitInitialSettings,
            disabled: outOfLimit,
            isLoading
          }}
          {...(isaSettingsOnboardingAndVariantB ? { backButton: null } : undefined)}
        />
      </Panel>
    </>
  );
};

SetAllowanceSISA.propTypes = {
  journeyNavigation: shape({
    continueButton: object,
    backButton: object,
    showErrorMessage: oneOfType([bool, string])
  }).isRequired,
  setAmount: func.isRequired,
  'data-qa': string,
  t: func.isRequired,
  MAX_ALLOWANCE_LIMIT: number,
  MIN_ALLOWANCE_LIMIT: number,
  amount: number,
  autoFill: bool,
  autoRenew: bool,
  userUuid: string.isRequired,
  isaSettingsOnboardingAndVariantB: bool
};

SetAllowanceSISA.defaultProps = {
  'data-qa': 'set-allowance-sisa',
  MAX_ALLOWANCE_LIMIT: 20000,
  MIN_ALLOWANCE_LIMIT: 0,
  amount: 20000,
  autoRenew: true,
  autoFill: true,
  isaSettingsOnboardingAndVariantB: false
};

export default SetAllowanceSISA;
