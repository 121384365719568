import React from 'react';
import { arrayOf, bool, node, oneOfType, shape, string } from 'prop-types';
import Loading from '@nutkit/component-loading';
import { GenericError } from '@nm-ui-shared-lib-web/generic-error';

import useCustomerAccess from '../../hooks/useCustomerAccess';

export const CustomerAccess = ({
  customerUuid,
  children,
  routesToIgnore,
  'data-qa': dataQa,
  errorHeaderLogoHref,
  errorHeaderTranslationNameSpace
}) => {
  const { isLoading, isRedirecting, error } = useCustomerAccess(customerUuid, routesToIgnore);

  if (error) {
    return (
      <GenericError
        data-qa={`${dataQa}__generic-error`}
        headerLogoHref={errorHeaderLogoHref}
        headerTranslationNameSpace={errorHeaderTranslationNameSpace}
      />
    );
  }

  if (isLoading || isRedirecting) {
    return <Loading data-qa={`${dataQa}__loading`} />;
  }

  return children;
};

CustomerAccess.propTypes = {
  customerUuid: string.isRequired,
  children: node.isRequired,
  routesToIgnore: arrayOf(oneOfType([string, shape({ path: string, match: bool, exact: bool })])),
  errorHeaderLogoHref: string,
  errorHeaderTranslationNameSpace: string,
  'data-qa': string
};

CustomerAccess.defaultProps = {
  routesToIgnore: [],
  errorHeaderLogoHref: undefined,
  errorHeaderTranslationNameSpace: undefined,
  'data-qa': 'customer-access'
};
