import { handleServerError } from '@nm-portfolio-lib-web/common/utils';

import baseValuationService from '../baseValuationService';

export const getPerformanceSummary = async ({ customerUuid, period }) => {
  const URL = `/customers/${customerUuid}/performance_summary`;

  try {
    const { data } = await baseValuationService.get(URL, { ...(period && { params: { period } }) });

    return data;
  } catch (error) {
    handleServerError(error);
  }
};
