import { userPreferences, userPreferencesService } from '@nm-utils-lib-web/user-preferences';

import { FILTER_TYPES, CUSTOM_FILTER_TYPES } from '../constants/FilterTypes';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.portfolioValuation.heading.labelByfilterType';

export const setValuationChartFilterPreference = {
  [FILTER_TYPES.all]: () => userPreferencesService.removePreference(userPreferences.DASHBOARD_VALUATION_CHART_FILTERS),
  [FILTER_TYPES.active]: () =>
    userPreferencesService.setPreference(userPreferences.DASHBOARD_VALUATION_CHART_FILTERS, {
      type: FILTER_TYPES.active
    }),
  [FILTER_TYPES.custom]: selectedPots =>
    userPreferencesService.setPreference(userPreferences.DASHBOARD_VALUATION_CHART_FILTERS, {
      type: FILTER_TYPES.custom,
      potUuids: selectedPots
    }),
  default: () => userPreferencesService.removePreference(userPreferences.DASHBOARD_VALUATION_CHART_FILTERS)
};

export const valuationChartFilterLabels = {
  [FILTER_TYPES.all]: `${TRANSLATION_NAMESPACE}.${FILTER_TYPES.all}`,
  [FILTER_TYPES.active]: `${TRANSLATION_NAMESPACE}.${FILTER_TYPES.active}`,
  [FILTER_TYPES.custom]: {
    [CUSTOM_FILTER_TYPES.single]: `${TRANSLATION_NAMESPACE}.${FILTER_TYPES.custom}.${CUSTOM_FILTER_TYPES.single}`,
    [CUSTOM_FILTER_TYPES.multiple]: `${TRANSLATION_NAMESPACE}.${FILTER_TYPES.custom}.${CUSTOM_FILTER_TYPES.multiple}`
  }
};

/**
 * Defines actual filter details for the user, make all required updates to local storage when it's needed.
 * @param {Function} t function to extract translations
 * @param {Array} activePots array of valid active pots for the valuation filter (not JISA ACTIVE pots)
 * @param {Array} inactivePots array of valid inactive pots for the valuation filer
 * (e.g., in CLOSED state with Invested date set and etc.)
 * @return {Object} Object containing filter details such as filter label, filter type and array of uuids selected pots
 */

const getValuationChartFilter = ({ t, activePots, inactivePots }) => {
  const getFilterDetailsForSelectedPots = (selectedPots, filterType) => {
    const getFilterLabel = () => {
      if (filterType === FILTER_TYPES.active) {
        return t(valuationChartFilterLabels[filterType]);
      } else {
        return selectedPots.length === 1
          ? t(valuationChartFilterLabels[filterType][CUSTOM_FILTER_TYPES.single], {
              potName: selectedPots[0].name,
              interpolation: { escapeValue: false }
            })
          : t(valuationChartFilterLabels[filterType][CUSTOM_FILTER_TYPES.multiple], {
              numberOfPots: selectedPots.length
            });
      }
    };

    return {
      filterLabel: getFilterLabel(),
      type: filterType,
      selectedPots: selectedPots.map(pot => pot.uuid)
    };
  };

  const getFilterDetailsForCustom = (currentValuationChartFilter, activePots, inactivePots) => {
    const activePotsUuids = activePots.map(pot => pot.uuid);
    const selectedPotsUuids = currentValuationChartFilter.potUuids.filter(pot => activePotsUuids.includes(pot));
    const selectedPots = activePots.filter(pot => selectedPotsUuids.includes(pot.uuid));

    if (selectedPots.length === activePots.length) {
      setValuationChartFilterPreference[FILTER_TYPES.active]();

      return getFilterDetailsForSelectedPots(selectedPots, FILTER_TYPES.active);
    } else {
      if (!!selectedPots.length && selectedPots.length !== currentValuationChartFilter.potUuids.length) {
        setValuationChartFilterPreference[FILTER_TYPES.custom](selectedPotsUuids);
      }

      if (selectedPots.length === 0) {
        setValuationChartFilterPreference[getDefaultValuationChartFilterType(inactivePots)]();

        return getFilterDetailsForDefaultState();
      }

      return getFilterDetailsForSelectedPots(selectedPots, FILTER_TYPES.custom);
    }
  };

  const getFilterDetailsForDefaultState = () => {
    const defaultFilterType = getDefaultValuationChartFilterType(inactivePots);

    switch (defaultFilterType) {
      case FILTER_TYPES.all:
        return {
          filterLabel: t(valuationChartFilterLabels[FILTER_TYPES.all]),
          type: FILTER_TYPES.all,
          selectedPots: []
        };
      case FILTER_TYPES.active:
        return {
          filterLabel: t(valuationChartFilterLabels[FILTER_TYPES.active]),
          type: FILTER_TYPES.active,
          selectedPots: activePots.map(pot => pot.uuid)
        };
    }
  };
  const currentValuationChartFilter = userPreferencesService.getPreference(
    userPreferences.DASHBOARD_VALUATION_CHART_FILTERS
  );

  switch (currentValuationChartFilter && currentValuationChartFilter.type) {
    case FILTER_TYPES.active:
      return getFilterDetailsForSelectedPots(activePots, FILTER_TYPES.active);
    case FILTER_TYPES.custom:
      return getFilterDetailsForCustom(currentValuationChartFilter, activePots, inactivePots);
    default:
      return getFilterDetailsForDefaultState();
  }
};

export const isValuationChartFilterVisible = (activePots = [], inactivePots = []) =>
  !!(activePots.length > 1 || (activePots.length === 1 && inactivePots.length >= 1));

export const getDefaultValuationChartFilterType = inactivePots =>
  inactivePots.length === 0 ? FILTER_TYPES.active : FILTER_TYPES.all;

export const isNoValuationForAllPots = (isChartAvailable, valuationChartFilter, inactivePots) =>
  !isChartAvailable && valuationChartFilter.type === getDefaultValuationChartFilterType(inactivePots);

export default getValuationChartFilter;
