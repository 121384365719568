import React from 'react';
import Amount, { amountStyle } from '@nutkit/component-amount';
import { Trans } from '@nm-utils-lib-web/translations';

const TRANSLATION_NAMESPACE = 'isaDetails.isaAllowance.validation';

export const exceededMaxSisaAllowanceError = maxIsaAllowance => (
  <Trans
    components={[<Amount value={maxIsaAllowance} style={amountStyle.CURRENCY} noColor minDecimals={0} />]}
    i18nKey={`${TRANSLATION_NAMESPACE}.exceededMaxSisaAllowanceError`}
  />
);
export const exceededMaxLisaAllowanceError = maxLisaAllowance => (
  <Trans
    components={[<Amount value={maxLisaAllowance} style={amountStyle.CURRENCY} noColor minDecimals={0} />]}
    i18nKey={`${TRANSLATION_NAMESPACE}.exceededMaxLisaAllowanceError`}
  />
);
export const ERROR_MESSAGES = {
  EXCEED_MAX_ISA_ALLOWANCE: `${TRANSLATION_NAMESPACE}.exceededMaxIsaAllowanceError`,
  SISA_CONTRIBUTIONS_MORE_THAN_ALLOWANCE: `${TRANSLATION_NAMESPACE}.sisaContributionsMoreThanAllowanceError`,
  LISA_CONTRIBUTIONS_MORE_THAN_ALLOWANCE: `${TRANSLATION_NAMESPACE}.lisaContributionsMoreThanAllowanceError`
};

const ValidateAllowance = ({
  holdsSisa,
  holdsLisa,
  sisaAllowance,
  lisaAllowance,
  sisaContributions,
  lisaContributions,
  maxIsaAllowance,
  maxLisaAllowance,
  t
}) => {
  let errors = [];

  if (holdsSisa && holdsLisa) {
    let totalSetAllowance = sisaAllowance + lisaAllowance;

    if (totalSetAllowance > maxIsaAllowance) {
      errors.push(t(ERROR_MESSAGES.EXCEED_MAX_ISA_ALLOWANCE));
    }
  }

  if (holdsLisa) {
    if (lisaAllowance > maxLisaAllowance) {
      errors.push(exceededMaxLisaAllowanceError(maxLisaAllowance));
    }

    if (lisaContributions > lisaAllowance) {
      errors.push(t(ERROR_MESSAGES.LISA_CONTRIBUTIONS_MORE_THAN_ALLOWANCE));
    }
  }

  if (holdsSisa) {
    if (sisaAllowance > maxIsaAllowance) {
      errors.push(exceededMaxSisaAllowanceError(maxIsaAllowance));
    }

    if (sisaContributions > sisaAllowance) {
      errors.push(t(ERROR_MESSAGES.SISA_CONTRIBUTIONS_MORE_THAN_ALLOWANCE));
    }
  }

  return errors;
};

export default ValidateAllowance;
