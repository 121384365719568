export const NAVIGATION_EVENTS = {
  CONTINUE: 'ON_CONTINUE',
  BACK: 'ON_BACK',
  BROWSER_HISTORY_UPDATE: 'BROWSER_HISTORY_UPDATE'
};

export const NAVIGATION_ACTIONS = {
  CHANGE_VIEW: 'CHANGE_VIEW',
  REDIRECT_VIEW: 'REDIRECT_VIEW',
  UPDATE_LOCATION_CONTEXT: 'UPDATE_LOCATION_CONTEXT',
  TRACK_NAVIGATION_EVENT: 'TRACK_NAVIGATION_EVENT'
};

export const JOURNEY_INTERNAL_STATES = {
  INITIAL: 'INITIAL',
  REDIRECTING_BACKWARD: 'REDIRECTING_BACKWARD',
  RESET_MACHINE: 'RESET_MACHINE',
  DASHBOARD: 'DASHBOARD'
};

export const EXTERNAL_VIEWS = {
  POT_CREATION: 'POT_CREATION',
  PAYMENTS: 'PAYMENTS'
};
