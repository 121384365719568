import { WRAPPER_TYPES } from '@nm-customer/common/constants';
import { Portfolio } from '@nm-utils-lib-web/routes';
import { handleError } from '@nm-customer/common/services';

const ctaPathMap = {
  Direct_Debit_JISA_Primary: () => ({
    href: Portfolio.getDashboardWithParamsPath({ activeProductTab: WRAPPER_TYPES.JISA }),
    isInternal: true
  }),
  Dashboard: () => ({
    href: Portfolio.DASHBOARD_PATH,
    isInternal: true
  }),
  External_Browser: ({ url }) => ({
    href: url,
    isExternal: true
  })
};

const getCtaPath = cta => {
  try {
    return ctaPathMap[cta.ctaLink](cta.destinations);
  } catch (error) {
    try {
      handleError(error, `FeatureIntro: could not generate CTA path`);
    } catch (errorToDiscard) {
      // TODO: TE-2019 Will allow us to remove this nested try-catch
    }

    return null;
  }
};

export default getCtaPath;
