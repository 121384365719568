import React from 'react';
import { number, shape } from 'prop-types';
import { Text, textStyles } from '@nutkit/component-text';
import { Row, Col } from '@nutkit/component-grid';
import Amount, { amountStyle } from '@nutkit/component-amount';
import Bold, { boldWeights } from '@nutkit/component-bold';
import { useTranslation } from '@nm-utils-lib-web/translations';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioInvestments';

const HoldingsInformation = ({ values: { amount: holdingsAmount, price: holdingPrice, value: totalValue } }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <Text textStyle={textStyles.TEXT_2} noStack>
          {t(`${TRANSLATION_NAMESPACE}.numberOfShares`)}
        </Text>
        <Bold weight={boldWeights.BOLD}>
          <Amount bolderWeight minDecimals={4} maxDecimals={4} value={holdingsAmount} />
        </Bold>
      </Col>
      <Col>
        <Text textStyle={textStyles.TEXT_2} noStack>
          {t(`${TRANSLATION_NAMESPACE}.sharePrice`)}
        </Text>
        <Bold weight={boldWeights.BOLD}>
          <Amount bolderWeight minDecimals={4} maxDecimals={4} style={amountStyle.CURRENCY} value={holdingPrice} />
        </Bold>
      </Col>
      <Col>
        <Text textStyle={textStyles.TEXT_2} noStack>
          {t(`${TRANSLATION_NAMESPACE}.totalValue`)}
        </Text>
        <Bold weight={boldWeights.BOLD}>
          <Amount bolderWeight minDecimals={2} style={amountStyle.CURRENCY} value={totalValue} />
        </Bold>
      </Col>
    </Row>
  );
};

HoldingsInformation.propTypes = {
  values: shape({
    amount: number,
    price: number,
    value: number
  }).isRequired
};

export default HoldingsInformation;
