import React, { useState } from 'react';
import { string, shape, func } from 'prop-types';
import { Route, Switch, Redirect, Link as RouterLink } from 'react-router-dom';
import Navigation, { NavigationItem } from '@nutkit/component-navigation';
import Section, { stackSpacing } from '@nutkit/component-section';
import { Container, Row, Col } from '@nutkit/component-grid';
import Select from '@nutkit/component-select';
import { Heading } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { Portfolio } from '@nm-utils-lib-web/routes';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';

import { VALUE_TAB, ALLOCATION_TAB } from '../../constants/tabs';
import PortfolioValueContainer from '../PortfolioValueContainer';
import PortfolioAllocationContainer from '../PortfolioAllocationContainer';
import { PORTFOLIO_DIG_DEEPER_EVENTS } from '../../events';

const selectOptions = [
  { id: VALUE_TAB, title: VALUE_TAB },
  { id: ALLOCATION_TAB, title: ALLOCATION_TAB }
];
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioDetails';
const getPath = (baseUrl, path) => `${baseUrl}/${path.toLowerCase()}`;

const PortfolioDetails = props => {
  const {
    history,
    customerUuid,
    match,
    location: { pathname: pathName }
  } = props;
  const isRouterPathValue = pathName.includes('value');
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(isRouterPathValue ? VALUE_TAB : ALLOCATION_TAB);
  const { matchesCondition: isLessThanMediumBreakpoint } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);

  const handleActiveTab = (tab, event) => {
    PORTFOLIO_DIG_DEEPER_EVENTS.Dig_Deeper_View(event);
    setActiveTab(tab);
  };

  const handleOnSelect = title => {
    history.push(title);
    handleActiveTab(title, { event: title });
  };

  return (
    <Container data-qa="portfolio-details">
      <Row>
        <Col md={{ size: '12', offset: '0' }} lg={{ size: '10', offset: '1' }}>
          <Section stackSpacing={stackSpacing.MD}>
            <Heading noStack>{t(`${TRANSLATION_NAMESPACE}.heading.title`)}</Heading>
          </Section>
          {isLessThanMediumBreakpoint ? (
            <Section stackSpacing={stackSpacing.SM}>
              <Select
                data-testid="portfolio-web__portfolio-details-navigation-select"
                noStack
                restrictHeight
                title="select"
                onSelect={title => handleOnSelect(title)}
                options={selectOptions}
                value={activeTab}
              />
            </Section>
          ) : (
            <Navigation>
              <NavigationItem isActive={activeTab === VALUE_TAB}>
                <RouterLink
                  data-qa="portfolio-web__portfolio-details-link-value"
                  to={getPath(match.url, VALUE_TAB)}
                  onClick={() => handleActiveTab(VALUE_TAB, { event: VALUE_TAB })}
                >
                  {t(`${TRANSLATION_NAMESPACE}.navigation.value`)}
                </RouterLink>
              </NavigationItem>
              <NavigationItem isActive={activeTab === ALLOCATION_TAB}>
                <RouterLink
                  data-qa="portfolio-web__portfolio-details-link-allocation"
                  to={getPath(match.url, ALLOCATION_TAB)}
                  onClick={() => handleActiveTab(ALLOCATION_TAB, { event: ALLOCATION_TAB })}
                >
                  {t(`${TRANSLATION_NAMESPACE}.navigation.allocation`)}
                </RouterLink>
              </NavigationItem>
            </Navigation>
          )}
          <Switch>
            <Route
              path={getPath(match.url, VALUE_TAB)}
              render={props => <PortfolioValueContainer {...props} customerUuid={customerUuid} />}
            />
            <Route
              path={getPath(match.url, ALLOCATION_TAB)}
              render={props => <PortfolioAllocationContainer {...props} customerUuid={customerUuid} />}
            />
            <Redirect from={Portfolio.DASHBOARD_PATH} to={getPath(match.url, VALUE_TAB)} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

PortfolioDetails.propTypes = {
  customerUuid: string.isRequired,
  history: shape({
    push: func
  }).isRequired,
  match: shape({
    url: string
  }).isRequired,
  location: shape({
    pathname: string.isRequired
  }).isRequired
};

export default PortfolioDetails;
