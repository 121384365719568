import React from 'react';
import { string } from 'prop-types';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Loading, { delaySizes } from '@nutkit/component-loading';
import useGetISAStatus from '@nm-portfolio-lib-web/common/hooks/useGetISAStatus';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import { MetaTitle } from '@nm-utils-lib-web/meta-title';

import ISAContributions from '../ISAContributions';
import ISAEditAllowance from '../ISAEditAllowance';
import { periodTypes } from '../../constants/periodTypes';

const ISAAllowanceContainer = props => {
  const { customerId, payInNowLink, openNewIsaLink } = props;
  const { t } = useTranslation();
  const translationMetaTitle = `${t('global.common.organisation.appTitle')} - ${t(
    'dashboard.common.metaTitles.isaDetailsAllowance'
  )}`;
  const { data: statusData, isLoading: isStatusLoading, isaStatus, error } = useGetISAStatus({
    customerUuid: customerId
  });

  if (isStatusLoading) {
    return <Loading delay={delaySizes.XS} data-qa="loading-isa-allowance" />;
  }

  if (error) {
    return (
      <Notification data-qa="failed-loading-isa-status" level={notificationLevels.ERROR} dismissable={false}>
        {t('dashboard.isaAllowance.loadingError')}
      </Notification>
    );
  }

  if (
    (statusData?.byWrapper?.[wrapperTypes.LISA]?.holds === false && isaStatus.none) ||
    Object.keys(statusData?.byWrapper).length === 0
  ) {
    return (
      <Notification data-qa="isa-allowance-no-records" level={notificationLevels.WARNING} dismissable={false}>
        {t('dashboard.isaAllowance.noIsa')}
      </Notification>
    );
  }

  return (
    <>
      <MetaTitle title={translationMetaTitle} />

      <div className="isa-allowance-container" data-qa="isa-allowance-container">
        <ISAContributions customerUuid={customerId} payInNowLink={payInNowLink} openNewIsaLink={openNewIsaLink} />
        <ISAEditAllowance
          data-qa={'isa-edit-allowance-current'}
          customerId={customerId}
          periodType={periodTypes.CURRENT}
          period={statusData.currentPeriod}
          holds={statusData.holds}
        />
        <ISAEditAllowance
          data-qa={'isa-edit-allowance-next'}
          customerId={customerId}
          periodType={periodTypes.NEXT}
          period={statusData.nextPeriod}
          holds={statusData.holds}
        />
      </div>
    </>
  );
};

ISAAllowanceContainer.propTypes = {
  customerId: string.isRequired,
  payInNowLink: string.isRequired,
  openNewIsaLink: string.isRequired
};

export default ISAAllowanceContainer;
