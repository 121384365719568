import { getQueryParams } from '../../utils/queryParams';
import { useStore } from '../../../../hooks/useStore';

import reducer from './reducer';
import actions from './actions';
import { getInitialValues } from './storeMap';

function useSISAStore({ location }) {
  const params = getQueryParams(location.search);
  const initialValues = getInitialValues(params);

  return useStore(initialValues, reducer, actions);
}

export default useSISAStore;
