import { WRAPPER_TYPES as wrapperTypes } from '@nm-pot/common/constants';

import { isEligible } from './productEligibility';

export const getSISATabLabelKey = (eligibleProducts, products, status) => {
  if (
    eligibleProducts === null ||
    (products[wrapperTypes.SISA] && products[wrapperTypes.SISA].isPresent) ||
    status.active
  ) {
    return 'sisa';
  }

  const eligibleSISA = isEligible(eligibleProducts, wrapperTypes.SISA);
  const eligibleGIA = isEligible(eligibleProducts, wrapperTypes.GIA);

  if (!eligibleSISA) {
    return 'giaOnly';
  }

  if (!eligibleGIA) {
    return 'sisaOnly';
  }

  return 'sisa';
};

export const getSISAEmptyStateHeadingKey = (namespace, eligibleGIA, eligibleSISA) => {
  if (eligibleGIA && eligibleSISA) {
    return `${namespace}.emptyState.heading`;
  }

  return eligibleSISA ? `${namespace}.emptyState.sisaOnly.heading` : `${namespace}.emptyState.giaOnly.heading`;
};

export const getSISAEmptyStateDescriptionKey = type => (namespace, eligibleGIA, eligibleSISA) =>
  eligibleGIA && eligibleSISA
    ? `${namespace}.emptyState.description.${type}`
    : `${namespace}.emptyState.sisaOnly.description.${type}`;

export const getSISAEmptyStateDescriptionErrorKey = getSISAEmptyStateDescriptionKey('error');

export const getSISAEmptyStateDescriptionSuccessKey = getSISAEmptyStateDescriptionKey('success');
