import React from 'react';
import { OrganisationFooter } from '@nm-ui-shared-lib-web/organisation-footer';
import ThemeProvider, { themeNames } from '@nutkit/theme';

export const Footer = ({ ...nutkitProps }) => (
  <ThemeProvider themeName={themeNames.DARK}>
    <OrganisationFooter translationNameSpace="dashboard.common.footer" showAppDownload {...nutkitProps} />
  </ThemeProvider>
);

export default Footer;
