import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { getConfigurationFromThunderhead } from '@nm-marketing/thunderhead-utils';

function useGetConfigurationFromThunderhead(interactionPoint, { onError, userUuid } = {}) {
  const [configuration, setConfiguration] = useState(null);
  const [actions, setActions] = useState(null);
  const [properties, setProperties] = useState(null);
  const [error, setError] = useState(null);
  const onErrorRef = useRef(onError);

  useLayoutEffect(() => {
    onErrorRef.current = onError;
  }, [onError]);

  useEffect(() => {
    if (!interactionPoint) {
      return;
    }

    async function getConfiguration() {
      try {
        const { json, responses } = await getConfigurationFromThunderhead(
          interactionPoint,
          userUuid && { user_uuid: userUuid }
        );
        const { eventProperties, userProperties } = json;

        setActions(responses.reduce((acc, cur) => ({ ...acc, [cur.sentiment]: cur.code }), {}));
        setProperties({ eventProperties, userProperties });

        setConfiguration(json);
      } catch (err) {
        onErrorRef.current && onErrorRef.current(err);
        setError(err);
      }
    }

    getConfiguration();
  }, [interactionPoint, userUuid]);

  return { configuration, actions, properties, error };
}

export default useGetConfigurationFromThunderhead;
