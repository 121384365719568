import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import Align from '@nutkit/component-align';
import { Layout } from '@nutkit/layouts';
import { Heading, Text } from '@nutkit/component-text';
import Link, { buttonCtas } from '@nutkit/component-link';
import { ScrollToTop } from '@nm-utils-lib-web/scroll-to';
import { pageView } from '@nm-utils-lib-web/analytics';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { Portfolio } from '@nm-utils-lib-web/routes';

import { parseSearchParameters } from '../../helpers/parseSearchParameters';
import CustomerHeader from '../Header';
import Footer from '../Footer';
import ErrorPage from '../ErrorPage';

const TRANSLATION_NAMESPACE = 'dashboard.common';
const ELIGIBILITY_SERVICE_ERROR = 'error';

const EligibilityError = ({ location }) => {
  const { t } = useTranslation();
  const reason = parseSearchParameters(location.search)?.reason;
  const [title, message] =
    reason === ELIGIBILITY_SERVICE_ERROR
      ? [`${TRANSLATION_NAMESPACE}.error.title`, `${TRANSLATION_NAMESPACE}.error.defaultText`]
      : [`${TRANSLATION_NAMESPACE}.eligibilityError.title`, `${TRANSLATION_NAMESPACE}.eligibilityError.message`];

  useEffect(() => {
    const getReasonToReport = () => {
      if (reason === ELIGIBILITY_SERVICE_ERROR) {
        return 'eligibility_service_error';
      }

      if (reason) return reason;

      return 'overseas';
    };

    pageView({
      name: 'Eligibility_Error_Page',
      properties: { reason: getReasonToReport() }
    });
  }, [reason]);

  return (
    <ScrollToTop>
      <CustomerHeader />
      <Layout>
        <Heading data-qa="eligibility-error-heading">{t(`${title}`)}</Heading>
        <ErrorPage message={<Trans i18nKey={message} components={{ p: <Text /> }} />} />
        <Align xs={{ textAlign: 'right' }}>
          <Link
            button
            buttonCta={buttonCtas.PRIMARY}
            href={Portfolio.DASHBOARD_PATH}
            data-qa="eligibility-error-home-link"
          >
            {t(`${TRANSLATION_NAMESPACE}.controls.ok`)}
          </Link>
        </Align>
      </Layout>
      <Footer />
    </ScrollToTop>
  );
};

EligibilityError.propTypes = {
  location: shape({
    search: string
  })
};

EligibilityError.defaultProps = {
  location: {
    search: ''
  }
};

export default EligibilityError;
