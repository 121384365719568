import React, { useState, useRef } from 'react';
import { string } from 'prop-types';
import Panel from '@nutkit/component-panel';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';
import { Heading, Text, headingLevels, textColors, textSizes } from '@nutkit/component-text';
import { LinkWithTracking, event } from '@nm-utils-lib-web/analytics';
import { ButtonBase, buttonCtaTypes, buttonSizes } from '@nutkit/component-button';
import Icon, { iconColors, IconClose } from '@nutkit/component-icon';
import Align, { justifyContentRules, alignItemsRules } from '@nutkit/component-align';
import { Row, Col } from '@nutkit/component-grid';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import { isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { sendResponseCode } from '@nm-marketing/thunderhead-utils/helpers';
import classnames from 'classnames';

import useGetConfigurationFromThunderhead from '../../../../hooks/useGetConfigurationFromThunderhead';

import { getCtaPath } from './helpers';
import { mapOranisationToInteractionPoint } from './constants';
import {
  openDashboardPromptCardPayload,
  displayDashboardPromptCardPayload,
  dismissDashboardPromptCardPayload
} from './events';
import styles from './PromptCard.module.scss';

const TRANSLATION_NAMESPACE = 'dashboard.promptCards';
const IMGIX_PARAMS = '?auto=format,compress&w=406&h=273&dpr=2';

const PromptCard = ({ interactionPoint, userUuid }) => {
  const [isDismissed, setIsDismissed] = useState(false);
  const isDisplayTracked = useRef(false);
  const { t } = useTranslation();
  const { configuration, actions, properties, error } = useGetConfigurationFromThunderhead(interactionPoint, {
    userUuid
  });
  const ctaPath = configuration && getCtaPath(configuration);
  const { matchesCondition: isMediumBreakpoint } = useBreakpoint(breakpoints.MD, breakpointDirections.UP);
  const { matchesCondition: isLessThanLargeBreakpoint } = useBreakpoint(breakpoints.LG, breakpointDirections.DOWN);
  const isTablet = isMediumBreakpoint && isLessThanLargeBreakpoint;
  const isVisible = configuration && !error && !isDismissed && ctaPath;

  React.useEffect(() => {
    if (isVisible && actions && properties && !isDisplayTracked.current) {
      sendResponseCode(actions.neutral);
      event(displayDashboardPromptCardPayload(properties));
      isDisplayTracked.current = true;
    }
  });

  if (!isVisible) {
    return null;
  }

  const { title, description, ctaText, backgroundImage } = configuration;
  const style = { backgroundImage: `url(${backgroundImage}${IMGIX_PARAMS})` };

  return (
    <Section tagName={sectionTagNames.DIV} stackSpacing={stackSpacing.MD}>
      <Panel
        className={classnames(styles.panelWithImageAndGradientOverlay, {
          [styles.panelWithImageAndGradientOverlayFlat]: isJohnLewisFinanceGBR()
        })}
        style={style}
        data-qa="prompt-card"
        aria-label={t(`${TRANSLATION_NAMESPACE}.ariaLabel`)}
      >
        <Align justifyContent={justifyContentRules.SPACE_BETWEEN} alignItems={alignItemsRules.START}>
          <Heading level={headingLevels.TWO} textColor={textColors.WHITE} data-qa="prompt-card__title">
            {title}
          </Heading>
          <ButtonBase
            aria-label={t(`${TRANSLATION_NAMESPACE}.dismissButton.ariaLabel`)}
            onClick={() => {
              setIsDismissed(true);
              sendResponseCode(actions.negative);
              event(dismissDashboardPromptCardPayload(properties));
            }}
            data-qa="prompt-card__dismiss-button"
          >
            <Icon component={IconClose} color={iconColors.WHITE} aria-hidden />
          </ButtonBase>
        </Align>
        <Row>
          <Col xs={12} md={7} lg={12}>
            <Text
              textColor={textColors.WHITE}
              textSize={textSizes.XS}
              noStack={isTablet}
              data-qa="prompt-card__description"
            >
              {description}
            </Text>
          </Col>
          <Col xs={12} md={5} lg={12}>
            <LinkWithTracking
              {...ctaPath}
              button
              buttonCta={isJohnLewisFinanceGBR() ? buttonCtaTypes.TERTIARY : buttonCtaTypes.PRIMARY}
              isBlock
              size={buttonSizes.SM}
              data-qa="prompt-card__cta"
              onClick={() => {
                sendResponseCode(actions.positive);
              }}
              eventPayload={openDashboardPromptCardPayload(properties)}
            >
              {ctaText}
            </LinkWithTracking>
          </Col>
        </Row>
      </Panel>
    </Section>
  );
};

PromptCard.propTypes = {
  interactionPoint: string,
  userUuid: string
};

PromptCard.defaultProps = {
  interactionPoint: mapOranisationToInteractionPoint[window.NutmegConfig.ORGANISATION],
  userUuid: undefined
};

export default PromptCard;
