import { formatPeriodDate } from '@nm-portfolio-lib-web/common/helpers';
import i18n from 'i18next';

export const createPeriodFieldOptions = periods =>
  periods.map((period, index) => ({
    id: `${period}`,
    'data-qa': `isa-contributions-period-choice-${index}`,
    title: i18n.t('isaDetails.isaAllowance.contributions.periodChoice', {
      period: formatPeriodDate(period),
      interpolation: { escapeValue: false }
    })
  }));
