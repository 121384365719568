import { getTokens } from '@nutkit/react-utils';

// @TODO: Use translations for asset names but would require a BE update to avoid grepping on strings
const getAssetColor = name => {
  const tokens = getTokens();

  if (name.toLowerCase().includes('bonds')) {
    return tokens.NK_ALLOCATION_BONDS_COLOR;
  }

  if (name.toLowerCase().includes('money market')) {
    return tokens.NK_ALLOCATION_BONDS_COLOR;
  }

  if (name.toLowerCase().includes('equities')) {
    return tokens.NK_ALLOCATION_EQUITIES_COLOR;
  }

  return tokens.NK_OTHER_COLOR;
};

export default getAssetColor;
