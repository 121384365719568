import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import { getTokens } from '@nutkit/react-utils';

export const getProgressBars = ({ headroomSISA, headroomLISA, headroomJISA }) => {
  const tokens = getTokens();

  if (headroomSISA && headroomLISA)
    return [
      {
        wrapper: wrapperTypes.SISA,
        color: tokens.NK_WRAPPER_SISA_COLOR,
        percentage: headroomSISA.contributionsPercentage.ofAllowance
      },
      {
        wrapper: wrapperTypes.LISA,
        color: tokens.NK_WRAPPER_LISA_COLOR,
        percentage: headroomLISA.contributionsPercentage.ofAllowance
      }
    ];

  if (headroomSISA)
    return [
      {
        wrapper: wrapperTypes.SISA,
        color: tokens.NK_WRAPPER_SISA_COLOR,
        percentage: headroomSISA.contributionsPercentage.ofAllowance
      }
    ];

  if (headroomLISA)
    return [
      {
        wrapper: wrapperTypes.LISA,
        color: tokens.NK_WRAPPER_LISA_COLOR,
        percentage: headroomLISA.contributionsPercentage.ofAllowance
      }
    ];

  if (headroomJISA)
    return [
      {
        wrapper: wrapperTypes.JISA,
        color: tokens.NK_WRAPPER_JISA_COLOR,
        percentage: headroomJISA.contributionsPercentage.ofAllowance
      }
    ];

  return [];
};
