// TODO: Agree with the business for the names of the
// public query paramaters here.
export const INTENT = 'selected_intention';
export const ALLOWANCE = 'selected_allowance';
export const AUTOFILL = 'selected_autofill';
export const AUTORENEW = 'selected_autorenew';
export const TRANSFER_TYPE = 'selected_transfer_type';
export const OPEN_ISA_WRAPPER = 'selected_open_new_isa';
export const TRANSFER_DATA = 'transfer_data';
export const POT_UUID = 'potUuid';
export const ONBOARDING = 'onboarding';
export const MANUAL_TRANSFER = 'manual_transfer';
export const CASH_FLOW = 'cashPot';
export const NEXT_STEP = 'next_step';
