import React from 'react';
import { number, bool, shape } from 'prop-types';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import ProgressBar from '@nutkit/component-progress-bar';
import Amount, { amountStyle, amountSizes } from '@nutkit/component-amount';
import { Text, textStyles, tagNames } from '@nutkit/component-text';

import { getProgressBars } from '../helpers/getProgressBars';

const TRANSLATION_NAMESPACE = 'isaDetails.isaAllowance.contributions.progressBar';

const ISAContributionsProgressBar = ({ isCurrentPeriod, headroomTotal, headroomSISA, headroomLISA, headroomJISA }) => {
  const { t } = useTranslation();
  const dataQa = `ISA-contributions__Progress-bar`;

  return (
    <ProgressBar
      type="multi"
      data-qa={`${dataQa}-contributions`}
      labelLeft={
        <Text textStyle={textStyles.TEXT_2} tagName={tagNames.SPAN}>
          <Trans
            components={[
              <Amount
                value={headroomTotal.contributions}
                style={amountStyle.CURRENCY}
                data-qa={`${dataQa}-total-contributions-amount`}
                bolderWeight
                minDecimals={0}
              />
            ]}
            i18nKey={`${TRANSLATION_NAMESPACE}.contribution`}
          />
        </Text>
      }
      labelRight={
        <Text textStyle={textStyles.TEXT_2} tagName={tagNames.SPAN} noStack>
          {isCurrentPeriod ? t(`${TRANSLATION_NAMESPACE}.remainingLabel`) : t(`${TRANSLATION_NAMESPACE}.unusedLabel`)}{' '}
          <Amount
            value={headroomTotal.headroom}
            style={amountStyle.CURRENCY}
            data-qa={`${dataQa}-remaining-allowance-amount`}
            size={amountSizes.XS}
            bolderWeight
            minDecimals={0}
          />
        </Text>
      }
      secondaryLabelLeft={null}
      secondaryLabelRight={
        <Text textStyle={textStyles.TEXT_2} tagName={tagNames.SPAN}>
          {t(`${TRANSLATION_NAMESPACE}.allowanceLabel`)}{' '}
          <Amount
            value={headroomTotal.allowance}
            style={amountStyle.CURRENCY}
            size={amountSizes.XS}
            data-qa={`${dataQa}-overall-allowance-amount`}
            minDecimals={0}
          />
        </Text>
      }
    >
      {getProgressBars({ headroomSISA, headroomLISA, headroomJISA }).map(({ wrapper, color, percentage }) => (
        <ProgressBar key={wrapper} color={color} value={percentage} />
      ))}
    </ProgressBar>
  );
};

ISAContributionsProgressBar.propTypes = {
  isCurrentPeriod: bool.isRequired,
  headroomSISA: shape({
    contributionsPercentage: shape({
      ofAllowance: number.isRequired
    })
  }),
  headroomLISA: shape({
    contributionsPercentage: shape({
      ofAllowance: number.isRequired
    })
  }),
  headroomJISA: shape({
    contributionsPercentage: shape({
      ofAllowance: number.isRequired
    })
  }),
  headroomTotal: shape({
    contributions: number,
    headroom: number,
    allowance: number
  })
};

ISAContributionsProgressBar.defaultProps = {
  headroomSISA: undefined,
  headroomLISA: undefined,
  headroomJISA: undefined,
  headroomTotal: {
    contributions: 0,
    headroom: 0,
    allowance: 0
  }
};

export default ISAContributionsProgressBar;
