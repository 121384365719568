import { useSend } from '@nm-utils-lib-web/network/hooks';
import { PortfolioService } from '@nm-portfolio-lib-web/common/services';

const usePostIsaAllowance = ({ onError = () => {}, onSuccess = () => {} } = {}) => {
  const postIsaAllowance = async ({ customerUuid, sisaAllowance, lisaAllowance, period }) => {
    const isaSettingsUpdate = PortfolioService.postUpdateISAAllowance(
      customerUuid,
      sisaAllowance,
      lisaAllowance,
      period
    );

    return isaSettingsUpdate;
  };
  const { error, isLoading, response, send } = useSend({
    onError,
    onSuccess,
    sendFn: postIsaAllowance
  });

  return { error, isLoading, response, send };
};

export default usePostIsaAllowance;
