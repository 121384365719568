import React, { useState, useRef } from 'react';
import { string } from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Inline, { inlineSpaces } from '@nutkit/component-inline';
import Badge, { badgeColors, badgeSizes } from '@nutkit/component-badge';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import Section, { stackSpacing } from '@nutkit/component-section';
import { Modal } from '@nutkit/component-modal';
import { Video } from '@nutkit/component-video';
import Image, { imageSizes } from '@nutkit/component-image';
import { buttonCtas, linkSizes } from '@nutkit/component-link';
import { Text, textAligns, textSizes, textWeights } from '@nutkit/component-text';
import { LinkWithTracking, event } from '@nm-utils-lib-web/analytics';
import { sendResponseCode } from '@nm-marketing/thunderhead-utils/helpers';

import useGetConfigurationFromThunderhead from '../../../../hooks/useGetConfigurationFromThunderhead';

import { getCtaPath } from './helpers';
import { mapOranisationToInteractionPoint } from './constants';
import {
  dismissFeatureIntroPayload,
  displayFeatureIntroPayload,
  clickCtaPrimaryFeatureIntroPayload,
  clickCtaSecondaryFeatureIntroPayload
} from './events';

const featureIntroState = {
  OPEN: 'open',
  CLOSED: 'closed',
  DISMISSED: 'dismissed'
};
const TRANSLATION_NAMESPACE = 'dashboard.featureIntro';

const FeatureIntro = ({ userUuid }) => {
  const [modalState, setModalState] = useState(featureIntroState.OPEN);
  const interactionPoint = mapOranisationToInteractionPoint[window.NutmegConfig.ORGANISATION];
  const { configuration, actions, properties, error } = useGetConfigurationFromThunderhead(interactionPoint, {
    userUuid
  });
  const shouldRender = configuration && !error;
  const isDisplayTracked = useRef(false);
  const isVisible = shouldRender && modalState === featureIntroState.OPEN;
  const { t } = useTranslation();

  React.useEffect(() => {
    if (isVisible && actions && properties && !isDisplayTracked.current) {
      sendResponseCode(actions.neutral);
      event(displayFeatureIntroPayload(properties));
      isDisplayTracked.current = true;
    }
  });

  if (!shouldRender) {
    return null;
  }

  const handleDismiss = () => {
    if (featureIntroState.DISMISSED === modalState) {
      event(dismissFeatureIntroPayload(properties));
      sendResponseCode(actions.negative);
    }
  };
  const { title, copy, ctaPrimary, newFeature, imageUrl, videoUrl, htmlContent } = configuration;
  const hasSecondaryCta = configuration.ctaSecondary;
  const hasHtmlContent = !copy && !!htmlContent;
  // htmlContent will appear as uri encoded string so must be decoded back
  const presentableHtmlContent = hasHtmlContent ? decodeURIComponent(htmlContent) : undefined;
  let hero = null;

  if (videoUrl) {
    hero = (
      <Video
        autoPlay
        controls
        loop
        muted
        width="100%"
        aspectRatio="16/9"
        poster={imageUrl}
        sources={[
          {
            src: `${videoUrl}.webm`,
            type: 'video/webm'
          },
          {
            src: `${videoUrl}.mp4`,
            type: 'video/mp4'
          }
        ]}
      />
    );
  } else if (imageUrl) {
    hero = <Image data-qa="feature-intro__image" src={imageUrl} alt={title} size={imageSizes.COVER} />;
  }

  return (
    <Modal
      data-qa="feature-intro"
      onClosed={handleDismiss}
      isOpen={modalState === featureIntroState.OPEN}
      toggle={() => setModalState(featureIntroState.DISMISSED)}
      hero={hero}
    >
      <Section stackSpacing={stackSpacing.MD}>
        <Inline space={inlineSpaces.SM} wrap>
          <Text
            data-qa="feature-intro__title"
            textSize={textSizes.MD}
            textWeight={textWeights.BOLD}
            textAlign={textAligns.LEFT}
            noStack
          >
            {title}
          </Text>
          {newFeature && (
            <Badge size={badgeSizes.XS} backgroundColor={badgeColors.INFORMATION}>
              {t(`${TRANSLATION_NAMESPACE}.newFeature.badge.label`)}
            </Badge>
          )}
        </Inline>
      </Section>

      <Section stackSpacing={stackSpacing.LG}>
        {hasHtmlContent ? (
          <div data-qa="feature-intro__html_description">
            <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{presentableHtmlContent}</ReactMarkdown>
          </div>
        ) : (
          <Text data-qa="feature-intro__description">{copy}</Text>
        )}
      </Section>

      <ButtonGroup align={buttonGroupAlignments.RIGHT}>
        {hasSecondaryCta && (
          <LinkWithTracking
            {...getCtaPath(configuration.ctaSecondary)}
            data-qa="feature-intro__cta-secondary"
            button
            buttonCta={buttonCtas.SECONDARY}
            size={linkSizes.SM}
            onClick={() => {
              sendResponseCode(actions.positive);
              setModalState(featureIntroState.CLOSED);
            }}
            eventPayload={clickCtaSecondaryFeatureIntroPayload(properties)}
          >
            {configuration.ctaSecondary.ctaText}
          </LinkWithTracking>
        )}
        <LinkWithTracking
          {...getCtaPath(ctaPrimary)}
          data-qa="feature-intro__cta-primary"
          button
          buttonCta={buttonCtas.PRIMARY}
          size={linkSizes.SM}
          onClick={() => {
            sendResponseCode(actions.positive);
            setModalState(featureIntroState.CLOSED);
          }}
          eventPayload={clickCtaPrimaryFeatureIntroPayload(properties)}
        >
          {ctaPrimary.ctaText}
        </LinkWithTracking>
      </ButtonGroup>
    </Modal>
  );
};

FeatureIntro.propTypes = {
  userUuid: string
};

FeatureIntro.defaultProps = {
  userUuid: undefined
};

export default FeatureIntro;
