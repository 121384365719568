const QA_DETAILS_BACK_TO_DASHBOARD = 'portfolio-web__portfolio-details__back-to-dashboard';
const QA_PORTFOLIO_DETAILS_VALUE_TAB = 'portfolio-web__portfolio-details-link-value';
const QA_PORTFOLIO_DETAILS_ALLOCATION_TAB = 'portfolio-web__portfolio-details-link-allocation';

class PortfolioDetailsTestObject {
  goToDashboard() {
    cy.qa(QA_DETAILS_BACK_TO_DASHBOARD);
  }

  goToValueTab() {
    cy.qa(QA_PORTFOLIO_DETAILS_VALUE_TAB).click();
  }

  goToAllocationTab() {
    cy.qa(QA_PORTFOLIO_DETAILS_ALLOCATION_TAB).click();
  }
}

export default new PortfolioDetailsTestObject();
