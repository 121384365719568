import React from 'react';
import { arrayOf, object, number, func, string, bool } from 'prop-types';
import Notification from '@nutkit/component-notification';
import Bold, { boldWeights } from '@nutkit/component-bold';
import Amount, { amountStyle } from '@nutkit/component-amount';
import { Trans } from '@nm-utils-lib-web/translations';
import Button, { buttonCtaTypes } from '@nutkit/component-button';
import { cancelPendingWithdrawals } from '@nm-payments/intra-pot-transfer/services/potTransferService';
import { postWithdrawCancel } from '@nm-payments/withdraw/services/postWithdrawCancel';
import { Flags, useFlag } from '@nm-utils-lib-web/flags';
import { event } from '@nm-utils-lib-web/analytics';

import CancelPendingWithdrawalModal from '../CancelPendingWithdrawalModal';
import { MODAL_STATUS } from '../../constants/CancelPendingWithdrawals';
import { cancelAllPendingWithdrawalsPayload } from '../../../../tracking/events/productList';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.pendingWithdrawal';

export const PendingWithdrawal = ({
  t,
  pendingWithdrawalPots,
  totalPendingWithdrawals,
  updatePots,
  userUuid,
  hasPendingWrapperWithdrawals
}) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const isBDVWithdrawalsFlagEnabled = useFlag(Flags.BANK_DETAILS_VERIFICATION_WITHDRAWALS);

  const closeModal = () => {
    if (status !== MODAL_STATUS.LOADING) {
      setStatus(MODAL_STATUS.DEFAULT);
      setModalOpen(false);
    }
  };

  const handleCancelPendingWithdrawals = async () => {
    setStatus(MODAL_STATUS.LOADING);

    try {
      if (isBDVWithdrawalsFlagEnabled) {
        await postWithdrawCancel({ customerId: userUuid });
      } else {
        await cancelPendingWithdrawals({ userUuid });
      }

      setStatus(MODAL_STATUS.DEFAULT);
      closeModal();
      updatePots();
    } catch (error) {
      setStatus(MODAL_STATUS.ERROR);
    }
  };

  return (
    <>
      <Notification dismissable={false} data-qa="pending-withdrawal__notification">
        {hasPendingWrapperWithdrawals ? (
          t(`${TRANSLATION_NAMESPACE}.wrapper.description`)
        ) : (
          <Trans
            i18nKey={`${TRANSLATION_NAMESPACE}.description`}
            components={[
              <Bold weight={boldWeights.BOLD}>
                <Amount
                  value={totalPendingWithdrawals}
                  style={amountStyle.CURRENCY}
                  bolderWeight
                  noColor
                  minDecimals={0}
                />
              </Bold>
            ]}
          />
        )}{' '}
        <Button
          onClick={() => {
            openModal();
            event(cancelAllPendingWithdrawalsPayload());
          }}
          cta={buttonCtaTypes.LINK}
          aria-label={t(`${TRANSLATION_NAMESPACE}.cancelwithdrawal.ariaLabel`)}
          data-qa="pending-withdrawal__notification__cancel-button"
          style={{ textDecoration: 'underline', color: 'inherit' }}
        >
          {t(`${TRANSLATION_NAMESPACE}.cancelwithdrawal.label`)}
        </Button>
      </Notification>
      <CancelPendingWithdrawalModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        pendingWithdrawalPots={pendingWithdrawalPots}
        amount={totalPendingWithdrawals}
        cancelPendingWithdrawals={handleCancelPendingWithdrawals}
        isCancelling={status === MODAL_STATUS.LOADING}
        isError={status === MODAL_STATUS.ERROR}
        hasPendingWrapperWithdrawals={hasPendingWrapperWithdrawals}
        data-qa="pending-withdrawal__modal"
      />
    </>
  );
};

PendingWithdrawal.propTypes = {
  pendingWithdrawalPots: arrayOf(object).isRequired,
  totalPendingWithdrawals: number.isRequired,
  updatePots: func.isRequired,
  userUuid: string.isRequired,
  hasPendingWrapperWithdrawals: bool,
  t: func.isRequired
};

PendingWithdrawal.defaultProps = {
  hasPendingWrapperWithdrawals: undefined
};

export default PendingWithdrawal;
