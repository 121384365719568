import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { Row, Col } from '@nutkit/component-grid';
import Box from '@nutkit/component-box';
import Panel from '@nutkit/component-panel';
import { Heading, Text, textStyles, headingLevels } from '@nutkit/component-text';
import Loading from '@nutkit/component-loading';
import Select, { selectSizes } from '@nutkit/component-select';
import Amount, { amountStyle, amountSizes } from '@nutkit/component-amount';
import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import { formatPeriodDate } from '@nm-portfolio-lib-web/common/helpers';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import useGetHeadroom from '@nm-portfolio-lib-web/common/hooks/useGetHeadroom';
import useGetISAStatus from '@nm-portfolio-lib-web/common/hooks/useGetISAStatus';
import { useTokens } from '@nutkit/react-utils';

import { createPeriodFieldOptions } from '../../utils/createPeriodFieldOptions';

import ISAContributionsProgressBar from './ISAContributionsProgressBar';
import ISAContributionsBox from './ISAContributionsBox';

const TRANSLATION_NAMESPACE = 'dashboard.isaAllowance.contributions';

const ISAContributions = ({ customerUuid }) => {
  const dataQa = 'ISA-contributions';
  const { t } = useTranslation();
  const { tokens } = useTokens();
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const { data: statusData, isLoading: isStatusLoading } = useGetISAStatus({ customerUuid });
  const { data: headroomData, isLoading: isHeadroomLoading, error: headroomError } = useGetHeadroom({
    shouldMakeRequest: !!selectedPeriod,
    customerUuid,
    period: selectedPeriod
  });

  useEffect(() => {
    setSelectedPeriod(statusData && statusData.currentPeriod);
  }, [statusData]);

  if (isHeadroomLoading || isStatusLoading) return <Loading />;

  if (headroomError) {
    return (
      <Notification data-qa={`${dataQa}__error`} level={notificationLevels.ERROR} dismissable={false}>
        {t('isaDetails.isaAllowance.loadingError')}
      </Notification>
    );
  }

  const headroomSISA = headroomData.byWrapper.sisa;
  const headroomLISA = headroomData.byWrapper.lisa;
  const headroomJISA = headroomData.byWrapper.jisa;
  const statusJISA = statusData.byWrapper.jisa;
  const periods = statusData.periods;
  const isCurrentPeriod = statusData.currentPeriod === selectedPeriod;
  const untilJISAHeld = statusJISA && statusJISA.periodsHeld.includes(selectedPeriod);
  const SISAContributions = headroomSISA && headroomSISA.contributions;
  const LISAContributions = headroomLISA && headroomLISA.contributions;

  return (
    <Panel>
      <Row>
        <Col>
          <Heading level={headingLevels.TWO} data-qa={`${dataQa}__contributions-heading`}>
            {t(`${TRANSLATION_NAMESPACE}.heading`, {
              periodLabel: formatPeriodDate(selectedPeriod),
              interpolation: { escapeValue: false }
            })}
          </Heading>
        </Col>
        <Col sm="4">
          <Select
            title={t(`${TRANSLATION_NAMESPACE}.periodSelectTitle`)}
            size={selectSizes.XS}
            options={createPeriodFieldOptions(periods)}
            onSelect={period => setSelectedPeriod(period)}
            data-qa={`${dataQa}__contribution-periods`}
            value={selectedPeriod}
          />
        </Col>
      </Row>

      <ISAContributionsProgressBar
        isCurrentPeriod={isCurrentPeriod}
        headroomTotal={headroomData.total}
        headroomSISA={headroomSISA}
        headroomLISA={headroomLISA}
        headroomJISA={headroomJISA}
      />
      <Row>
        {isCurrentPeriod || !!SISAContributions ? (
          <ISAContributionsBox
            wrapperType={wrapperTypes.SISA}
            isCurrentPeriod={isCurrentPeriod}
            headroomData={headroomSISA}
            untilJISAHeld={untilJISAHeld}
          />
        ) : null}

        {typeof LISAContributions !== 'undefined' ? (
          <ISAContributionsBox
            wrapperType={wrapperTypes.LISA}
            isCurrentPeriod={isCurrentPeriod}
            headroomData={headroomLISA}
            untilJISAHeld={untilJISAHeld}
          />
        ) : null}
      </Row>

      {isCurrentPeriod && (!!headroomSISA || !!headroomLISA) ? (
        <Text textStyle={textStyles.TEXT_2} data-qa={`${dataQa}__pending-contributions-copy`}>
          {t(`${TRANSLATION_NAMESPACE}.pendingContributionsText`)}
        </Text>
      ) : null}

      {untilJISAHeld ? (
        <Row>
          <Col md="12" data-qa={`${dataQa}__Box-jisa`}>
            <Heading level={headingLevels.TWO} data-qa={`${dataQa}__Box-jisa-heading`}>
              {t(`${TRANSLATION_NAMESPACE}.jisa.heading`)}
            </Heading>
            <Box stripeColor={tokens.NK_WRAPPER_JISA_COLOR}>
              <Text textStyle={textStyles.TEXT_2} data-qa={`${dataQa}__Box-jisa-title`}>
                {t(`${TRANSLATION_NAMESPACE}.jisa.boxTitle`)}
              </Text>
              <Amount
                value={headroomJISA && headroomJISA.contributions}
                size={amountSizes.MD}
                style={amountStyle.CURRENCY}
                data-qa={`${dataQa}__Box-jisa-current-period-contributions`}
                minDecimals={0}
              />
            </Box>
            <Notification dismissable={false} noStack>
              {t(`${TRANSLATION_NAMESPACE}.jisa.closedJisaInfo`)}
            </Notification>
          </Col>
        </Row>
      ) : null}
    </Panel>
  );
};

ISAContributions.propTypes = {
  customerUuid: string.isRequired
};

export default ISAContributions;
