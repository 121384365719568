import { POT_STATUSES } from '@nm-pot/common/constants';

import { JISA } from '../../../constants/PotWrappers';

export const getInactivePots = pots => {
  if (!pots) return [];

  return pots.filter(
    ({ status, investedDate, wrapper: { key } }) =>
      ((status === POT_STATUSES.CLOSED && !!investedDate) || status === POT_STATUSES.CLOSING) && key !== JISA
  );
};

export const getActivePots = pots => {
  if (!pots) return [];

  return pots.filter(({ status, wrapper: { key } }) => status === POT_STATUSES.ACTIVE && key !== JISA);
};
