import Container from './components/StartASISAContainer';
import OpenOrTransfer from './components/OpenOrTransferSISA';
import SetAllowanceSISA from './components/SetAllowanceSISA';
import TransferSISA from './components/TransferSISA';
import TransferSISAOpen from './components/TransferSISAOpen';
import TransferFullSISA from './components/TransferFullSISA';
import TransferDownloadFormSISA from './components/TransferDownloadFormSISA';
import TransferSubmittedSISA from './components/TransferSubmittedSISA';

export const StartASISA = {
  Container,
  OpenOrTransfer,
  SetAllowanceSISA,
  TransferSISA,
  TransferSISAOpen,
  TransferFullSISA,
  TransferDownloadFormSISA,
  TransferSubmittedSISA
};
