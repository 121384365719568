import { useReducer } from 'react';

import useActions from './useActions';
import useMiddleware from './useMiddleware';

function useStore(initialValue, reducer, pureActions, middlewares = []) {
  const [state, dispatch] = useReducer(reducer, initialValue);
  const enhancedDispatch = useMiddleware(dispatch, middlewares);
  const actions = useActions(pureActions, enhancedDispatch);

  return { state, actions };
}

export default useStore;
