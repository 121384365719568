import React from 'react';
import { string, func } from 'prop-types';
import Section from '@nutkit/component-section';
import { TabGroupControlled, TabItem } from '@nutkit/component-tabs';
import Amount, { amountStyle, amountSizes } from '@nutkit/component-amount';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { Heading, headingLevels, Text, textStyles } from '@nutkit/component-text';
import DateFormat from '@nutkit/component-date-format';
import Loading from '@nutkit/component-loading';

import { useGetPerformanceSummary } from '../../../../hooks/useGetPerformanceSummary';
import { useGetCustomerPerformanceSummary } from '../../../../hooks/useGetCustomerPerformanceSummary';
import { TIMEFRAME_OFFSETS, PERFORMANCE_INDICATORS } from '../../../PortfolioDashboard/constants';
import { displayPerformanceValue } from '../../../PortfolioDashboard/helpers';
import { PORTFOLIO_DIG_DEEPER_EVENTS } from '../../events';
import PortfolioPerformanceDetails from '../PortfolioPerformanceDetails';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioValue';

const PortfolioValuation = ({ customerUuid, onError }) => {
  const { t } = useTranslation();
  const {
    data: performanceSummaryAllTimeFrame,
    isLoading: isPerformanceSummaryLoadingAllTimeFrame
  } = useGetPerformanceSummary({
    customerUuid,
    context: t(`${TRANSLATION_NAMESPACE}.context`),
    onError
  });
  const { data: performanceSummary30DTimeFrame } = useGetPerformanceSummary({
    customerUuid,
    period: TIMEFRAME_OFFSETS.ONE_MONTH,
    context: t(`${TRANSLATION_NAMESPACE}.context`)
  });
  const {
    data: customerPerformanceSummary,
    isLoading: isCustomerPerformanceSummaryLoading
  } = useGetCustomerPerformanceSummary({
    customerUuid,
    timeframe: [TIMEFRAME_OFFSETS.ALL, TIMEFRAME_OFFSETS.THIRTY_DAYS],
    indicatorType: [PERFORMANCE_INDICATORS.TWRR, PERFORMANCE_INDICATORS.SR],
    context: t(`${TRANSLATION_NAMESPACE}.context`)
  });
  let simpleReturnPercent, timeWeightedReturnPercent, twrrLastUpdatedAt;
  let simpleReturnPercent30D, timeWeightedReturnPercent30D, twrrLastUpdatedAt30D;
  let twrrExcluded = false;
  let twrrExcluded30D = false;
  const { portfolioValue, netContributions, returnValue, lastUpdatedAt } = performanceSummaryAllTimeFrame || {};
  const { netContributions: netContributions30D, returnValue: returnValue30D } = performanceSummary30DTimeFrame || {};

  if (customerPerformanceSummary) {
    simpleReturnPercent = customerPerformanceSummary[TIMEFRAME_OFFSETS.ALL].indicators.SR.value;
    timeWeightedReturnPercent = customerPerformanceSummary[TIMEFRAME_OFFSETS.ALL].indicators.TWRR.value;
    twrrLastUpdatedAt = customerPerformanceSummary[TIMEFRAME_OFFSETS.ALL].indicators.TWRR.updatedAt;
    twrrExcluded = customerPerformanceSummary[TIMEFRAME_OFFSETS.ALL].indicators.TWRR.isExcluded || false;

    simpleReturnPercent30D = customerPerformanceSummary[TIMEFRAME_OFFSETS.THIRTY_DAYS].indicators.SR.value;
    timeWeightedReturnPercent30D = customerPerformanceSummary[TIMEFRAME_OFFSETS.THIRTY_DAYS].indicators.TWRR.value;
    twrrLastUpdatedAt30D = customerPerformanceSummary[TIMEFRAME_OFFSETS.THIRTY_DAYS].indicators.TWRR.updatedAt;
    twrrExcluded30D = customerPerformanceSummary[TIMEFRAME_OFFSETS.THIRTY_DAYS].indicators.TWRR.isExcluded || false;
  }

  return (
    <section>
      {isPerformanceSummaryLoadingAllTimeFrame || isCustomerPerformanceSummaryLoading ? (
        <Loading />
      ) : (
        <>
          <Heading data-qa="portfolio-web__valuation-title" level={headingLevels.TWO} noStack>
            {t(`${TRANSLATION_NAMESPACE}.heading`)}
          </Heading>
          {lastUpdatedAt && (
            <Text textStyle={textStyles.TEXT_2} noStack>
              {t(`${TRANSLATION_NAMESPACE}.updatedAt`)}{' '}
              <DateFormat
                date={lastUpdatedAt}
                options={{
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                }}
              />
            </Text>
          )}
          <Section>
            <Amount
              size={amountSizes.XL}
              style={amountStyle.CURRENCY}
              value={displayPerformanceValue(portfolioValue)}
            />
          </Section>

          <TabGroupControlled
            fullWidth={false}
            defaultTab={t(`${TRANSLATION_NAMESPACE}.tabGroup.allTime`)}
            onChange={index => {
              const timeFrame = Object.values({ alltime: 'All Time', last30Days: '30 Days' })[index];

              PORTFOLIO_DIG_DEEPER_EVENTS.Select_Performance_Timeframe({ timeFrame });
            }}
          >
            <TabItem title={t(`${TRANSLATION_NAMESPACE}.tabGroup.allTime`)}>
              <PortfolioPerformanceDetails
                lastUpdatedAt={lastUpdatedAt}
                twrrLastUpdatedAt={twrrLastUpdatedAt}
                netContributions={netContributions}
                returnValue={returnValue}
                simpleReturnPercent={simpleReturnPercent}
                timeWeightedReturnPercent={timeWeightedReturnPercent}
                twrrExcluded={twrrExcluded}
              />
            </TabItem>
            <TabItem title={t(`${TRANSLATION_NAMESPACE}.tabGroup.lastThirtyDays`)}>
              <PortfolioPerformanceDetails
                lastUpdatedAt={lastUpdatedAt}
                twrrLastUpdatedAt={twrrLastUpdatedAt30D}
                netContributions={netContributions30D}
                returnValue={returnValue30D}
                simpleReturnPercent={simpleReturnPercent30D}
                timeWeightedReturnPercent={timeWeightedReturnPercent30D}
                twrrExcluded={twrrExcluded30D}
              />
            </TabItem>
          </TabGroupControlled>
        </>
      )}
    </section>
  );
};

PortfolioValuation.propTypes = {
  customerUuid: string.isRequired,
  onError: func.isRequired
};

export default PortfolioValuation;
