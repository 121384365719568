import React from 'react';
import { string, func } from 'prop-types';
import Amount, { amountSizes, amountStyle } from '@nutkit/component-amount';
import { Heading, Text, textStyles, textWeights, headingLevels } from '@nutkit/component-text';
import Loading from '@nutkit/component-loading';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { Table, TableBody, TableRow, TableCell, TableHead, TableHeadCell, tableAligns } from '@nutkit/component-table';
import { useTranslation } from '@nm-utils-lib-web/translations';

import { useGetPerformanceSummary } from '../../../../hooks/useGetPerformanceSummary';

const breakdownFeeTableRows = t => {
  return {
    netContributions: {
      ariaLabel: t(`${TRANSLATION_NAMESPACE}.netContributionsAllTime.ariaLabel`),
      label: t(`${TRANSLATION_NAMESPACE}.netContributionsAllTime.label`),
      isBold: false
    },
    investmentReturns: {
      ariaLabel: t(`${TRANSLATION_NAMESPACE}.investmentReturnsAllTime.ariaLabel`),
      label: t(`${TRANSLATION_NAMESPACE}.investmentReturnsAllTime.label`),
      isBold: false
    },
    totalFees: {
      ariaLabel: t(`${TRANSLATION_NAMESPACE}.totalFees.ariaLabel`),
      label: t(`${TRANSLATION_NAMESPACE}.totalFees.label`),
      isBold: false
    },
    currentValue: {
      ariaLabel: t(`${TRANSLATION_NAMESPACE}.currentValue.ariaLabel`),
      label: t(`${TRANSLATION_NAMESPACE}.currentValue.label`),
      isBold: true
    }
  };
};
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioFeesBreakdown';

const PortfolioFeesBreakdown = ({ customerUuid, onError }) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetPerformanceSummary({
    customerUuid,
    context: t(`${TRANSLATION_NAMESPACE}.context`),
    onError
  });

  if (isLoading) {
    return (
      <>
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Heading data-qa="portfolio-web__fees-breakdown-title" level={headingLevels.TWO}>
          {t(`${TRANSLATION_NAMESPACE}.title`)}
        </Heading>
        <Notification dismissable={false} level={notificationLevels.ERROR}>
          {t(`${TRANSLATION_NAMESPACE}.error`)}
        </Notification>
      </>
    );
  }

  const { portfolioValue, netContributions, returnValue, feeDetail } = data || {};
  const totalFees = feeDetail?.totalFees;
  const breakdownFeeValueMap = {
    netContributions,
    investmentReturns: returnValue,
    totalFees,
    currentValue: portfolioValue
  };

  return (
    <>
      <Heading data-qa="portfolio-web__fees-breakdown-title" level={headingLevels.TWO}>
        {t(`${TRANSLATION_NAMESPACE}.title`)}
      </Heading>

      <Table striped>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Text noStack textStyle={textStyles.TEXT_2} textWeight={textWeights.BOLD}>
                {t(`${TRANSLATION_NAMESPACE}.description`)}
              </Text>
            </TableHeadCell>
            <TableHeadCell textAlign={tableAligns.RIGHT}>
              <Text noStack textStyle={textStyles.TEXT_2} textWeight={textWeights.BOLD}>
                {t(`${TRANSLATION_NAMESPACE}.amount`)}
              </Text>
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(breakdownFeeTableRows(t)).map(key => {
            const { label, ariaLabel, isBold } = breakdownFeeTableRows(t)[key];
            const value = breakdownFeeValueMap[key];

            return (
              <TableRow key={label} aria-label={ariaLabel}>
                <TableCell>
                  <Text
                    noStack
                    textWeight={isBold ? textWeights.BOLD : textWeights.REGULAR}
                    textStyle={textStyles.TEXT_2}
                  >
                    {label}
                  </Text>
                </TableCell>
                <TableCell textAlign={tableAligns.RIGHT}>
                  <Text
                    noStack
                    textWeight={isBold ? textWeights.BOLD : textWeights.REGULAR}
                    textStyle={textStyles.TEXT_2}
                  >
                    <Amount bolderWeight={isBold} style={amountStyle.CURRENCY} value={value} size={amountSizes.XS} />
                  </Text>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

PortfolioFeesBreakdown.propTypes = {
  customerUuid: string.isRequired,
  onError: func.isRequired
};

PortfolioFeesBreakdown.defaultProps = {};

export default PortfolioFeesBreakdown;
