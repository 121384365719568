import React from 'react';
import { Pot } from '@nm-utils-lib-web/routes';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Heading, Text } from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { linkSizes } from '@nutkit/component-link';
import Section from '@nutkit/component-section';

import { createNewCashPotClickedPayload, createNewPotClickedPayload } from '../../../../../tracking/events/productList';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.sisaTab';

export const Sisa = () => {
  const { t } = useTranslation();

  return (
    <Section stackSpacing="lg">
      <Panel data-qa="product-list__sisa-tab__create-pot">
        <Heading level="3" noStack>
          {t(`${TRANSLATION_NAMESPACE}.create.title`)}
        </Heading>

        <Text noStack textStyle="text-1" textWeight="regular">
          {t(`${TRANSLATION_NAMESPACE}.create.description`)}
        </Text>

        <ButtonGroup align={buttonGroupAlignments.RIGHT} stackUp stackOnMobile>
          <LinkWithTracking
            key="createCashLink"
            button
            buttonCta="secondary"
            size={linkSizes.SM}
            aria-label={t(`${TRANSLATION_NAMESPACE}.create.links.cash.ariaLabel`)}
            href={Pot.getPotNewPath({
              wrapperType: WRAPPER_TYPES.SISA,
              isCashPot: true,
              productType: WRAPPER_TYPES.GIA
            })}
            eventPayload={createNewCashPotClickedPayload({ wrapperType: WRAPPER_TYPES.SISA })}
          >
            {t(`${TRANSLATION_NAMESPACE}.create.links.cash.label`)}
          </LinkWithTracking>

          <LinkWithTracking
            key="createInvesmentLink"
            button
            buttonCta="primary"
            size={linkSizes.SM}
            aria-label={t(`${TRANSLATION_NAMESPACE}.create.links.investment.ariaLabel`)}
            href={Pot.getPotNewPath({ wrapperType: WRAPPER_TYPES.SISA, productType: WRAPPER_TYPES.GIA })}
            eventPayload={createNewPotClickedPayload({ wrapperType: WRAPPER_TYPES.SISA })}
          >
            {t(`${TRANSLATION_NAMESPACE}.create.links.investment.label`)}
          </LinkWithTracking>
        </ButtonGroup>
      </Panel>
    </Section>
  );
};
