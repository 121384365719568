import React, { useMemo } from 'react';
import { string, number, bool, shape, func } from 'prop-types';
import { Text, Heading, headingLevels, textStyles } from '@nutkit/component-text';
import Link from '@nutkit/component-link';
import Disclosure, { SummaryWithMultiTitle } from '@nutkit/component-disclosure';
import { FocusableWrapper } from '@nutkit/accessibility';
import { SupportArticles } from '@nm-utils-lib-web/routes';
import { useTranslation } from '@nm-utils-lib-web/translations';

import HoldingsInformation from '../HoldingsInformation';

const ALLOCATION_PERCENTAGE_SUFFIX = '%';
const ALLOCATION_MIN_DECIMALS = 1;
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioInvestments';

export const SummaryWithMultiTitleFocusable = ({ isOpen, forceFocus, ...otherProps }) => {
  const { fieldPrimary, fieldSecondary, highlightPrimary } = otherProps;

  return useMemo(
    () => (
      <FocusableWrapper focus={forceFocus}>
        <SummaryWithMultiTitle
          isOpen={isOpen}
          fieldPrimary={fieldPrimary}
          fieldSecondary={fieldSecondary}
          highlightPrimary={highlightPrimary}
          secondaryHideOnOpen
        />
      </FocusableWrapper>
    ),
    [fieldPrimary, fieldSecondary, forceFocus, highlightPrimary, isOpen]
  );
};

const InvestmentListItem = ({
  code,
  allocation,
  name,
  description,
  forceFocus,
  isActual,
  actualLabels,
  actualValues,
  onInvestmentClick,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const formattedAllocation = Number(allocation).toFixed(ALLOCATION_MIN_DECIMALS) + ALLOCATION_PERCENTAGE_SUFFIX;

  return (
    <Disclosure
      {...otherProps}
      summary={({ isOpen }) => (
        <SummaryWithMultiTitleFocusable
          isOpen={isOpen}
          forceFocus={forceFocus}
          fieldPrimary={code}
          fieldSecondary={name}
          highlightPrimary={formattedAllocation}
        />
      )}
    >
      <Text textStyle={textStyles.TEXT_2} noStack>
        {t(`${TRANSLATION_NAMESPACE}.investments`)}
      </Text>
      {actualValues ? (
        <>
          <Heading level={headingLevels.THREE}>
            <Link
              data-qa="investment-list-item-investment-link"
              href={`${SupportArticles.OUR_INVESTMENTS}#${code}`}
              isExternal
              onClick={() => onInvestmentClick && onInvestmentClick({ ticker: code })}
            >
              {name}
            </Link>
          </Heading>
          <Text textStyle={textStyles.TEXT_2}>{description}</Text>
          <HoldingsInformation labels={actualLabels} values={actualValues} />
        </>
      ) : (
        <>
          <Heading level={headingLevels.THREE}>{name}</Heading>
          <Text textStyle={textStyles.TEXT_2}>{description}</Text>
        </>
      )}
    </Disclosure>
  );
};

InvestmentListItem.propTypes = {
  forceFocus: bool,
  code: string.isRequired,
  allocation: number.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  isActual: bool,
  actualValues: shape({
    amount: number,
    price: number,
    value: number
  }),
  actualLabels: shape({
    amount: string,
    price: string,
    value: string
  }),
  onInvestmentClick: func
};

InvestmentListItem.defaultProps = {
  forceFocus: false,
  isActual: false,
  actualValues: null,
  actualLabels: {
    amount: 'No. Shares',
    price: 'Share price',
    value: 'Total value'
  },
  onInvestmentClick: null
};

export default InvestmentListItem;
