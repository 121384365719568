import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';

import { ISA_ALLOWANCE_EVENTS } from '../events';
import {
  ON_SISA_ALLOWANCE_PAY_IN_LINK_CLICKED,
  ON_LISA_ALLOWANCE_PAY_IN_LINK_CLICKED,
  ON_SISA_ALLOWANCE_OPEN_LINK_CLICKED
} from '../events/eventTypes';

export const handleTrackPayInLink = wrapper =>
  wrapper === wrapperTypes.SISA
    ? ISA_ALLOWANCE_EVENTS[ON_SISA_ALLOWANCE_PAY_IN_LINK_CLICKED]
    : ISA_ALLOWANCE_EVENTS[ON_LISA_ALLOWANCE_PAY_IN_LINK_CLICKED];
export const handleTrackOpenLink = () => ISA_ALLOWANCE_EVENTS[ON_SISA_ALLOWANCE_OPEN_LINK_CLICKED];
