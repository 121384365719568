/**
 * Strips decimals and returns value without decimals
 *
 * @param {Number} value Value to strip decimals
 */
function stripDecimals(value) {
  if (typeof value === 'number') {
    return value - (value % 1);
  }

  return value;
}

export default stripDecimals;
