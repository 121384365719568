import * as React from 'react';

import postDefineInitialSettings from '../../services/PortfolioService/methods';

const useDefineInitialSettings = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const defineInitialSettings = React.useCallback(async ({ userUuid, wrapperType, payload, onComplete }) => {
    try {
      setIsLoading(true);

      const { data } = await postDefineInitialSettings(userUuid, wrapperType, payload);

      onComplete(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { defineInitialSettings, isLoading, error };
};

export default useDefineInitialSettings;
