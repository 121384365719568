import useSWR from 'swr';
import { getSWRIsLoading } from '@nm-utils-lib-web/network/swr';

import { getPortfolioHoldings } from '../../services/PotHoldingService/portfolioHoldingsService';

const useGetPortfolioHoldings = ({ customerUuid, onError, shouldMakeRequest = true, swrOptions }) => {
  const cacheKey = `useGetPortfolioHoldings/${customerUuid}`;
  const { data, error } = useSWR(shouldMakeRequest ? cacheKey : null, () => getPortfolioHoldings({ customerUuid }), {
    revalidateOnFocus: false,
    onError: error => onError && onError(error),
    ...swrOptions
  });
  const isLoading = getSWRIsLoading(data, error, shouldMakeRequest);

  return {
    data: (data && data.holdings) || [],
    isLoading,
    error
  };
};

export default useGetPortfolioHoldings;
