import useSWR from 'swr';
import { getSWRNetworkStatus, STATES as networkStates } from '@nm-utils-lib-web/network/swr';

import { getPerformanceSummary } from '../../services/PerformanceSummaryService/performanceSummaryService';

function useGetPerformanceSummary({ customerUuid, context, onError, period, ...otherProps }) {
  const { data, error, isValidating } = useSWR(
    `getPerformanceSummary/${customerUuid}${period ? `?period=${period}` : ''}`,
    () => getPerformanceSummary({ customerUuid, period }),
    {
      revalidateOnFocus: false,
      onError: error => onError && onError({ context, error }),
      ...otherProps
    }
  );
  const isLoading = [networkStates.INITIAL, networkStates.LOADING, networkStates.REVALIDATING].includes(
    getSWRNetworkStatus({ data, isValidating, error })
  );

  return { data, isLoading, error };
}

export default useGetPerformanceSummary;
