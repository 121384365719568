import React from 'react';
import { object } from 'prop-types';
import Amount, { amountStyle, amountSizes, amountTypes } from '@nutkit/component-amount';
import { Row, Col } from '@nutkit/component-grid';
import Panel from '@nutkit/component-panel';
import { Text, Heading, headingLevels, textWeights, textAligns } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import Icon, { IconChevronRight, iconSizes } from '@nutkit/component-icon';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.systemPotOverview';

export const SystemPotOverview = ({ systemPot }) => {
  const { t } = useTranslation();

  return (
    <Panel
      aria-label={t(`${TRANSLATION_NAMESPACE}.overviewAriaLabel`, {
        currentValue: systemPot.currentValue
      })}
      data-qa="dashboard-web__system-pot-overview"
      data-qa-status={systemPot.status}
      role="group"
    >
      <Row>
        <Col xs={{ size: 7 }} aria-hidden>
          <Heading level={headingLevels.THREE} noStack>
            <LinkWithTracking
              eventPayload={
                // TODO: add analytics event payload
                {}
              }
              href={
                // TODO: update url
                '/PLACEHOLDER_URL'
              }
              data-qa="dashboard-web__system-pot-name"
            >
              {t(`${TRANSLATION_NAMESPACE}.incomePotLabel`)}
              <Icon
                component={IconChevronRight}
                size={iconSizes.MD}
                viewBox="0 0 24 26"
                data-qa="dashboard-web__system-pot-name-icon"
              />
            </LinkWithTracking>
          </Heading>
        </Col>
        <Col xs={{ size: 5 }}>
          <Text textWeight={textWeights.BOLD} textAlign={textAligns.RIGHT} noStack noLineHeight>
            <Amount
              maxDecimals={2}
              minDecimals={0}
              size={amountSizes.MD}
              style={amountStyle.CURRENCY}
              value={systemPot.currentValue}
              type={amountTypes.CONTRIBUTION}
              data-qa="dashboard-web__system-pot-value-label"
              noLineHeight
            />
          </Text>
        </Col>
      </Row>
    </Panel>
  );
};

SystemPotOverview.propTypes = {
  systemPot: object.isRequired
};

export default SystemPotOverview;
