import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@nutkit/component-text';
import Section from '@nutkit/component-section';
import { useTranslation } from '@nm-utils-lib-web/translations';

import { Sisa } from './components/SISA';
import { Lisa } from './components/LISA';
import { Pensions } from './components/Pensions';
import { Jisa } from './components/JISA';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList';

export const InvestTowardsNewGoal = props => {
  const {
    userUuid,
    isPensionEligible,
    isPensionPresent,
    eligibilityUnknown,
    eligibleSISA,
    eligibleGIA,
    isLisaPresent,
    isLisaDraftPresent,
    isLisaEligible,
    isJisaEligible,
    shouldShowLisaTransfersInTab = false
  } = props;
  const { t } = useTranslation();

  return (
    <Section>
      <Heading level="3"> {t(`${TRANSLATION_NAMESPACE}.startInvestingTowardsNewGoal.label`)}</Heading>

      {!eligibilityUnknown && (eligibleSISA || eligibleGIA) ? <Sisa /> : null}
      {!isLisaPresent && !isLisaDraftPresent && isLisaEligible ? (
        <Lisa shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab} />
      ) : null}
      {!isPensionPresent && isPensionEligible ? <Pensions /> : null}
      {isJisaEligible ? <Jisa userUuid={userUuid} onError={() => {}} /> : null}
    </Section>
  );
};

InvestTowardsNewGoal.propTypes = {
  userUuid: PropTypes.string.isRequired,
  isPensionEligible: PropTypes.bool.isRequired,
  isPensionPresent: PropTypes.bool.isRequired,
  eligibilityUnknown: PropTypes.bool.isRequired,
  eligibleSISA: PropTypes.bool.isRequired,
  eligibleGIA: PropTypes.bool.isRequired,
  isLisaPresent: PropTypes.bool.isRequired,
  isLisaDraftPresent: PropTypes.bool.isRequired,
  isLisaEligible: PropTypes.bool.isRequired,
  isJisaEligible: PropTypes.bool.isRequired,
  shouldShowLisaTransfersInTab: PropTypes.bool
};

InvestTowardsNewGoal.defaultProps = {
  shouldShowLisaTransfersInTab: false
};
