import { Portfolio, NutmegClient, Payment } from '@nm-utils-lib-web/routes';

import { JOURNEY_INTERNAL_STATES as STATES, EXTERNAL_VIEWS } from '../journey/constants';

import * as PARAM from './queryParams';
import * as VIEWS from './views';

const {
  SISA_OPEN_TRANSFER_PATH,
  SISA_OPEN_ALLOWANCE_PATH,
  SISA_DECLARATION_PATH,
  SISA_TRANSFER_TYPE_PATH,
  SISA_TRANSFER_OPEN_ISA_PATH,
  SISA_TRANSFER_FULL_PATH,
  SISA_TRANSFER_PARTIAL_PATH,
  SISA_TRANSFER_SUCCESSFUL_PATH
} = Portfolio;
const { SIGN_UP_PATH } = NutmegClient;
const ROUTES = Object.freeze({
  [VIEWS.OPEN_OR_TRANSFER]: {
    path: SISA_OPEN_TRANSFER_PATH,
    updateParam: [PARAM.INTENT],
    removeParam: [PARAM.OPEN_ISA_WRAPPER, PARAM.TRANSFER_TYPE, PARAM.ALLOWANCE, PARAM.AUTOFILL, PARAM.AUTORENEW]
  },
  [VIEWS.SET_ALLOWANCE]: {
    path: SISA_OPEN_ALLOWANCE_PATH
  },
  [VIEWS.DECLARATION]: {
    path: SISA_DECLARATION_PATH,
    removeParam: [PARAM.INTENT, PARAM.ALLOWANCE, PARAM.AUTOFILL, PARAM.AUTORENEW, PARAM.NEXT_STEP]
  },
  [VIEWS.TRANSFER_TYPE]: {
    path: SISA_TRANSFER_TYPE_PATH,
    updateParam: [PARAM.TRANSFER_TYPE],
    removeParam: [PARAM.OPEN_ISA_WRAPPER]
  },
  [VIEWS.TRANSFER_OPEN_ISA]: {
    path: SISA_TRANSFER_OPEN_ISA_PATH,
    updateParam: [PARAM.OPEN_ISA_WRAPPER]
  },
  [VIEWS.TRANSFER_FULL]: {
    path: SISA_TRANSFER_FULL_PATH,
    removeParam: [PARAM.INTENT, PARAM.OPEN_ISA_WRAPPER, PARAM.TRANSFER_TYPE]
  },
  [VIEWS.TRANSFER_PARTIAL]: {
    path: SISA_TRANSFER_PARTIAL_PATH,
    removeParam: [PARAM.INTENT, PARAM.OPEN_ISA_WRAPPER, PARAM.TRANSFER_TYPE]
  },
  [VIEWS.TRANSFER_AUTO_SUCCESS]: {
    path: SISA_TRANSFER_SUCCESSFUL_PATH
  },
  [EXTERNAL_VIEWS.PAYMENTS]: {
    base: global.NutmegConfig.DASHBOARD_HOST,
    path: ({ potUuid }) => Payment.paymentsCombinedPaymentPath({ potUuid })
  },
  [STATES.DASHBOARD]: {
    path: Portfolio.DASHBOARD_PATH
  },
  [STATES.REDIRECTING_BACKWARD]: { path: SIGN_UP_PATH }
});

export default ROUTES;
