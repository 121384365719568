import React from 'react';
import { func, string } from 'prop-types';
import { BoxWithOutline } from '@nutkit/component-box';
import { List, ListItem } from '@nutkit/component-list';
import { Text, Heading, headingLevels } from '@nutkit/component-text';
import Link from '@nutkit/component-link';
import { iconColors } from '@nutkit/component-icon';

import {
  TERMS_AND_CONDITIONS,
  ISA_TERMS_AND_CONDITIONS,
  ISA_TRANSFER_HELP_ARTICLE
} from '../../constants/URLConstants';

const TransferTermsSISA = ({ 'data-qa': dataQa, t, translationNamespace }) => {
  const TRANSLATION_NAMESPACE = `${translationNamespace}.terms`;
  const currentTaxYearInfo = t(`${TRANSLATION_NAMESPACE}.currentTaxYearInfo`);
  const ISAConditions = [
    t(`${TRANSLATION_NAMESPACE}.conditions.allowance`),
    t(`${TRANSLATION_NAMESPACE}.conditions.autorenew`),
    t(`${TRANSLATION_NAMESPACE}.conditions.autofill`)
  ];
  const Declarations = [
    t(`${TRANSLATION_NAMESPACE}.declarations.ageAndLocation`),
    t(`${TRANSLATION_NAMESPACE}.declarations.ownership`),
    t(`${TRANSLATION_NAMESPACE}.declarations.ISADeclaration`),
    t(`${TRANSLATION_NAMESPACE}.declarations.SISADeclaration`),
    t(`${TRANSLATION_NAMESPACE}.declarations.transferDeclaration`),
    <>
      {t(`${TRANSLATION_NAMESPACE}.declarations.nutmegAgreement`)}
      <Link href={TERMS_AND_CONDITIONS} isExternal>
        {t(`${TRANSLATION_NAMESPACE}.declarations.nutmegTermsLinkLabel`)}
      </Link>
      {t(`${TRANSLATION_NAMESPACE}.declarations.SISAAgreement`)}
      <Link href={ISA_TERMS_AND_CONDITIONS} isExternal>
        {t(`${TRANSLATION_NAMESPACE}.declarations.SISATermsLinkLabel`)}
      </Link>
    </>
  ];
  const Authorisations = [
    t(`${TRANSLATION_NAMESPACE}.authorisations.sellFromProvider`),
    t(`${TRANSLATION_NAMESPACE}.authorisations.holdSubscriptions`),
    t(`${TRANSLATION_NAMESPACE}.authorisations.claimTax`),
    t(`${TRANSLATION_NAMESPACE}.authorisations.contactProvider`)
  ];

  return (
    <div data-qa={`${dataQa}-terms-and-conditions`}>
      <BoxWithOutline>
        <Text>{currentTaxYearInfo}</Text>
        <List bulletColor={iconColors.SUCCESS}>
          {ISAConditions.map(condition => (
            <ListItem key={condition}>{condition}</ListItem>
          ))}
        </List>
        <Text noStack>{t(`${TRANSLATION_NAMESPACE}.conditions.disclaimer`)}</Text>
      </BoxWithOutline>
      <Text>
        {t(`${TRANSLATION_NAMESPACE}.conditions.exitFeesDisclaimer`)}
        <Link href={ISA_TRANSFER_HELP_ARTICLE} isExternal>
          {t(`${TRANSLATION_NAMESPACE}.conditions.exitFeesLearnLinkLabel`)}
        </Link>
      </Text>

      <Heading level={headingLevels.THREE}>{t(`${TRANSLATION_NAMESPACE}.declarations.heading`)}</Heading>
      <List bulletColor={iconColors.SUCCESS}>
        {Declarations.map(declaration => (
          <ListItem key={declaration}>{declaration}</ListItem>
        ))}
      </List>
      <Text>{t(`${TRANSLATION_NAMESPACE}.authorisations.heading`)}</Text>
      <List bulletColor={iconColors.SUCCESS}>
        {Authorisations.map(authorisation => (
          <ListItem key={authorisation}>{authorisation}</ListItem>
        ))}
      </List>
    </div>
  );
};

TransferTermsSISA.propTypes = {
  translationNamespace: string.isRequired,
  t: func.isRequired,
  'data-qa': string
};

TransferTermsSISA.defaultProps = {
  'data-qa': 'transfer-terms-SISA'
};

export default TransferTermsSISA;
