import { useEffect, useCallback } from 'react';

import { NAVIGATION_EVENTS } from '../constants';

function useBrowserHistoryMachine({ send, context }) {
  const sendBrowserHistoryUpdate = useCallback(() => {
    context.location = window.location;
    send(NAVIGATION_EVENTS.BROWSER_HISTORY_UPDATE, context);
  }, [context, send]);

  useEffect(() => {
    window.addEventListener('popstate', sendBrowserHistoryUpdate);

    return () => window.removeEventListener('popstate', sendBrowserHistoryUpdate);
  }, [sendBrowserHistoryUpdate]);
}

export default useBrowserHistoryMachine;
