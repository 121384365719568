import { JOURNEY_TYPES } from '@nm-pot/common/constants';
import { useGetDrafts } from '@nm-pot/common/hooks';

import { getAvailableDraftsByWrapper } from '../helpers';

export const useAvailableDrafts = ({ customerUuid, shouldMakeRequest, onError }) => {
  const { isValidating: draftsLoading, data: drafts, error: draftsError, getDrafts } = useGetDrafts({
    customerUuid,
    shouldMakeRequest,
    journeyTypes: [JOURNEY_TYPES.CREATE_POT],
    onError
  });

  return {
    error: draftsError,
    isLoading: draftsLoading,
    drafts: getAvailableDraftsByWrapper(drafts),
    getDrafts
  };
};
