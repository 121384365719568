import React from 'react';
import { func, string } from 'prop-types';
import { Route } from 'react-router-dom';
import { MetaTitle } from '@nm-utils-lib-web/meta-title';
import { useTranslation } from '@nm-utils-lib-web/translations';

export const MetaTitleRoute = ({ render, titleTranslationKey, children, ...props }) => {
  const { t } = useTranslation();
  let translationMetaTitle = t('global.common.organisation.appTitle');

  if (titleTranslationKey) {
    translationMetaTitle = `${t('dashboard.common.metaTitles.organisationName')} - ${t(titleTranslationKey)}`;
  }

  return (
    <>
      <MetaTitle title={translationMetaTitle} />
      <Route {...props} render={render}>
        {children}
      </Route>
    </>
  );
};

MetaTitleRoute.propTypes = {
  render: func,
  titleTranslationKey: string,
  children: func
};

MetaTitleRoute.defaultProps = {
  render: undefined,
  titleTranslationKey: undefined,
  children: undefined
};
