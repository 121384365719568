import { event as trackEvent } from '@nm-utils-lib-web/analytics';
import { assign } from 'xstate';

import ROUTES from '../../constants/routes';
import getViewFromRoute from '../../utils/getViewFromRoute';
import { VIEWS_TRACKING_NAVIGATION_EVENTS } from '../../constants/events';
import { getNextQueryString, appendParams } from '../helpers';

/*
 * This file is to hold all the side-effects associated with a transition ( xstate Actions )
 * in our case one generic side-effect is the changing of the route
 * This file holds generic actions, and specific available only for one transition
 *
 * Every action has 3 parameters action(event,meta,context)
 * meta can be used to introspect about the machine current state ( current view )
 * context is the machine's extended state, if we want to persist things as long as the machine lives
 *
 * And the event object holds the event type and the app context in which happened
 * event({state, history, location, match, type}) - All of these can be used in our actions
 */

// changeView() side-effect to trigger an all transitions
export const changeView = (event, meta, context, isExternal = false, customQueryString) => {
  const { baseUrl } = context;
  const { history, location } = event;
  const currentView = meta.state.value;
  const currentPage = getViewFromRoute(location.pathname);
  const nextPage = ROUTES[currentView];

  if (!nextPage) {
    console.error(`Couldn't find a route assigned for the view: ${currentView}`);

    return;
  }
  const queryString = appendParams(
    getNextQueryString(currentPage.updateParam, currentPage.removeParam, event, customQueryString)
  );
  const path = typeof nextPage.path === 'function' ? nextPage.path(context) : nextPage.path;
  const nextPath = baseUrl ? `${baseUrl}${path}` : path;

  if (isExternal) {
    if (!nextPage.base) {
      console.error(`Please define a baseUrl for the redirection of the route: ${currentView}`);

      return;
    }

    return window.location.assign(`${nextPage.base}${path}${queryString && `?${queryString}`}`);
  }

  history.push({ pathname: nextPath, search: queryString });
};

export const trackNavigationEvent = (context, event, meta) => {
  const {
    state: {
      history: { value }
    }
  } = meta;
  const currentViewTracking = VIEWS_TRACKING_NAVIGATION_EVENTS[event.type][value];
  // @NOTE: this statement should be removed once all the navigation events are added

  if (!currentViewTracking) {
    return;
  }

  const additionalProperties =
    typeof currentViewTracking.sendProperty === 'function' ? currentViewTracking.sendProperty(event) : {};

  trackEvent({
    name: currentViewTracking.name,
    properties: {
      category: currentViewTracking.category,
      ...additionalProperties
    }
  });
};

export const updateLocationContext = assign((ctx, event) => ({
  location: {
    ...ctx.location,
    ...event.location
  }
}));
