import React from 'react';
import { number, string, bool } from 'prop-types';
import { Row, Col } from '@nutkit/component-grid';
import DescriptionBlock, { DescriptionBlockWithModal } from '@nutkit/component-description-block';
import { TimeWeightedReturnContent, TotalContributionsContent } from '@nm-portfolio-lib-web/common/components';
import Amount, { amountStyle, amountTypes } from '@nutkit/component-amount';
import { Text, textStyles, textWeights, tagNames } from '@nutkit/component-text';
import { useTranslation, Trans } from '@nm-utils-lib-web/translations';
import { TooltipWithTrigger } from '@nutkit/component-tooltip';
import DateFormat from '@nutkit/component-date-format';
import { useTokens } from '@nutkit/react-utils';

import { getPerformancePrefix, toFixedPercent, displayPerformanceValue } from '../../../PortfolioDashboard/helpers';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioValue.labels';

const PortfolioPerformanceDetails = ({
  netContributions,
  returnValue,
  simpleReturnPercent,
  timeWeightedReturnPercent,
  twrrExcluded,
  lastUpdatedAt,
  twrrLastUpdatedAt
}) => {
  const { t } = useTranslation();
  const { tokens } = useTokens();
  const isTWRRUpToDate = lastUpdatedAt === twrrLastUpdatedAt;
  const isExcludedFromTWRR = timeWeightedReturnPercent === undefined || twrrExcluded;

  return (
    <Row>
      <Col xs={{ size: '6' }} md={{ size: '3' }} lg={{ size: '2' }}>
        <div aria-label={t(`${TRANSLATION_NAMESPACE}.netContributions.ariaLabel`, { netContributions })} role="term">
          <DescriptionBlockWithModal
            label={t(`${TRANSLATION_NAMESPACE}.netContributions.label`)}
            modalTriggerAriaLabel={t(`${TRANSLATION_NAMESPACE}.netContributions.modalTriggerAriaLabel`)}
            description={
              <Text textWeight={textWeights.MEDIUM} aria-hidden>
                <Amount bolderWeight style={amountStyle.CURRENCY} value={netContributions} />
              </Text>
            }
            content={<TotalContributionsContent trackingLocation="portfolio" />}
            cta={{ text: t(`${TRANSLATION_NAMESPACE}.netContributions.ctaButtonText`) }}
            aria-hidden
          />
        </div>
      </Col>
      <Col xs={{ size: '6' }} md={{ size: '3' }} lg={{ size: '2' }}>
        <div
          aria-label={t(`${TRANSLATION_NAMESPACE}.returnValue.ariaLabel`, {
            performancePrefix: getPerformancePrefix(returnValue),
            returnValue
          })}
          role="term"
        >
          <DescriptionBlock
            aria-hidden
            label={t(`${TRANSLATION_NAMESPACE}.returnValue.label`)}
            description={
              <Amount
                value={displayPerformanceValue(returnValue)}
                type={amountTypes.PERFORMANCE}
                style={amountStyle.CURRENCY}
                bolderWeight
                aria-hidden
              />
            }
          />
        </div>
      </Col>
      <Col xs={{ size: '6' }} md={{ size: '3' }} lg={{ size: '2' }}>
        <div
          aria-label={t(`${TRANSLATION_NAMESPACE}.simpleReturnPercent.ariaLabel`, {
            performancePrefix: getPerformancePrefix(simpleReturnPercent),
            simpleReturnPercent: toFixedPercent(simpleReturnPercent)
          })}
          role="term"
        >
          <DescriptionBlock
            aria-hidden
            label={t(`${TRANSLATION_NAMESPACE}.simpleReturnPercent.label`)}
            description={
              <Amount
                value={displayPerformanceValue(simpleReturnPercent)}
                type={amountTypes.PERFORMANCE}
                style={amountStyle.PERCENT}
                aria-hidden
                bolderWeight
              />
            }
          />
        </div>
      </Col>
      {!isExcludedFromTWRR && (
        <Col xs={{ size: '6' }} md={{ size: '3' }} lg={{ size: '2' }}>
          <div
            aria-label={
              isTWRRUpToDate
                ? t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.ariaLabel`, {
                    performancePrefix: getPerformancePrefix(timeWeightedReturnPercent),
                    timeWeightedReturnPercent: toFixedPercent(timeWeightedReturnPercent)
                  })
                : t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.ariaLabelWithDate`, {
                    performancePrefix: getPerformancePrefix(timeWeightedReturnPercent),
                    timeWeightedReturnPercent: toFixedPercent(timeWeightedReturnPercent),
                    twrrLastUpdatedAt: new Date(twrrLastUpdatedAt).toLocaleDateString(tokens.NK_LOCALE, {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })
                  })
            }
            role="term"
          >
            <DescriptionBlockWithModal
              label={t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.label`)}
              modalTriggerAriaLabel={t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.modalTriggerAriaLabel`)}
              description={
                <TooltipWithTrigger
                  id="time-weighted-label"
                  message={
                    isTWRRUpToDate ? (
                      t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.info`)
                    ) : (
                      <Trans
                        components={[
                          <br />,
                          <Text tagName={tagNames.SPAN} textStyle={textStyles.TEXT_2} noStack>
                            <DateFormat
                              date={twrrLastUpdatedAt}
                              options={{
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric'
                              }}
                            />
                          </Text>
                        ]}
                        defaults={`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.infoWithDate`}
                      />
                    )
                  }
                >
                  <Amount
                    value={displayPerformanceValue(timeWeightedReturnPercent)}
                    type={amountTypes.PERFORMANCE}
                    style={amountStyle.PERCENT}
                    bolderWeight
                  />
                </TooltipWithTrigger>
              }
              content={<TimeWeightedReturnContent trackingLocation="portfolio" />}
              cta={{ text: t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.ctaButtonText`) }}
              aria-hidden
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

PortfolioPerformanceDetails.defaultProps = {
  netContributions: undefined,
  returnValue: undefined,
  simpleReturnPercent: undefined,
  timeWeightedReturnPercent: undefined,
  twrrExcluded: false,
  lastUpdatedAt: undefined,
  twrrLastUpdatedAt: undefined
};

PortfolioPerformanceDetails.propTypes = {
  netContributions: number,
  returnValue: number,
  simpleReturnPercent: number,
  timeWeightedReturnPercent: number,
  twrrExcluded: bool,
  lastUpdatedAt: string,
  twrrLastUpdatedAt: string
};

export default PortfolioPerformanceDetails;
