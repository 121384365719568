import handleError from '../../errorHandler';
import basePortfolioService from '../basePortfolioService';

export const postDefineInitialSettings = async (customerId, wrapperType, { allowance, autoFill, autoRenew }) => {
  const url = `/customers/${customerId}/portfolios/wrappers/${wrapperType.toUpperCase()}/define_initial_settings`;

  try {
    const { data } = await basePortfolioService.post(url, {
      allowance,
      autoFill,
      autoRenew
    });

    return data;
  } catch (error) {
    handleError(error, 'Error defining settings');
  }
};
