/* eslint-disable max-len */
import React, { useState } from 'react';
import { func } from 'prop-types';
import { FocusableWrapper } from '@nutkit/accessibility';
import { PotsOverview } from '@nm-pot/overview';
import Align from '@nutkit/component-align';
import { Container, Row, Col } from '@nutkit/component-grid';
import Icon, { iconSizes, IconChevronLeft } from '@nutkit/component-icon';
import Link, { buttonCtas, linkSizes } from '@nutkit/component-link';
import { Heading, headingLevels } from '@nutkit/component-text';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';
import { OpenJISA } from '@nm-pot/overview/components';
import { useGetPots } from '@nm-pot/common/hooks';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import { getSWRNetworkStatus, STATES as networkStates } from '@nm-utils-lib-web/network/swr';
import Loading from '@nutkit/component-loading';
import { Portfolio } from '@nm-utils-lib-web/routes';

import ErrorPage from '../ErrorPage';
import Footer from '../Footer';
import CustomerHeader from '../Header';
import WelcomeBanner from '../WelcomeBanner';

const TRANSLATION_NAMESPACE = 'dashboard.common';

// @TODO: When dashboard is used for more than JISA the payment link needs to be modified to handle the wrapper type
const Dashboard = ({ t }) => {
  const [isError, setIsError] = useState(null);
  const { customerUuid } = useGetAuthenticationMetadata();
  const onError = error => !isError && setIsError(error);
  const { data: pots, isValidating, getPots: updatePots } = useGetPots({
    customerUuid,
    onError,
    wrapper: WRAPPER_TYPES.JISA
  });
  const networkStatus = getSWRNetworkStatus({ data: pots, isValidating });
  const isLoading = [networkStates.INITIAL, networkStates.LOADING, networkStates.REVALIDATING].includes(networkStatus);

  return (
    <>
      <CustomerHeader />
      <Section tagName={sectionTagNames.MAIN}>
        <Container>
          <Row>
            <Col lg={{ size: '8', offset: '2' }}>
              {isError ? (
                <>
                  <FocusableWrapper focus>
                    <Heading>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
                  </FocusableWrapper>
                  <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.error.title`)}</Heading>
                  <ErrorPage message={t(`${TRANSLATION_NAMESPACE}.error.defaultText`)} />
                  <Align xs={{ textAlign: 'right' }}>
                    <Link button buttonCta={buttonCtas.SECONDARY} href={Portfolio.DASHBOARD_PATH}>
                      {t(`${TRANSLATION_NAMESPACE}.link.backToPortfolio.text`)}
                    </Link>
                  </Align>
                </>
              ) : (
                <>
                  <Section tagName={sectionTagNames.DIV} stackSpacing={stackSpacing.XL}>
                    <FocusableWrapper focus>
                      <Heading noStack>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
                    </FocusableWrapper>
                    <Link data-qa="dashboard__back-to-portfolio" href={Portfolio.DASHBOARD_PATH} size={linkSizes.SM}>
                      <Icon component={IconChevronLeft} size={iconSizes.SM} />
                      {t(`${TRANSLATION_NAMESPACE}.link.backToPortfolio.text`)}
                    </Link>
                  </Section>
                  <WelcomeBanner customerUuid={customerUuid} />
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <PotsOverview customerUuid={customerUuid} pots={pots} onClosePot={updatePots} onError={onError} />
                  )}
                  <OpenJISA customerUuid={customerUuid} showLoadingSpinner={false} />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Section>
      <Footer />
    </>
  );
};

Dashboard.propTypes = {
  t: func.isRequired
};

export default Dashboard;
