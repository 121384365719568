import React from 'react';
import { node } from 'prop-types';
import Panel from '@nutkit/component-panel';
import { Heading, headingLevels, Text, textStyles } from '@nutkit/component-text';
import { IconLogoMSCI } from '@nutkit/component-icon';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Section, { stackSpacing } from '@nutkit/component-section';
import Inline, { inlineSpaces } from '@nutkit/component-inline';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioAllocation.allocationBreakdown';

const PortfolioAllocationLayout = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <Heading data-qa="portfolio-web__allocation-title" level={headingLevels.TWO}>
        {t(`${TRANSLATION_NAMESPACE}.heading`)}
      </Heading>
      <Section stackSpacing={stackSpacing.LG}>{children}</Section>
      <Section>
        <Inline space={inlineSpaces.MD}>
          <IconLogoMSCI width="80" />
          <Text noStack textStyle={textStyles.TEXT_2}>
            {t(`${TRANSLATION_NAMESPACE}.barraCopyright`, { year: new Date().getFullYear() })}
          </Text>
        </Inline>
      </Section>
    </Panel>
  );
};

PortfolioAllocationLayout.propTypes = {
  children: node.isRequired
};

export default PortfolioAllocationLayout;
