import { useCallback } from 'react';

/**
 * Middleware hook to use it in the store.
 * It should have the next interface:
 *
 * dispatch => action => ...
 *
 * @example
 * const middlewares = [
 *   dispatch => action => console.log(action)
 * ];
 *
 * // `action` is an object with `type` and `payload` fields
 * // passed into dispatch
 *
 * @param {Function} dispatch Dispatch function
 * @param {Array} middlewares Middlewares array
 */
function useMiddleware(dispatch, middlewares) {
  function callback(action) {
    dispatch(action);
    middlewares.forEach(fn => fn(dispatch)(action));
  }

  return useCallback(callback, [dispatch, middlewares]);
}

export default useMiddleware;
