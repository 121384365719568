import React from 'react';
import { useBreakpoint, breakpoints, breakpointDirections } from '@nutkit/react-utils';
import { LayoutWithSidebar } from '@nutkit/layouts';
import Section, { sectionTagNames, stackSpacing } from '@nutkit/component-section';
import ThemeProvider, { themeNames } from '@nutkit/theme';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import { getIsTransferOver40 } from '@nm-portfolio-lib-web/product-select/helpers/getIsTransferOver40';

import LayoutWithSideMenu from '../common/LayoutWithSideMenu';
import ProductList from '../ProductList';
import PortfolioValuationChart from '../PortfolioValuationChart';
import CustomerAccess from '../CustomerAccess';

import PortfolioDashboardBanner from './components/PortfolioDashboardBanner';
import PortfolioDashboardSidebar from './components/PortfolioDashboardSidebar';
import FeatureIntro from './components/FeatureIntro';
import PortfolioSummary from './components/PortfolioSummary';

const PortfolioDashboard = () => {
  const shouldShowFeatureIntro = useFlag(Flags.DASHBOARD_FEATURE_INTRO);
  const isLisaTransfersEnabled = useFlag(Flags.LISA_TRANSFERS);
  const { matchesCondition: isDesktop } = useBreakpoint(breakpoints.LG, breakpointDirections.UP);
  const { customerUuid } = useGetAuthenticationMetadata();

  return (
    <CustomerAccess customerUuid={customerUuid}>
      <LayoutWithSideMenu
        renderBanner={({ userDetails }) => <PortfolioDashboardBanner userDetails={userDetails} />}
        renderSummary={({ userDetails }) => (
          <ThemeProvider themeName={themeNames.DARK}>
            <Section tagName={sectionTagNames.DIV} stackSpacing={isDesktop ? stackSpacing.LG : undefined}>
              <PortfolioSummary customerUuid={userDetails.userUuid} customerFirstName={userDetails.firstName} />
            </Section>
          </ThemeProvider>
        )}
        data-qa="dashboard"
      >
        {({ userDetails, isSideMenuOpen }) => {
          const shouldShowLisaTransfersInTab = isLisaTransfersEnabled && getIsTransferOver40(userDetails);

          return (
            <LayoutWithSidebar
              main={
                <>
                  <PortfolioValuationChart userUuid={userDetails.userUuid} isSideMenuOpen={isSideMenuOpen} />
                  <ProductList
                    shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
                    userUuid={userDetails.userUuid}
                  />
                  {shouldShowFeatureIntro ? (
                    <FeatureIntro data-qa="feature-intro" userUuid={userDetails.userUuid} />
                  ) : null}
                </>
              }
              sidebar={<PortfolioDashboardSidebar userDetails={userDetails} />}
            />
          );
        }}
      </LayoutWithSideMenu>
    </CustomerAccess>
  );
};

export default PortfolioDashboard;
