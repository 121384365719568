import { upperCaseWrapperTypes } from '@nm-customer/common/constants';

// Thunderhead gets its wrapperTypes from Salesforce which seems to be quite
// inconsistent with its naming. This is an attempt to normalize them.

const { LISA, GIA_ISA, PENSION, JISA } = upperCaseWrapperTypes;
const THUNDERHEAD_WRAPPER_TYPES = {
  Pension: PENSION,
  LISA,
  JISA,
  'ISA/GIA': GIA_ISA,
  ISA: GIA_ISA,
  GIA: GIA_ISA,
  ISA_GIA: GIA_ISA
};

export default THUNDERHEAD_WRAPPER_TYPES;
