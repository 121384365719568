import React from 'react';
import { string, func } from 'prop-types';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import Select from '@nutkit/component-select';
import { TabGroupControlled, TabItem } from '@nutkit/component-tabs';
import Loading from '@nutkit/component-loading';
import ScrollBar from '@nutkit/component-scroll-bar';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Notification, { notificationLevels } from '@nutkit/component-notification';

import PortfolioAllocationDisplay from '../PortfolioAllocationDisplay';
import PortfolioAllocationLayout from '../PortfolioAllocationLayout';
import { getAllocationConfiguration } from '../../helpers';
import { ALLOCATION_CATEGORIES } from '../../constants';
import { PORTFOLIO_DIG_DEEPER_EVENTS } from '../../events';
import useGetPortfolioAllocationBreakdown from '../../../../hooks/useGetPortfolioAllocationBreakdown/useGetPortfolioAllocationBreakdown';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioAllocation.allocationBreakdown';

export const DETAILED_ALLOCATION_TABS = t => {
  return [
    {
      name: ALLOCATION_CATEGORIES.ASSET,
      label: t(`${TRANSLATION_NAMESPACE}.tabs.asset`),
      id: '0',
      title: t(`${TRANSLATION_NAMESPACE}.tabs.asset`)
    },
    {
      name: ALLOCATION_CATEGORIES.COUNTRY,
      label: t(`${TRANSLATION_NAMESPACE}.tabs.country`),
      id: '1',
      title: t(`${TRANSLATION_NAMESPACE}.tabs.country`)
    },
    {
      name: ALLOCATION_CATEGORIES.SECTOR,
      label: t(`${TRANSLATION_NAMESPACE}.tabs.sector`),
      id: '2',
      title: t(`${TRANSLATION_NAMESPACE}.tabs.sector`)
    },
    {
      name: ALLOCATION_CATEGORIES.CONTINENT,
      label: t(`${TRANSLATION_NAMESPACE}.tabs.continent`),
      id: '3',
      title: t(`${TRANSLATION_NAMESPACE}.tabs.continent`)
    }
  ];
};
const DetailedPortfolioAllocation = ({ customerUuid, onTabChange, onError }) => {
  const [selectedTabId, setSelectedId] = React.useState('0');
  const { matchesCondition: isLessThanMediumBreakpoint } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);
  const { data: allocations, isLoading, error } = useGetPortfolioAllocationBreakdown({
    customerUuid,
    onError
  });
  const { t } = useTranslation();

  if (isLoading) {
    return <Loading delay={0} />;
  }

  const handleTabChange = index => {
    const { name } = DETAILED_ALLOCATION_TABS(t)[index];

    setSelectedId(index);
    onTabChange && onTabChange(name);
    PORTFOLIO_DIG_DEEPER_EVENTS.Dig_Deeper_Allocation_Type({ name });
  };
  const selectedTab = DETAILED_ALLOCATION_TABS(t)[selectedTabId];

  if (isLessThanMediumBreakpoint && allocations.length > 0) {
    let content = null;
    const selectedTabItem = allocations.find(({ category }) => category === selectedTab.name);

    if (selectedTabItem) {
      content = (
        <ScrollBar>
          <PortfolioAllocationDisplay allocation={getAllocationConfiguration(selectedTab, selectedTabItem.data)} />
        </ScrollBar>
      );
    }

    return (
      <PortfolioAllocationLayout>
        <Select options={DETAILED_ALLOCATION_TABS(t)} onSelect={handleTabChange} value={selectedTabId.toString()} />
        {content}
      </PortfolioAllocationLayout>
    );
  }

  return (
    <PortfolioAllocationLayout>
      {error ? (
        <Notification dismissable={false} level={notificationLevels.ERROR}>
          {t(`${TRANSLATION_NAMESPACE}.error`)}
        </Notification>
      ) : (
        <TabGroupControlled onChange={handleTabChange} defaultTab={DETAILED_ALLOCATION_TABS(t)[selectedTabId].title}>
          {allocations
            ? DETAILED_ALLOCATION_TABS(t).map(({ name: categoryName, label: categoryLabel }) => {
                const currentAllocation = allocations.find(({ category }) => category === categoryName);

                return currentAllocation ? (
                  <TabItem key={categoryName} title={categoryLabel}>
                    <ScrollBar>
                      <PortfolioAllocationDisplay
                        allocation={getAllocationConfiguration(categoryName, currentAllocation.data)}
                      />
                    </ScrollBar>
                  </TabItem>
                ) : null;
              })
            : null}
        </TabGroupControlled>
      )}
    </PortfolioAllocationLayout>
  );
};

DetailedPortfolioAllocation.defaultProps = {
  onTabChange: null,
  onError: null
};

DetailedPortfolioAllocation.propTypes = {
  customerUuid: string.isRequired,
  onTabChange: func,
  onError: func
};

export default DetailedPortfolioAllocation;
