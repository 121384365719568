export const getTabsSortComparator = (products, drafts) => (firstTab, secondTab) => {
  const firstTabHasProducts = products[firstTab.id] && products[firstTab.id].isPresent;
  const secondTabHasProducts = products[secondTab.id] && products[secondTab.id].isPresent;
  const firstTabHasDrafts = drafts && drafts[firstTab.id] && drafts[firstTab.id].isPresent;
  const secondTabHasDrafts = drafts && drafts[secondTab.id] && drafts[secondTab.id].isPresent;

  if ((firstTabHasProducts || firstTabHasDrafts) && !(secondTabHasProducts || secondTabHasDrafts)) {
    return -1;
  }

  if (!(firstTabHasProducts || firstTabHasDrafts) && (secondTabHasProducts || secondTabHasDrafts)) {
    return 1;
  }

  return 0;
};
