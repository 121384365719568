import React from 'react';
import { string, shape } from 'prop-types';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';

import LayoutWithSideMenu from '../common/LayoutWithSideMenu';

import PortfolioDetails from './components/PortfolioDetails';

const PortfolioDetailsContainer = props => {
  const { customerUuid } = useGetAuthenticationMetadata();

  return (
    <LayoutWithSideMenu data-qa="portfolio-details-container">
      {() => <PortfolioDetails customerUuid={customerUuid} {...props} />}
    </LayoutWithSideMenu>
  );
};

PortfolioDetailsContainer.propTypes = {
  match: shape({
    path: string.isRequired,
    url: string.isRequired
  }).isRequired
};

export default PortfolioDetailsContainer;
