import useSWR from 'swr';
import { getSWRIsLoading } from '@nm-utils-lib-web/network/swr';

import { getPortfolioAllocationBreakdown } from '../../services/PotHoldingService/portfolioAllocationBreakdownService';

const useGetPortfolioAllocationBreakdown = ({ customerUuid, onError, shouldMakeRequest = true, swrOptions }) => {
  const cacheKey = `useGetPortfolioAllocationBreakdown/${customerUuid}`;
  const { data, error } = useSWR(
    shouldMakeRequest ? cacheKey : null,
    () => getPortfolioAllocationBreakdown({ customerUuid }),
    {
      revalidateOnFocus: false,
      onError: error => onError && onError(error),
      ...swrOptions
    }
  );
  const isLoading = getSWRIsLoading(data, error, shouldMakeRequest);

  return {
    data: data && data.data,
    isLoading,
    error
  };
};

export default useGetPortfolioAllocationBreakdown;
