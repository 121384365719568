import React from 'react';
import { arrayOf, string, func, object } from 'prop-types';
import { Heading } from '@nutkit/component-text';
import { PotsOverview } from '@nm-pot/overview';
import { useTranslation } from '@nm-utils-lib-web/translations';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.cashPotsSection';

const CashPotsSection = ({ pots, userUuid, onError }) => {
  const { t } = useTranslation();

  if (!pots.length) return null;

  return (
    <>
      <Heading level="3" data-qa="product-list__cash-title">
        {t(`${TRANSLATION_NAMESPACE}.heading`)}
      </Heading>
      <PotsOverview customerUuid={userUuid} pots={pots} onError={onError} />
    </>
  );
};

CashPotsSection.propTypes = {
  pots: arrayOf(object).isRequired,
  userUuid: string.isRequired,
  onError: func
};

CashPotsSection.defaultProps = {
  onError: () => {}
};

export default CashPotsSection;
