import useSWR from 'swr';
import { getSWRNetworkStatus, STATES as networkStates } from '@nm-utils-lib-web/network/swr';

import { getCustomerPerformanceSummary } from '../../services/CustomerPerformanceService/customerPerformanceSummaryService';

function useGetCustomerPerformanceSummary({
  customerUuid,
  onError,
  timeframe,
  fromDate,
  toDate,
  indicatorType,
  shouldMakeRequest = true,
  context,
  swrOptions = {}
}) {
  const cacheKey = `getCustomerPerformanceSummary/${customerUuid}/${timeframe &&
    timeframe.join(',')}/${fromDate}/${toDate}/${indicatorType && indicatorType.join(',')}`;
  const { data, isValidating, error } = useSWR(
    shouldMakeRequest ? cacheKey : null,
    () =>
      getCustomerPerformanceSummary({
        customerUuid,
        timeframe,
        fromDate,
        toDate,
        indicatorType
      }),
    {
      revalidateOnFocus: false,
      onError: error => onError && onError({ context, error }),
      ...swrOptions
    }
  );
  const isLoading =
    shouldMakeRequest &&
    [networkStates.INITIAL, networkStates.LOADING, networkStates.REVALIDATING].includes(
      getSWRNetworkStatus({ data, isValidating })
    );

  return { data, isLoading, error };
}

export default useGetCustomerPerformanceSummary;
