import React, { useState } from 'react';
import { bool, string, oneOf } from 'prop-types';
import { Row, Col } from '@nutkit/component-grid';
import Panel, { PanelWithCollapse } from '@nutkit/component-panel';
import { Heading, Text, textStyles, textWeights, headingLevels, textAligns } from '@nutkit/component-text';
import Button, { buttonSizes, buttonCtaTypes } from '@nutkit/component-button';
import Loading from '@nutkit/component-loading';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import ProgressBar from '@nutkit/component-progress-bar';
import Amount, { amountStyle, amountSizes } from '@nutkit/component-amount';
import ButtonGroup from '@nutkit/component-button-group';
import { useTokens } from '@nutkit/react-utils';
import Icon, { IconBarStriped } from '@nutkit/component-icon';
import { CurrencyInput } from '@nutkit/component-input';
import { formatPeriodDate } from '@nm-portfolio-lib-web/common/helpers';
import { useTranslation, Trans } from '@nm-utils-lib-web/translations';
import useGetHeadroom from '@nm-portfolio-lib-web/common/hooks/useGetHeadroom';

import usePostIsaAllowance from '../../../../hooks/usePostIsaAllowance';
import { periodTypes } from '../../constants/periodTypes';
import calculateHeadroomPercentage from '../../utils/calculateHeadroomPercentage';
import stripDecimals from '../../utils/stripDecimals';
import {
  trackCancelUpdateCurrentIsaAllowance,
  trackCancelUpdateNextIsaAllowance,
  trackUpdateCurrentIsaAllowance,
  trackUpdateNextIsaAllowance,
  ISA_ALLOWANCE_TRACKING_CATEGORY
} from '../../../../tracking/events/isaAllowance';

import ValidateAllowance from './ValidateAllowance';

const TRANSLATION_NAMESPACE = 'dashboard.isaAllowance.edit';

const ISAEditAllowance = props => {
  const { period, periodType, holds, 'data-qa': dataQa, customerId } = props;
  const { t } = useTranslation();
  const { tokens } = useTokens();
  const { data: headroomData, isLoading: isHeadroomLoading, error: headroomError } = useGetHeadroom({
    customerUuid: customerId,
    period
  });
  const { send: setIsaAllowance, isLoading: isSaving, error: hasUpdatedAllowanceFailed } = usePostIsaAllowance({
    onSuccess: () => setHasUpdatedAllowance(true)
  });
  const [hasUpdatedAllowance, setHasUpdatedAllowance] = useState(false);
  const holdsSisa = !!headroomData?.byWrapper?.sisa;
  const holdsLisa = !!headroomData?.byWrapper?.lisa;
  const sisaContributions = headroomData?.byWrapper?.sisa?.contributions;
  const sisaContributionsPercentage = headroomData?.byWrapper?.sisa?.contributionsPercentage?.ofAllowance;
  const lisaContributions = headroomData?.byWrapper?.lisa?.contributions;
  const lisaContributionsPercentage = headroomData?.byWrapper?.lisa?.contributionsPercentage?.ofAllowance;
  const maxLisaAllowance = headroomData?.currentDefaultAllowances?.lisa;
  const maxIsaAllowance = headroomData?.currentDefaultAllowances?.isa;
  const sisaAllowanceProp = headroomData?.byWrapper?.sisa?.allowance;
  const [sisaAllowance, setSisaAllowance] = useState(() => {
    if (isHeadroomLoading && !sisaAllowanceProp) return 0;

    return sisaAllowanceProp;
  });
  const lisaAllowanceProp = headroomData?.byWrapper?.lisa?.allowance;
  const [lisaAllowance, setLisaAllowance] = useState(() => {
    if (isHeadroomLoading && !lisaAllowanceProp) return 0;

    return lisaAllowanceProp;
  });
  const noDecimals = sisaAllowance - stripDecimals(sisaAllowance) === 0;
  const [lisaHeadroomPercentage, setLisaHeadroomPercentage] = useState();
  const [sisaHeadroomPercentage, setSisaHeadroomPercentage] = useState();

  React.useEffect(() => {
    if (!isHeadroomLoading && headroomData?.byWrapper?.sisa?.allowance) {
      setSisaAllowance(headroomData.byWrapper.sisa.allowance);
    } else {
      setSisaAllowance(0);
    }
  }, [isHeadroomLoading, headroomData]);

  React.useEffect(() => {
    if (!isHeadroomLoading && headroomData?.byWrapper?.lisa?.allowance) {
      setLisaAllowance(headroomData.byWrapper.lisa.allowance);
    } else {
      setLisaAllowance(0);
    }
  }, [isHeadroomLoading, headroomData]);

  React.useEffect(() => {
    if (!isHeadroomLoading && typeof sisaContributions !== 'undefined' && sisaAllowance && !sisaHeadroomPercentage) {
      setSisaHeadroomPercentage(calculateHeadroomPercentage(sisaContributions, sisaAllowance, maxIsaAllowance));
    }
  }, [isHeadroomLoading, maxIsaAllowance, sisaAllowance, sisaContributions, sisaHeadroomPercentage]);

  React.useEffect(() => {
    if (!isHeadroomLoading && typeof lisaContributions !== 'undefined' && lisaAllowance && !lisaHeadroomPercentage) {
      setLisaHeadroomPercentage(calculateHeadroomPercentage(lisaContributions, lisaAllowance, maxIsaAllowance));
    }
  }, [isHeadroomLoading, maxIsaAllowance, lisaAllowance, lisaContributions, lisaHeadroomPercentage]);

  const onIsaFocus = () => {
    changeSisaAllowance(sisaAllowance);
    changeLisaAllowance(lisaAllowance);
  };

  const submitAllowances = async () => {
    if (periodType === periodTypes.CURRENT) {
      if (holdsSisa && !holdsLisa) {
        await setIsaAllowance({ customerUuid: customerId, sisaAllowance, period });
        trackUpdateCurrentIsaAllowance({ category: ISA_ALLOWANCE_TRACKING_CATEGORY });
      } else if (holdsLisa && !holdsSisa) {
        await setIsaAllowance({ customerUuid: customerId, lisaAllowance, period });
        trackUpdateCurrentIsaAllowance({ category: ISA_ALLOWANCE_TRACKING_CATEGORY });
      } else {
        await setIsaAllowance({ customerUuid: customerId, sisaAllowance, lisaAllowance, period });
        trackUpdateCurrentIsaAllowance({ category: ISA_ALLOWANCE_TRACKING_CATEGORY });
      }
    }

    if (periodType === periodTypes.NEXT) {
      if (holdsSisa && !holdsLisa) {
        await setIsaAllowance({ customerUuid: customerId, sisaAllowance, period });
        trackUpdateNextIsaAllowance({ category: ISA_ALLOWANCE_TRACKING_CATEGORY });
      } else if (holdsLisa && !holdsSisa) {
        await setIsaAllowance({ customerUuid: customerId, lisaAllowance, period });
        trackUpdateNextIsaAllowance({ category: ISA_ALLOWANCE_TRACKING_CATEGORY });
      } else {
        await setIsaAllowance({ customerUuid: customerId, sisaAllowance, lisaAllowance, period });
        trackUpdateNextIsaAllowance({ category: ISA_ALLOWANCE_TRACKING_CATEGORY });
      }
    }
  };

  function changeSisaAllowance(allowance) {
    const sisaAllowance = stripDecimals(allowance);

    setSisaAllowance(sisaAllowance);
    setSisaHeadroomPercentage(calculateHeadroomPercentage(sisaContributions, sisaAllowance, maxIsaAllowance));
  }

  function changeLisaAllowance(allowance) {
    const lisaAllowance = stripDecimals(allowance);

    setLisaAllowance(lisaAllowance);
    setLisaHeadroomPercentage(calculateHeadroomPercentage(lisaContributions, lisaAllowance, maxIsaAllowance));
  }

  function cancelChanges(closePanel) {
    setSisaAllowance(headroomData?.byWrapper?.sisa?.allowance);
    setLisaAllowance(headroomData?.byWrapper?.lisa?.allowance);
    setSisaHeadroomPercentage(calculateHeadroomPercentage(sisaContributions, sisaAllowance, maxIsaAllowance));
    setLisaHeadroomPercentage(calculateHeadroomPercentage(lisaContributions, lisaAllowance, maxIsaAllowance));

    if (periodType === periodTypes.CURRENT) {
      trackCancelUpdateCurrentIsaAllowance({ category: ISA_ALLOWANCE_TRACKING_CATEGORY });
    } else {
      trackCancelUpdateNextIsaAllowance({ category: ISA_ALLOWANCE_TRACKING_CATEGORY });
    }

    closePanel();
  }

  function validate() {
    return ValidateAllowance({
      holdsSisa,
      holdsLisa,
      sisaAllowance,
      lisaAllowance,
      sisaContributions,
      lisaContributions,
      maxIsaAllowance,
      maxLisaAllowance,
      t
    });
  }

  if (headroomError) {
    return (
      <Notification data-qa={`${dataQa}__error`} level={notificationLevels.ERROR} dismissable={false}>
        {t('dashboard.isaAllowance.loadingError')}
      </Notification>
    );
  }

  if (holds === false) {
    return null;
  }

  if (isHeadroomLoading) {
    return (
      <Panel>
        <Loading />
      </Panel>
    );
  }

  const progressBars = [];
  let overallAllowance = 0;
  let overallRemainingAllowance = maxIsaAllowance;

  overallAllowance += holdsSisa && sisaAllowance;
  overallAllowance += holdsLisa && lisaAllowanceProp;

  // Overall allowance shouldn't be more than max value
  overallAllowance = Math.min(maxIsaAllowance, overallAllowance);
  overallRemainingAllowance -= sisaAllowance + lisaAllowanceProp;

  const isCurrentPeriod = periodType === periodTypes.CURRENT;
  const hasOverallRemainingAllowance = overallRemainingAllowance > 0;
  const unSavedChanges = lisaAllowanceProp !== lisaAllowance || sisaAllowanceProp !== sisaAllowance;
  const validationErrors = validate();
  const hasValidationErrors = validationErrors.length > 0;
  const periodLabel = formatPeriodDate(period);
  const isaLimitsLabel = t(`${TRANSLATION_NAMESPACE}.isaLimitsMessage`);
  const maxLabel = (
    <Text textStyle={textStyles.TEXT_2} noStack>
      <Trans
        components={[
          <Amount
            data-qa="max-amount-to-contribute"
            value={maxIsaAllowance}
            style={amountStyle.CURRENCY}
            size={amountSizes.XS}
            minDecimals={0}
          />
        ]}
        i18nKey={`${TRANSLATION_NAMESPACE}.maxLabel`}
      />
    </Text>
  );
  const isaLimitsHeader = t(`${TRANSLATION_NAMESPACE}.isaLimitsHeader`, {
    periodLabel,
    interpolation: { escapeValue: false }
  });
  const sisaInputLabel = t(`${TRANSLATION_NAMESPACE}.sisa.inputLabel`);
  const lisaInputLabel = t(`${TRANSLATION_NAMESPACE}.lisa.inputLabel`);
  const overallAllowanceLabel = t(`${TRANSLATION_NAMESPACE}.overallAllowanceLabel`, {
    periodLabel,
    interpolation: { escapeValue: false }
  });
  const cancelLabel = t(`${TRANSLATION_NAMESPACE}.buttons.cancelLabel`);
  const saveLabel = t(`${TRANSLATION_NAMESPACE}.buttons.saveLabel`);
  const updatedAllowancesMessage = t(`${TRANSLATION_NAMESPACE}.messages.updatedAllowances`);
  const failedAllowancesUpdateMessage = t(`${TRANSLATION_NAMESPACE}.messages.failedAllowancesUpdate`);

  holdsSisa &&
    sisaContributionsPercentage > 0 &&
    progressBars.push(
      <ProgressBar key="sisaContribution" color={tokens.NK_WRAPPER_SISA_COLOR} value={sisaContributionsPercentage} />
    );

  holdsSisa &&
    progressBars.push(
      <ProgressBar key="sisaHeadroom" striped color={tokens.NK_WRAPPER_SISA_COLOR} value={sisaHeadroomPercentage} />
    );

  holdsLisa &&
    lisaContributionsPercentage > 0 &&
    progressBars.push(
      <ProgressBar key="lisaContribution" color={tokens.NK_WRAPPER_LISA_COLOR} value={lisaContributionsPercentage} />
    );

  holdsLisa &&
    progressBars.push(
      <ProgressBar key="lisaHeadroom" striped color={tokens.NK_WRAPPER_LISA_COLOR} value={lisaHeadroomPercentage} />
    );

  return (
    <PanelWithCollapse summary={isaLimitsHeader} data-qa={dataQa}>
      {({ toggleOpen }) => (
        <>
          {isCurrentPeriod && (
            <Row>
              <Col>
                <Text data-qa="set-isa-limits-copy" textStyle={textStyles.TEXT_2}>
                  {isaLimitsLabel}
                </Text>
              </Col>
            </Row>
          )}
          <Row>
            {holdsSisa && (
              <Col md="6">
                <Icon component={IconBarStriped} color={tokens.NK_WRAPPER_SISA_COLOR} />
                <CurrencyInput
                  data-qa="sisa-allowance-input"
                  label={sisaInputLabel}
                  onFocus={onIsaFocus}
                  onChange={value => changeSisaAllowance(value)}
                  value={sisaAllowance}
                  max={maxIsaAllowance}
                  helpContent={
                    <>
                      <Trans
                        components={[
                          <Amount
                            value={maxIsaAllowance}
                            style={amountStyle.CURRENCY}
                            size={amountSizes.XS}
                            minDecimals={0}
                          />
                        ]}
                        i18nKey={`${TRANSLATION_NAMESPACE}.sisa.maxLabel`}
                      />
                      {periodType === periodTypes.CURRENT && (
                        <>
                          {' '}
                          <Trans
                            components={[
                              <Amount
                                value={sisaContributions}
                                style={amountStyle.CURRENCY}
                                size={amountSizes.XS}
                                minDecimals={0}
                              />
                            ]}
                            i18nKey={`${TRANSLATION_NAMESPACE}.sisa.contributedLabel`}
                          />
                        </>
                      )}
                    </>
                  }
                  noDecimals={noDecimals}
                />
              </Col>
            )}
            {holdsLisa && (
              <Col md="6">
                <Icon component={IconBarStriped} color={tokens.NK_WRAPPER_LISA_COLOR} />
                <CurrencyInput
                  data-qa="lisa-allowance-input"
                  label={lisaInputLabel}
                  onFocus={onIsaFocus}
                  onChange={value => changeLisaAllowance(value)}
                  value={lisaAllowance}
                  max={maxLisaAllowance}
                  helpContent={
                    <>
                      <Trans
                        components={[
                          <Amount
                            value={maxLisaAllowance}
                            style={amountStyle.CURRENCY}
                            size={amountSizes.XS}
                            minDecimals={0}
                          />
                        ]}
                        i18nKey={`${TRANSLATION_NAMESPACE}.lisa.maxLabel`}
                      />
                      {periodType === periodTypes.CURRENT && (
                        <>
                          {' '}
                          <Trans
                            components={[
                              <Amount
                                value={lisaContributions}
                                style={amountStyle.CURRENCY}
                                size={amountSizes.XS}
                                minDecimals={0}
                              />
                            ]}
                            i18nKey={`${TRANSLATION_NAMESPACE}.lisa.contributedLabel`}
                          />
                        </>
                      )}
                    </>
                  }
                  noDecimals={noDecimals}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <ProgressBar type="multi" labelRight={maxLabel}>
                {progressBars}
              </ProgressBar>
            </Col>
          </Row>
          <Row>
            <Col sm="8">
              <Heading level={headingLevels.THREE} data-qa="overall-nutmeg-isa-header">
                {overallAllowanceLabel}
              </Heading>
            </Col>
            <Col sm="4">
              <Text textAlign={textAligns.RIGHT} textWeight={textWeights.BOLD}>
                <Amount
                  data-qa="overall-amount"
                  value={overallAllowance}
                  style={amountStyle.CURRENCY}
                  bolderWeight
                  minDecimals={0}
                />
              </Text>
            </Col>
          </Row>
          <Text data-qa="compliance-overall-allowance-txt" textStyle={textStyles.TEXT_2}>
            <Trans
              components={[
                <Amount value={maxIsaAllowance} style={amountStyle.CURRENCY} size={amountSizes.XS} minDecimals={0} />
              ]}
              i18nKey={`${TRANSLATION_NAMESPACE}.complianceOverallAllowanceLabel`}
            />
          </Text>
          {hasOverallRemainingAllowance && !hasValidationErrors && (
            <Text data-qa="compliance-amount-left-txt" textStyle={textStyles.TEXT_2} textWeight={textWeights.BOLD}>
              <Trans
                components={[
                  <Amount
                    value={overallRemainingAllowance}
                    style={amountStyle.CURRENCY}
                    size={amountSizes.XS}
                    bolderWeight
                    minDecimals={0}
                  />
                ]}
                i18nKey={`${TRANSLATION_NAMESPACE}.complianceAmountLeftLabel`}
              />
            </Text>
          )}
          {hasValidationErrors &&
            validationErrors.map(error => (
              <Notification dismissable={false} key={error} level={notificationLevels.ERROR}>
                {error}
              </Notification>
            ))}
          {hasUpdatedAllowance && (
            <Notification level={notificationLevels.SUCCESS}>{updatedAllowancesMessage}</Notification>
          )}
          {hasUpdatedAllowanceFailed && (
            <Notification level={notificationLevels.ERROR}>{failedAllowancesUpdateMessage}</Notification>
          )}
          <ButtonGroup stackUp>
            <Button
              data-qa="cancel-edit-allowance"
              size={buttonSizes.SM}
              cta={buttonCtaTypes.SECONDARY}
              onClick={() => cancelChanges(toggleOpen)}
              disabled={!unSavedChanges}
            >
              {cancelLabel}
            </Button>
            <Button
              data-qa="save-edit-allowance"
              size={buttonSizes.SM}
              onClick={submitAllowances}
              isLoading={isSaving}
              disabled={!unSavedChanges || hasValidationErrors}
            >
              {saveLabel}
            </Button>
          </ButtonGroup>
        </>
      )}
    </PanelWithCollapse>
  );
};

ISAEditAllowance.propTypes = {
  customerId: string.isRequired,
  holds: bool.isRequired,
  period: string,
  periodType: oneOf(Object.values(periodTypes)).isRequired,
  'data-qa': string
};

ISAEditAllowance.defaultProps = {
  period: null,
  'data-qa': 'edit-allowance'
};

export default ISAEditAllowance;
