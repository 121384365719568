import { WRAPPER_TYPES as wrapperTypes, POT_STATUSES, INVESTING_FOR_INCOME } from '@nm-pot/common/constants';

import { isEligible } from './productEligibility';

export const isSISAAvailable = (eligibleProducts, products, status) =>
  isEligible(eligibleProducts, wrapperTypes.SISA) ||
  isEligible(eligibleProducts, wrapperTypes.GIA) ||
  (products[wrapperTypes.SISA] && products[wrapperTypes.SISA].isPresent) ||
  status[wrapperTypes.SISA].active;

export const isLISAAvailable = (eligibleProducts, products, status) => {
  // returns true if user has LISA pots
  if (products[wrapperTypes.LISA] && products[wrapperTypes.LISA].isPresent) {
    return true;
  }

  const eligible = isEligible(eligibleProducts, wrapperTypes.LISA);

  // returns false if user does not have LISA pots AND is not eligible to open LISA
  if (!eligible) {
    return false;
  }

  const lisaStatus = status[wrapperTypes.LISA];

  // returns true if user is eligible to open LISA
  // AND there is no info about LISA status (e.g. ISA status endpoint returns 404)
  if (!lisaStatus) {
    return true;
  }

  // returns true if user is eligible to open LISA AND does not hold OR held LISA
  return !lisaStatus.holds && !lisaStatus.held;
};

export const isJISAAvailable = (eligibleProducts, products) =>
  isEligible(eligibleProducts, wrapperTypes.JISA) ||
  (products[wrapperTypes.JISA] &&
    products[wrapperTypes.JISA].data.some(({ status }) => status === POT_STATUSES.ACTIVE));

export const isPensionAvailable = (eligibleProducts, products) =>
  isEligible(eligibleProducts, wrapperTypes.PENSION) ||
  (products[wrapperTypes.PENSION] && products[wrapperTypes.PENSION].isPresent);

export const isIncomeAvailable = (eligibleProducts, products) => {
  // Income pots are SISA/GIA pots so we check if user has SISA/GIA eligibility
  const hasEligibility =
    isEligible(eligibleProducts, wrapperTypes.SISA) || isEligible(eligibleProducts, wrapperTypes.GIA);
  const hasActiveProduct = products.income.data.some(
    ({ investmentStrategy }) => investmentStrategy === INVESTING_FOR_INCOME
  );

  return hasEligibility && hasActiveProduct;
};

export const availabilityConditions = {
  [wrapperTypes.SISA]: isSISAAvailable,
  [wrapperTypes.LISA]: isLISAAvailable,
  [wrapperTypes.JISA]: isJISAAvailable,
  [wrapperTypes.PENSION]: isPensionAvailable,
  income: isIncomeAvailable
};

export const isTabAvailable = (wrapperType, eligibleProducts, products, statuses) => {
  if (eligibleProducts === null) {
    return products[wrapperType] && products[wrapperType].isPresent;
  }

  return availabilityConditions[wrapperType](eligibleProducts, products, statuses);
};
