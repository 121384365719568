import React, { useMemo } from 'react';
import { arrayOf, func, object, bool, string, shape } from 'prop-types';
import { PotsOverview } from '@nm-pot/overview';
import useGetUnallocatedCash from '@nm-payments/common/hooks/useGetUnallocatedCash';
import Panel from '@nutkit/component-panel';
import { Heading, headingLevels } from '@nutkit/component-text';
import { linkSizes, buttonCtas } from '@nutkit/component-link';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Portfolio } from '@nm-utils-lib-web/routes';
import { FIND_OUT_MORE_ISA } from '@nm-utils-lib-web/routes/supportArticles';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';

import { getSISAEmptyStateHeadingKey } from '../../helpers';
import UnallocatedCash from '../UnallocatedCash';
import PendingWithdrawal from '../PendingWithdrawal';
import PendingTransfers from '../PendingTransfers';
import { productListEmptyProductClickedPayload } from '../../../../tracking/events/productList';
import { OUTGOING_GIA_WITHDRAWAL, OUTGOING_ISA_WITHDRAWAL } from '../../constants/WrapperTransactions';

import EmptyStateDescription from './EmptyStateDescription';

const { DASHBOARD_HOST } = global.NutmegConfig;
const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.sisaTab';
const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const SISATab = ({
  t,
  pots,
  drafts,
  updatePots,
  updateDrafts,
  onError,
  isPresent,
  isDraftPresent,
  userUuid,
  eligibleGIA,
  eligibleSISA,
  isaStatus,
  withHeading,
  withGroupHeading,
  tabLabelKey
}) => {
  const { hasUnallocatedCash, data } = useGetUnallocatedCash({ userUuid });
  const pendingWithdrawalPots = pots.filter(({ pending: { withdrawals } }) => withdrawals > 0);
  const totalPendingWithdrawals = pendingWithdrawalPots
    .map(pot => pot.pending.withdrawals)
    .reduce((prev, next) => prev + next, 0);
  const hasPendingWrapperWithdrawals = useMemo(() => {
    return pots.some(pot => {
      const wrapperTransactions = pot && pot.pending && pot.pending.wrapperTransactions;

      return (
        wrapperTransactions &&
        (wrapperTransactions.includes(OUTGOING_ISA_WITHDRAWAL) || wrapperTransactions.includes(OUTGOING_GIA_WITHDRAWAL))
      );
    });
  }, [pots]);
  const hasPendingWithdrawals = pendingWithdrawalPots.length > 0 || hasPendingWrapperWithdrawals;

  if (!isPresent && !isaStatus.active) {
    return (
      <>
        {withHeading && (
          <Heading level={headingLevels.THREE} data-qa="product-list__sisa-title">
            {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.${tabLabelKey}.label`)}
          </Heading>
        )}
        {withGroupHeading && (
          <Heading level={headingLevels.THREE} data-qa="product-list__growth-title">
            {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.growth.label`)}
          </Heading>
        )}
        {hasUnallocatedCash && <UnallocatedCash amount={data.currentValue} potUuid={data.uuid} />}
        {isDraftPresent && (
          <PotsOverview customerUuid={userUuid} drafts={drafts} updateDrafts={updateDrafts} onError={onError} />
        )}
        <Panel data-qa="product-list__empty-state__panel">
          <Heading level={headingLevels.TWO}>
            {t(getSISAEmptyStateHeadingKey(TRANSLATION_NAMESPACE, eligibleGIA, eligibleSISA))}
          </Heading>
          <EmptyStateDescription
            eligibleGIA={eligibleGIA}
            eligibleSISA={eligibleSISA}
            userUuid={userUuid}
            onError={onError}
            learMore={
              <LinkWithTracking
                href={FIND_OUT_MORE_ISA}
                eventPayload={productListEmptyProductClickedPayload({ wrapperType: WRAPPER_TYPES.SISA })}
                aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.ariaLabel`)}
                size={linkSizes.MD}
                data-qa="product-list__sisa-tab__create-new"
                isExternal
              >
                {t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.label`)}
              </LinkWithTracking>
            }
          />
          <ButtonGroup align={buttonGroupAlignments.RIGHT} stackOnMobile>
            {eligibleGIA && (
              <LinkWithTracking
                href={`${DASHBOARD_HOST}${Portfolio.PRODUCT_GIA_INFORMATION_PATH}`}
                button
                buttonCta={buttonCtas.PRIMARY}
                aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.openGIALink.ariaLabel`)}
                size={linkSizes.SM}
                data-qa="product-list__sisa-empty-state__open-gia"
                eventPayload={productListEmptyProductClickedPayload({
                  option: 'open_gia',
                  wrapperType: WRAPPER_TYPES.SISA
                })}
              >
                {t(`${TRANSLATION_NAMESPACE}.emptyState.openGIALink.label`)}
              </LinkWithTracking>
            )}
            {eligibleSISA && (
              <LinkWithTracking
                href={Portfolio.PRODUCT_SISA_INFORMATION_PATH}
                button
                buttonCta={buttonCtas.PRIMARY}
                aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.openISALink.ariaLabel`)}
                size={linkSizes.SM}
                data-qa="product-list__sisa-empty-state__open-isa"
                eventPayload={productListEmptyProductClickedPayload({
                  option: 'open_or_transfer',
                  wrapperType: WRAPPER_TYPES.SISA
                })}
              >
                {t(`${TRANSLATION_NAMESPACE}.emptyState.openISALink.label`)}
              </LinkWithTracking>
            )}
          </ButtonGroup>
        </Panel>
      </>
    );
  }

  return (
    <div data-qa="product-list__sisa_content">
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__sisa-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.${tabLabelKey}.label`)}
        </Heading>
      )}
      {withGroupHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__growth-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.growth.label`)}
        </Heading>
      )}
      {hasUnallocatedCash && <UnallocatedCash amount={data.currentValue} potUuid={data.uuid} />}
      {hasPendingWithdrawals && (
        <PendingWithdrawal
          pendingWithdrawalPots={pendingWithdrawalPots}
          totalPendingWithdrawals={totalPendingWithdrawals}
          updatePots={updatePots}
          userUuid={userUuid}
          hasPendingWrapperWithdrawals={hasPendingWrapperWithdrawals}
          data-qa="product-list__sisa-tab__pending-withdrawal"
        />
      )}
      <PendingTransfers userUuid={userUuid} onCancel={updatePots} pots={pots} />
      {(isPresent || isDraftPresent) && (
        <PotsOverview
          customerUuid={userUuid}
          pots={pots}
          drafts={drafts}
          onClosePot={updatePots}
          updateDrafts={updateDrafts}
          onError={onError}
        />
      )}
    </div>
  );
};

SISATab.propTypes = {
  t: func.isRequired,
  isPresent: bool.isRequired,
  pots: arrayOf(object).isRequired,
  updatePots: func.isRequired,
  updateDrafts: func.isRequired,
  onError: func,
  userUuid: string.isRequired,
  eligibleGIA: bool.isRequired,
  eligibleSISA: bool.isRequired,
  isaStatus: shape({
    active: bool.isRequired
  }).isRequired,
  drafts: arrayOf(object),
  isDraftPresent: bool,
  withHeading: bool,
  withGroupHeading: bool,
  tabLabelKey: string.isRequired
};

SISATab.defaultProps = {
  drafts: [],
  isDraftPresent: false,
  withHeading: false,
  withGroupHeading: false,
  onError: undefined
};

export default SISATab;
