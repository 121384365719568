import { Pot } from '@nm-utils-lib-web/routes';
import { JOURNEY_TYPES, WRAPPER_TYPES } from '@nm-pot/common/constants';

const getEditRiskPath = (uuid, type) => {
  if (!type) {
    return null;
  }

  const wrapperTypeMap = {
    [WRAPPER_TYPES.GIA_ISA]: WRAPPER_TYPES.SISA, // `gia/isa` is not a handled wrapper, use `sisa`
    default: type
  };
  const wrapperType = wrapperTypeMap[type] || wrapperTypeMap.default;

  return Pot.getPotRiskLevelPath({
    journeyType: JOURNEY_TYPES.EDIT_POT,
    potUuid: uuid,
    wrapperType
  });
};
const getSendNewNutmailPath = () => `${global.NutmegConfig.RAILS_APP_URL}/client/nutmail/conversation/new`;

const getLegacyWithdrawPath = (uuid = null) => {
  let withdrawPath = `${global.NutmegConfig.RAILS_APP_URL}/client/withdraw`;

  if (uuid !== null) {
    withdrawPath = `${withdrawPath}/${uuid}`;
  }

  return withdrawPath;
};
const getNonInvestorDashboardPath = () => global.NutmegConfig.SIGNUP_HOST;

export { getEditRiskPath, getSendNewNutmailPath, getLegacyWithdrawPath, getNonInvestorDashboardPath };
