import * as PARAM_NAME from '../../constants/queryParams';

import types from './convertTypes';
import actions from './actions';

const storeSchema = {
  nextStep: {
    publicName: PARAM_NAME.NEXT_STEP,
    method: actions.setNextStep,
    convert: types.string,
    initialValue: null
  },
  intent: {
    publicName: PARAM_NAME.INTENT,
    method: actions.setIntent,
    convert: types.string,
    initialValue: null
  },
  allowance: {
    publicName: PARAM_NAME.ALLOWANCE,
    method: actions.setAllowance,
    convert: types.number,
    initialValue: 20000
  },
  settings: {
    autoFill: {
      publicName: PARAM_NAME.AUTOFILL,
      method: actions.setAutoFill,
      convert: types.boolean,
      initialValue: false
    },
    autoRenew: {
      publicName: PARAM_NAME.AUTORENEW,
      method: actions.setAutoRenew,
      convert: types.boolean,
      initialValue: true
    }
  },
  transferType: {
    publicName: PARAM_NAME.TRANSFER_TYPE,
    method: actions.setTransfer,
    convert: types.string,
    initialValue: null
  },
  openISA: {
    publicName: PARAM_NAME.OPEN_ISA_WRAPPER,
    method: actions.setOpenISA,
    convert: types.boolean,
    initialValue: null
  },
  transferData: {
    publicName: PARAM_NAME.TRANSFER_DATA,
    method: actions.setTransferData,
    convert: types.object,
    initialValue: {}
  }
};

/**
 * @desc Extract the initialValues to be used in store when there is no query param
 * @return {object} Nested object containing the initial values
 */
const getInitialValues = (publicValues = {}) => {
  function _getInitialValues(obj) {
    return Object.entries(obj).reduce((result, [property, value]) => {
      const { initialValue, publicName, convert } = value;
      const publicValue = publicValues[publicName];
      const resultValue = publicValue ? convert(publicValue) : initialValue;

      result[property] = value.hasOwnProperty('initialValue') ? resultValue : _getInitialValues(value);

      return result;
    }, {});
  }

  return _getInitialValues(storeSchema);
};

/**
 * @desc getStateMetaObject Provides information for saving and initialisg for a given query param
 * @param {string} publicName - Targeted publicName for which to return the meta information
 * @return {metaObject} Object containing the extra information about the query param and state field
 * @typedef {object} metaObject
 * @property {function} method - The correspondend method to update the state
 * @property {(number|boolean|string)} initialValue - The correspondent initial valuee
 */
const getStateMetaObject = publicName => {
  if (!publicName) throw 'no query parameter was provided';

  function _getStateMetaObject(obj, publicName, prevResult = false) {
    const filtered = Object.values(obj).reduce((result, current) => {
      if (!current.hasOwnProperty('publicName')) {
        result = _getStateMetaObject(current, publicName, result);
      } else if (current.publicName === publicName) {
        result = { ...current };
      }

      return result;
    }, prevResult);

    if (!filtered && obj === storeSchema) throw 'searched parameter is not defined in schema';

    return filtered;
  }

  return _getStateMetaObject(storeSchema, publicName);
};

/**
 * @desc getStateProperty  Provides the path for accesing the state value for a given public query parameter
 * or provides the value directly for a given query parameter and a state object
 * @param {string} publicName - Targeted publicName for which to return the current state value
 * @param {object} [state=false] - Optional state object from which to extract the current value of provided query param
 * @return {(string|number|boolean)} Returns Either the path to access the state property, or if
 * the state is provided, returns the value of the correspondent field
 */
const getStateProperty = (publicName, state = false) => {
  if (!publicName) throw 'no query parameter was provided';

  function _getStatePropertyPath(obj, publicName, prevResult = '', ...keys) {
    const path = Object.entries(obj).reduce((result, [key, current]) => {
      if (!current.hasOwnProperty('publicName')) {
        result = _getStatePropertyPath(current, publicName, result, key);
      } else if (current.publicName === publicName) {
        result = [...keys, key];
      }

      return result;
    }, prevResult);

    if (!path && obj === storeSchema) throw 'searched parameter is not defined in schema';

    return path;
  }
  const propertyPath = _getStatePropertyPath(storeSchema, publicName);

  //if we didn't referenced the state, then we return the path to be used later
  if (!state) return propertyPath;

  // if we referenced the state object, we return directly the value for that path
  return propertyPath.reduce((obj, i) => obj[i], state);
};

export { storeSchema, getInitialValues, getStateMetaObject, getStateProperty };
