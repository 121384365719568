import React from 'react';
import { Col, Container, Row } from '@nutkit/component-grid';
import { ScrollToTop } from '@nm-utils-lib-web/scroll-to';
import { shape, string } from 'prop-types';
import { Customer } from '@nm-utils-lib-web/routes';
import { StartAJISA } from '@nm-portfolio-lib-web/start-a-jisa';
import { passBackUrlTo, getBackUrl } from '@nm-utils-lib-web/back-url';

import { isOnboarding } from '../../utils/isOnboarding';
import Header from '../Header';
import Footer from '../Footer';

export const CreateOrTransferJISA = ({ location }) => (
  <ScrollToTop>
    <Header />
    <Container>
      <Row>
        <Col md={{ size: '10', offset: '1' }} lg={{ size: '8', offset: '2' }}>
          <StartAJISA.OpenOrTransfer
            onNextClick={choice =>
              window.location.assign(
                passBackUrlTo(
                  `${Customer.JISA_CHILD_DETAILS_PATH}?onboarding=${isOnboarding(location)}&intent=${choice}`
                )
              )
            }
            onBackClick={() =>
              window.location.assign(getBackUrl() || `${Customer.JISA_ABOUT_PATH}?onboarding=${isOnboarding(location)}`)
            }
          />
        </Col>
      </Row>
    </Container>
    <Footer />
  </ScrollToTop>
);

CreateOrTransferJISA.propTypes = {
  location: shape({
    search: string
  }).isRequired
};
