import { NAVIGATION_EVENTS as NAVIGATION } from '../journey/constants';

import * as VIEWS from './views';

export const START_OR_TRANSFER_SISA_CATEGORY = 'Onboarding_SISA';
export const ISA_TRANSFER_CATEGORY = 'ISA Transfer';
export const TRANSFER_FULL_ISA_TRANSFER_SUBMIT_EVENT = 'Isa_Transfer_Clicked_Submit';
export const START_OR_TRANSFER_SISA_EVENT = 'SISA_Open_Or_Transfer';
export const SET_ALLOWANCE_SISA_EVENT = 'SISA_Set_Allowance';
export const TRANSFER_TYPE_EVENT = 'SISA_Transfer_Type';
export const TRANSFER_OPEN_ISA_EVENT = 'SISA_Transfer_Open_ISA';
export const TRANSFER_ANOTHER_ISA_EVENT = 'Isa_Transfer_Clicked_Transfer_Another_Isa';
export const GO_TO_DASHBOARD_LINK = 'ISA_transfer_go_to_dashboard_link';

export const VIEWS_TRACKING_NAVIGATION_EVENTS = {
  [NAVIGATION.CONTINUE]: {
    [VIEWS.OPEN_OR_TRANSFER]: {
      name: START_OR_TRANSFER_SISA_EVENT,
      category: START_OR_TRANSFER_SISA_CATEGORY,
      sendProperty: event => ({
        value: event.state.intent
      })
    },
    [VIEWS.SET_ALLOWANCE]: {
      name: SET_ALLOWANCE_SISA_EVENT,
      category: START_OR_TRANSFER_SISA_CATEGORY,
      sendProperty: event => ({ value: event.state.allowance })
    },
    [VIEWS.TRANSFER_TYPE]: {
      name: TRANSFER_TYPE_EVENT,
      category: START_OR_TRANSFER_SISA_CATEGORY,
      sendProperty: event => ({ value: event.state.transferType })
    },
    [VIEWS.TRANSFER_OPEN_ISA]: {
      name: TRANSFER_OPEN_ISA_EVENT,
      category: START_OR_TRANSFER_SISA_CATEGORY,
      sendProperty: event => ({ value: event.state.openISA })
    },
    [VIEWS.TRANSFER_FULL]: {
      name: TRANSFER_FULL_ISA_TRANSFER_SUBMIT_EVENT,
      category: ISA_TRANSFER_CATEGORY,
      sendProperty: event => ({
        currentProviderId: event.state.transferData.currentProviderId,
        valueOfIsa: event.state.transferData.valueOfIsa,
        contributionsThisYear: event.state.transferData.contributionsThisYear,
        onboarding: !!event.onboarding
      })
    },
    [VIEWS.TRANSFER_AUTO_SUCCESS]: {
      name: GO_TO_DASHBOARD_LINK,
      category: ISA_TRANSFER_CATEGORY
    }
  },
  [NAVIGATION.BACK]: {
    [VIEWS.TRANSFER_AUTO_SUCCESS]: {
      name: TRANSFER_ANOTHER_ISA_EVENT,
      category: ISA_TRANSFER_CATEGORY
    },
    [VIEWS.TRANSFER_PARTIAL]: {
      name: TRANSFER_ANOTHER_ISA_EVENT,
      category: ISA_TRANSFER_CATEGORY
    }
  }
};
