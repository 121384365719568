import ROUTES from '../constants/routes';

export const getViewFromRoute = route => {
  if (!route) throw 'No route provided';

  const view = Object.values(ROUTES).filter(({ path }) => route.includes(path));

  if (view.length === 0) throw 'View was not found';

  return view[0];
};

export default getViewFromRoute;
