import React from 'react';
import { PotsOverview } from '@nm-pot/overview';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { Heading, headingLevels } from '@nutkit/component-text';

const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const PensionTab = ({ t, isPresent, pots, updatePots, userUuid, onError, withHeading }) => {
  if (!isPresent) return null;

  return (
    <>
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__pension-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.pension.label`)}
        </Heading>
      )}
      <PotsOverview customerUuid={userUuid} pots={pots} onClosePot={updatePots} onError={onError} />
    </>
  );
};

PensionTab.propTypes = {
  t: func.isRequired,
  isPresent: bool.isRequired,
  pots: arrayOf(object).isRequired,
  updatePots: func.isRequired,
  userUuid: string.isRequired,
  onError: func,
  withHeading: bool
};

PensionTab.defaultProps = {
  withHeading: false,
  onError: undefined
};

export default PensionTab;
