import React from 'react';
import PropTypes from 'prop-types';
import { Customer } from '@nm-utils-lib-web/routes';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Heading, Text } from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { linkSizes, buttonCtas } from '@nutkit/component-link';
import Bold, { boldWeights } from '@nutkit/component-bold';
import { FIND_OUT_MORE_JISA } from '@nm-utils-lib-web/routes/supportArticles';
import Amount, { amountStyle } from '@nutkit/component-amount';
import { useGetCustomerDependantPersonalInformation } from '@nm-customer/common/hooks';
import useGetHeadroom from '@nm-portfolio-lib-web/common/hooks/useGetHeadroom';
import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import Loading from '@nutkit/component-loading';
import Section from '@nutkit/component-section';

import { productListEmptyProductClickedPayload } from '../../../../../tracking/events/productList';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.jisaTab';
const { DASHBOARD_HOST } = global.NutmegConfig;

export const Jisa = props => {
  const { userUuid } = props;
  const { t } = useTranslation();
  const { jisaDependants, isLoading } = useGetCustomerDependantPersonalInformation(userUuid);
  const { data: headroomData, error: headroomError } = useGetHeadroom({
    wrapperType: wrapperTypes.JISA,
    customerUuid: userUuid
  });
  const hasJisaDependants = jisaDependants && jisaDependants.length > 0;

  if (isLoading) return <Loading />;

  if (hasJisaDependants) return null;

  return (
    <Section stackSpacing="lg">
      <Panel data-qa="product-list__jisa-empty-state">
        <Heading level="3" noStack>
          {t(`${TRANSLATION_NAMESPACE}.emptyState.heading`)}
        </Heading>

        <Text>
          {headroomError
            ? t(`${TRANSLATION_NAMESPACE}.emptyState.description.error`)
            : !!headroomData && (
                <Trans
                  i18nKey={`${TRANSLATION_NAMESPACE}.emptyState.description.success`}
                  components={[
                    <Bold weight={boldWeights.BOLD}>
                      {' '}
                      <Amount
                        value={headroomData.currentDefaultAllowances.jisa}
                        style={amountStyle.CURRENCY}
                        bolderWeight
                        minDecimals={0}
                      />
                    </Bold>
                  ]}
                />
              )}{' '}
          <LinkWithTracking
            href={FIND_OUT_MORE_JISA}
            eventPayload={productListEmptyProductClickedPayload({ wrapperType: WRAPPER_TYPES.JISA })}
            aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.ariaLabel`)}
            size={linkSizes.MD}
            data-qa="product-list__jisa-empty-state__learn-more"
            isExternal
          >
            {t(`${TRANSLATION_NAMESPACE}.emptyState.learnMoreLink.label`)}
          </LinkWithTracking>
        </Text>

        <ButtonGroup align={buttonGroupAlignments.RIGHT} stackOnMobile>
          <LinkWithTracking
            href={`${DASHBOARD_HOST}${Customer.JISA_ABOUT_PATH}`}
            button
            buttonCta={buttonCtas.PRIMARY}
            aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.openOrTransfer.ariaLabel`)}
            size={linkSizes.SM}
            data-qa="product-list__jisa-empty-state__open-jisa"
            eventPayload={productListEmptyProductClickedPayload({
              option: 'open_or_transfer',
              wrapperType: WRAPPER_TYPES.JISA
            })}
          >
            {t(`${TRANSLATION_NAMESPACE}.emptyState.openOrTransfer.label`)}
          </LinkWithTracking>
        </ButtonGroup>
      </Panel>
    </Section>
  );
};

Jisa.propTypes = {
  userUuid: PropTypes.string.isRequired
};
