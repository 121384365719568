import { event } from '@nm-utils-lib-web/analytics';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';

export const trackAcceptedDeclaration = ({ potUuid, intent }) => {
  event({
    name: 'Pot_Confirm_Declaration_Continue',
    properties: {
      category: WRAPPER_TYPES.SISA,
      potUuid,
      intent
    }
  });
};
