import React from 'react';
import PropTypes from 'prop-types';
import { SupportArticles, Portfolio } from '@nm-utils-lib-web/routes';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Heading, Text } from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { linkSizes } from '@nutkit/component-link';
import Bold, { boldWeights } from '@nutkit/component-bold';
import Section from '@nutkit/component-section';

import { productListEmptyProductClickedPayload } from '../../../../../tracking/events/productList';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.lisaTab';
const { DASHBOARD_HOST } = global.NutmegConfig;

export const Lisa = props => {
  const { shouldShowLisaTransfersInTab } = props;
  const { t } = useTranslation();
  const dataQA = shouldShowLisaTransfersInTab
    ? 'product-list__lisa-empty-state-transfer'
    : 'product-list__lisa-empty-state-open';
  const OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE = shouldShowLisaTransfersInTab
    ? `${TRANSLATION_NAMESPACE}.emptyState.transfer`
    : `${TRANSLATION_NAMESPACE}.emptyState.open`;
  const createLISAPotLink = `${DASHBOARD_HOST}${Portfolio.PRODUCT_LISA_INFORMATION_PATH}`;

  return (
    <Section stackSpacing="lg">
      <Panel data-qa="product-list__lisa-empty-state">
        <Heading level="3" data-qa={`${dataQA}__title`} noStack>
          {t(`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.title`)}
        </Heading>

        <Text>
          <Trans
            i18nKey={`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.description`}
            components={[<Bold weight={boldWeights.BOLD} />]}
          />{' '}
          <LinkWithTracking
            data-qa="product-list__lisa-empty-state__learn-more"
            eventPayload={productListEmptyProductClickedPayload({ wrapperType: WRAPPER_TYPES.LISA })}
            isExternal
            href={SupportArticles.FIND_OUT_MORE_LISA}
          >
            {t(`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.learnMore`)}
          </LinkWithTracking>
        </Text>

        <ButtonGroup align={buttonGroupAlignments.RIGHT} stackUp stackOnMobile>
          <LinkWithTracking
            button
            buttonCta="primary"
            size={linkSizes.SM}
            aria-label={t(`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.link.ariaLabel`)}
            data-qa="product-list__lisa-empty-state__create-new"
            href={createLISAPotLink}
            eventPayload={productListEmptyProductClickedPayload({
              option: shouldShowLisaTransfersInTab ? 'transfer' : 'open',
              wrapperType: WRAPPER_TYPES.LISA
            })}
          >
            {t(`${OPEN_OR_TRANSFER_TRANSLATION_NAMESPACE}.link.label`)}
          </LinkWithTracking>
        </ButtonGroup>
      </Panel>
    </Section>
  );
};

Lisa.propTypes = {
  shouldShowLisaTransfersInTab: PropTypes.bool.isRequired
};
