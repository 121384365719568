export const FILTER_TYPES = {
  active: 'active',
  custom: 'custom',
  all: 'all'
};

export const CUSTOM_FILTER_TYPES = {
  single: 'single',
  multiple: 'multiple'
};
