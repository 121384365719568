import React from 'react';
import { arrayOf, func, string, object } from 'prop-types';
import { Heading } from '@nutkit/component-text';
import { PotsOverview } from '@nm-pot/overview';
import { useTranslation } from '@nm-utils-lib-web/translations';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.draftsSection';

const DraftPots = ({ drafts, userUuid, updateDrafts, onError }) => {
  const { t } = useTranslation();
  const wrapperTypes = Object.keys(drafts);
  const allDrafts = wrapperTypes.reduce((allDrafts, wrapperType) => {
    if (drafts[wrapperType].data.length) {
      return [...allDrafts, ...drafts[wrapperType].data];
    }

    return allDrafts;
  }, []);

  if (!allDrafts.length) return null;

  return (
    <>
      <Heading level="3" data-qa="product-list__drafts-title">
        {t(`${TRANSLATION_NAMESPACE}.heading`)}
      </Heading>
      <PotsOverview customerUuid={userUuid} drafts={allDrafts} updateDrafts={updateDrafts} onError={onError} />
    </>
  );
};

DraftPots.propTypes = {
  drafts: arrayOf(object).isRequired,
  userUuid: string.isRequired,
  updateDrafts: func.isRequired,
  onError: func
};

DraftPots.defaultProps = {
  onError: () => {}
};

export default DraftPots;
