import React from 'react';
import { bool } from 'prop-types';
import { OrganisationHeader } from '@nm-ui-shared-lib-web/organisation-header';
import ThemeProvider, { themeNames } from '@nutkit/theme';
import { Portfolio } from '@nm-utils-lib-web/routes';

import { getNonInvestorDashboardPath } from '../../utils/paths';

export const CustomerHeader = ({ isOnboarding }) => (
  <ThemeProvider themeName={themeNames.DARK}>
    <OrganisationHeader
      translationNameSpace="dashboard.common.uiSharedOrganisationHeader"
      logoHref={isOnboarding ? getNonInvestorDashboardPath() : Portfolio.DASHBOARD_PATH}
      showHelp
    />
  </ThemeProvider>
);

CustomerHeader.propTypes = {
  isOnboarding: bool
};

CustomerHeader.defaultProps = {
  isOnboarding: false
};

export default CustomerHeader;
