import getColorByCategory from './getColorByCategory';

const getAllocationConfiguration = (category, allocation, convertToPercent = true) => {
  const percentageMultiple = convertToPercent ? 100 : 1;

  return allocation
    .sort(({ percentage: firstPercentage }, { percentage: secondPercentage }) => secondPercentage - firstPercentage)
    .map(({ name, percentage }) => ({
      name,
      percentage: parseFloat((percentage * percentageMultiple).toFixed(2)),
      color: getColorByCategory(category, name)
    }));
};

export default getAllocationConfiguration;
