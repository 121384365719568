import React from 'react';
import { func, string, object, shape, oneOfType, bool } from 'prop-types';
import { Heading, headingLevels, Text } from '@nutkit/component-text';
import { List, ListItem } from '@nutkit/component-list';
import { RadioInput } from '@nutkit/component-radio';
import { OpenOrTransfer } from '@nm-portfolio-lib-web/common/components';
import { INTENT_OPTIONS } from '@nm-portfolio-lib-web/common/constants/IntentOptions';
import { iconColors } from '@nutkit/component-icon';
import { Flags, useVariant, Variants } from '@nm-utils-lib-web/flags';

const TRANSLATION_NAMESPACE = 'start.openOrTransferSISA';
const listStyle = {
  tagName: 'ul',
  bulletColor: iconColors.SUCCESS,
  isIndented: false
};

const OpenOrTransferSISA = props => {
  const { t, 'data-qa': dataQa, intent, setIntent, journeyNavigation } = props;
  const isaSettingsVariant = useVariant(Flags.ISA_SETTINGS_TEST);
  const VARIANT_NAMESPACE =
    isaSettingsVariant?.name === Variants.VARIANT_B
      ? `${TRANSLATION_NAMESPACE}.${isaSettingsVariant.name}`
      : TRANSLATION_NAMESPACE;
  const title = t(`${VARIANT_NAMESPACE}.headers.title`);
  const subTitle = t(`${VARIANT_NAMESPACE}.headers.subTitle`);
  const transferLabel = t(`${VARIANT_NAMESPACE}.content.transferLabel`);
  const transferDescription = t(`${VARIANT_NAMESPACE}.content.transferDescription`);
  const startLabel = t(`${VARIANT_NAMESPACE}.content.startLabel`);
  const startDescription = t(`${VARIANT_NAMESPACE}.content.startDescription`);
  const taxTreatmentDescription = t(`${TRANSLATION_NAMESPACE}.content.taxTreatments`);
  const getMainDescription = element => t(`${TRANSLATION_NAMESPACE}.content.description.${element}`);
  const openChoice = (
    <RadioInput value={INTENT_OPTIONS.OPEN} label={startLabel} data-qa={`${dataQa}-select-step-open`}>
      {startDescription}
    </RadioInput>
  );
  const transferChoice = (
    <RadioInput value={INTENT_OPTIONS.TRANSFER} label={transferLabel} data-qa={`${dataQa}-select-step-transfer`}>
      <Text noStack>{transferDescription}</Text>
    </RadioInput>
  );
  const journeyControls = { ...journeyNavigation, backButton: null };
  const commonProps = {
    intent,
    ...(isaSettingsVariant?.name === Variants.VARIANT_B
      ? { firstChoice: transferChoice, secondChoice: openChoice }
      : { firstChoice: openChoice, secondChoice: transferChoice }),
    setIntent,
    journeyControls,
    subTitle
  };

  return (
    <OpenOrTransfer heading={title} data-qa={dataQa} {...commonProps}>
      <Heading level={headingLevels.THREE}>{getMainDescription('headline')}</Heading>
      <List {...listStyle}>
        <ListItem>{getMainDescription('AllowanceInfo')}</ListItem>
        <ListItem>{getMainDescription('ExceedAllowanceInfo')}</ListItem>
      </List>
      <Text noStack>{taxTreatmentDescription}</Text>
    </OpenOrTransfer>
  );
};

OpenOrTransferSISA.propTypes = {
  journeyNavigation: shape({
    continueButton: object,
    backButton: object,
    showErrorMessage: oneOfType([bool, string])
  }).isRequired,
  setIntent: func.isRequired,
  t: func.isRequired,
  intent: string,
  'data-qa': string
};

OpenOrTransferSISA.defaultProps = {
  'data-qa': 'open-or-transfer-sisa',
  intent: null
};

export default OpenOrTransferSISA;
