import { useMachine } from '@xstate/react';

import journeyMachine from '../journeyMachine';

import useBrowserHistoryMachine from './useBrowserHistoryMachine';

function useJourneyMachine(context) {
  const machine = useMachine(journeyMachine, { context });
  const [current, send] = machine;

  useBrowserHistoryMachine({ current, send, context });

  return machine;
}

export default useJourneyMachine;
