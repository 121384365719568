import noMatchLanguage from '@nm-utils-lib-web/no-match/languages/johnlewisfinance-gbr.json';
// eslint-disable-next-line max-len
import { organisationHeaderTranslations } from '@nm-ui-shared-lib-web/organisation-header/languages/johnlewisfinance-gbr';
// eslint-disable-next-line max-len
import { organisationFooterTranslations } from '@nm-ui-shared-lib-web/organisation-footer/languages/johnlewisfinance-gbr';
import { johnlewisfinanceGBR as riskQuestionnaire } from '@nm-customer/risk-questionnaire/languages/';
import { johnlewisfinanceGBR as riskProfile } from '@nm-customer/risk-profile/languages/';

import common from './common.json';
import isaAllowance from './isa-allowance.json';
import isaSettings from './isa-settings.json';

export default {
  dashboard: {
    common: {
      ...common,
      ...organisationHeaderTranslations,
      ...organisationFooterTranslations,
      ...noMatchLanguage
    },
    isaAllowance,
    isaSettings
  },
  ...riskQuestionnaire,
  ...riskProfile
};
