import React, { useState } from 'react';
import { func, string, arrayOf, object } from 'prop-types';
import Notification from '@nutkit/component-notification';
import { event } from '@nm-utils-lib-web/analytics';
import Section, { stackSpacing } from '@nutkit/component-section';
import Button, { buttonCtaTypes } from '@nutkit/component-button';
import { cancelPendingTransfers } from '@nm-payments/intra-pot-transfer/services/potTransferService';

import CancelPendingTransfersModal from '../CancelPendingTransfersModal';
import { reallocateMoneyClickedPayload } from '../../../../tracking/events/productList';
import { INTRA_FUND_TRANSFER } from '../../constants/WrapperTransactions';
import { TRANSLATION_KEYS } from '../../constants/PendingTransfers';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.pendingTransfers';

const getTranslationKeyForPendingTransferOrMultiple = (pots, filterFn, key) => {
  const filteredPots = pots.filter(filterFn);

  if (filteredPots.length === 1) {
    return key;
  }

  if (filteredPots.length > 1) {
    return TRANSLATION_KEYS.MULTIPLE;
  }

  return false;
};

export const getTranslationKeyForAllPendingTransfers = pots => {
  const pendingInvestmentsKey = getTranslationKeyForPendingTransferOrMultiple(
    pots,
    pot => pot.pending.investments > 0,
    TRANSLATION_KEYS.INVESTMENTS
  );
  const pendingTransfersKey = getTranslationKeyForPendingTransferOrMultiple(
    pots,
    pot => pot.pending.transfers > 0,
    TRANSLATION_KEYS.TRANSFERS
  );
  const pendingIntraFundTransferKey = getTranslationKeyForPendingTransferOrMultiple(
    pots,
    pot => pot.pending.wrapperTransactions?.includes(INTRA_FUND_TRANSFER),
    TRANSLATION_KEYS.INTRA_FUND_TRANSFER
  );
  const allPendingTransfersKeys = [pendingInvestmentsKey, pendingTransfersKey, pendingIntraFundTransferKey].filter(
    Boolean
  );

  if (allPendingTransfersKeys.length === 0) {
    return null;
  }

  if (allPendingTransfersKeys.length === 1) {
    return allPendingTransfersKeys[0];
  }

  return TRANSLATION_KEYS.MULTIPLE;
};

export const PendingTransfers = ({ onCancel, userUuid, t, pots }) => {
  const [isModalOpened, setModalOpened] = useState(false);
  const openModal = () => setModalOpened(true);
  const closeModal = () => setModalOpened(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelError, setCancelError] = useState(false);
  const translationKey = getTranslationKeyForAllPendingTransfers(pots);

  const submitCancelling = async () => {
    try {
      setCancelError(false);
      setCancelLoading(true);

      await cancelPendingTransfers({ userUuid });

      closeModal();
      onCancel();
    } catch (error) {
      setCancelError(true);
      setCancelLoading(false);
    }
  };

  if (translationKey === null) {
    return null;
  }

  return (
    <>
      <Notification dismissable={false}>
        <Section stackSpacing={stackSpacing.NONE}>
          {t(`${TRANSLATION_NAMESPACE}.notification.${translationKey}`)}
        </Section>
        <Button
          data-qa="pending-transfers__notification__open-cancel-modal-button"
          cta={buttonCtaTypes.LINK}
          // TODO: remove inline styles as soon as they are added for link-like buttons inside Nutkit notification
          style={{ textDecoration: 'underline', color: 'inherit' }}
          onClick={() => {
            event(reallocateMoneyClickedPayload());
            openModal();
          }}
        >
          {t(`${TRANSLATION_NAMESPACE}.button.${translationKey}`)}
        </Button>
      </Notification>
      <CancelPendingTransfersModal
        isOpen={isModalOpened}
        isLoading={cancelLoading}
        isError={cancelError}
        onSubmit={submitCancelling}
        closeModal={closeModal}
        translationKey={translationKey}
      />
    </>
  );
};

PendingTransfers.propTypes = {
  onCancel: func.isRequired,
  userUuid: string.isRequired,
  t: func.isRequired,
  pots: arrayOf(object).isRequired
};

export default PendingTransfers;
