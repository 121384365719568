// These are nampespaces for the views - A view represents a specific screen
// that can be loaded in many paths upon different rules.
export const OPEN_OR_TRANSFER = 'OPEN_OR_TRANSFER';
export const SET_ALLOWANCE = 'SET_ALLOWANCE';
export const DECLARATION = 'DECLARATION';
export const TRANSFER_TYPE = 'TRANSFER_TYPE';
export const TRANSFER_OPEN_ISA = 'TRANSFER_OPEN_ISA';
export const TRANSFER_FULL = 'TRANSFER_FULL';
export const TRANSFER_PARTIAL = 'TRANSFER_PARTIAL';
export const TRANSFER_AUTO_SUCCESS = 'TRANSFER_AUTO_SUCCESS';
export const REACT_POT_CREATION = 'REACT_POT_CREATION';
