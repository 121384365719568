import { isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';
import { SupportArticles } from '@nm-utils-lib-web/routes';

//Todo: These constants are the same as in payments-lib-web (https://github.com/nutmegdevelopment/nm-payments-lib-web/blob/master/packages/isa-transfer/constants/UrlConstants.js), so should be refactored so they both consume these from utils-lib-web
export const ISA_TRANSFER_HELP_ARTICLE = isJohnLewisFinanceGBR()
  ? SupportArticles.JOHN_LEWIS_FINANCE_FAQ
  : 'https://support.nutmeg.com/hc/en-us/articles/115000361952-Before-you-transfer-an-ISA-to-Nutmeg';
export const TERMS_AND_CONDITIONS = isJohnLewisFinanceGBR()
  ? 'https://investments.johnlewisfinance.com/legal/terms-and-conditions'
  : 'https://www.nutmeg.com/legal/terms-and-conditions';
export const ISA_TERMS_AND_CONDITIONS = isJohnLewisFinanceGBR()
  ? 'https://investments.johnlewisfinance.com/legal/terms-and-conditions#isa-terms'
  : 'https://www.nutmeg.com/legal/terms-and-conditions#24-17-terms-applying-to-isa';
