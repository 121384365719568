import { matchPath } from 'react-router';
import { useGetJourneysHome } from '@nm-customer/journeys/hooks/useGetJourneysHome';

const isRoot = ({ host, pathname }) => host === window.location.host && pathname === '/';
const getHrefWithoutQuery = urlData => (urlData.search ? urlData.href.replace(urlData.search, '') : urlData.href);
const isCurrentPath = urlData => getHrefWithoutQuery(urlData) === getHrefWithoutQuery(window.location);
const isSubPath = urlData => getHrefWithoutQuery(window.location).includes(`${getHrefWithoutQuery(urlData)}/`);
const isSameQuery = urlData => urlData.search === window.location.search;
const shouldRedirectBasedOnQueryParams = urlData => !!urlData.search && !isSameQuery(urlData);
const shouldIgnoreCheck = routesToIgnore =>
  routesToIgnore.some(route =>
    matchPath(window.location.pathname, typeof route === 'string' ? { path: route } : route)
  );

const useCustomerAccess = (customerUuid, routesToIgnore = []) => {
  const { url, isLoading, error } = useGetJourneysHome(customerUuid, {
    shouldMakeRequest: !shouldIgnoreCheck(routesToIgnore)
  });

  if (!url) {
    return {
      isLoading,
      isRedirecting: false,
      error
    };
  }

  const urlData = new URL(url);

  if (isRoot(urlData) || isSubPath(urlData) || (isCurrentPath(urlData) && !shouldRedirectBasedOnQueryParams(urlData))) {
    return {
      isLoading: false,
      isRedirecting: false,
      error
    };
  }

  window.location.replace(url);

  return {
    isLoading: false,
    isRedirecting: true,
    error
  };
};

export default useCustomerAccess;
