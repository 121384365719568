import React, { useState } from 'react';
import { arrayOf, shape, string, number, func, bool, node } from 'prop-types';
import { AccordionWithHeader } from '@nutkit/component-accordion';
import Button, { buttonCtaTypes, buttonSizes } from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Notification, { notificationLevels } from '@nutkit/component-notification';

import InvestmentListItem from '../InvestmentListItem';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDetails.portfolioInvestments';
const DEFAULT_MAXIMUM_INVESTMENTS_TO_SHOW = 5;

const InvestmentList = ({
  investments,
  onListExpand,
  openAllHandler,
  investmentLimitCount,
  isActual,
  title,
  onInvestmentClick,
  error
}) => {
  const { t } = useTranslation();
  const totalInvestmentCount = investments.length;
  const showAllInvestments = totalInvestmentCount > investmentLimitCount;
  const [isExpanded, toggleExpand] = useState(false);
  let investmentsToDisplay = investments.sort(
    ({ allocation: firstAllocation }, { allocation: secondAllocation }) => secondAllocation - firstAllocation
  );

  if (showAllInvestments && isExpanded === false) {
    investmentsToDisplay = investments.slice(0, investmentLimitCount);
  }

  if (error) {
    return (
      <Notification dismissable={false} level={notificationLevels.ERROR}>
        {t(`${TRANSLATION_NAMESPACE}.error`)}
      </Notification>
    );
  }

  return (
    <>
      <AccordionWithHeader
        striped
        openAllLabel={t(`${TRANSLATION_NAMESPACE}.openAllLabel`)}
        closeAllLabel={t(`${TRANSLATION_NAMESPACE}.closeAllLabel`)}
        openAllHandler={openAllHandler}
        title={title}
      >
        {investmentsToDisplay.map((investment, index) => (
          <InvestmentListItem
            forceFocus={index === investmentLimitCount}
            key={`investment-list-item-${investment.code}`}
            isActual={isActual}
            onInvestmentClick={onInvestmentClick}
            {...investment}
          />
        ))}
      </AccordionWithHeader>
      {showAllInvestments && (
        <ButtonGroup align={buttonGroupAlignments.RIGHT}>
          <Button
            size={buttonSizes.SM}
            cta={buttonCtaTypes.LINK}
            onClick={e => {
              onListExpand && onListExpand(e);
              toggleExpand(!isExpanded);
            }}
          >
            {isExpanded ? t(`${TRANSLATION_NAMESPACE}.showLessLabel`) : t(`${TRANSLATION_NAMESPACE}.showMoreLabel`)}
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

InvestmentList.propTypes = {
  investments: arrayOf(
    shape({
      code: string.isRequired,
      allocation: number.isRequired,
      name: string.isRequired,
      description: string.isRequired,
      actualValues: shape({})
    })
  ).isRequired,
  investmentLimitCount: number,
  onListExpand: func,
  openAllHandler: func,
  isActual: bool,
  title: node,
  onInvestmentClick: func,
  error: shape({ message: string })
};

InvestmentList.defaultProps = {
  investmentLimitCount: DEFAULT_MAXIMUM_INVESTMENTS_TO_SHOW,
  onListExpand: null,
  openAllHandler: null,
  isActual: false,
  title: null,
  onInvestmentClick: null,
  error: null
};

export default InvestmentList;
