import React from 'react';
import NoMatch from '@nm-utils-lib-web/no-match';
import { Layout } from '@nutkit/layouts';
import { ScrollToTop } from '@nm-utils-lib-web/scroll-to';

import CustomerHeader from '../Header';
import Footer from '../Footer';

const NoMatch404 = props => {
  return (
    <ScrollToTop>
      <CustomerHeader />
      <Layout>
        <NoMatch {...props} translationNamespace="dashboard.common.page404" />
      </Layout>
      <Footer />
    </ScrollToTop>
  );
};

export default NoMatch404;
