import useSWR from 'swr';
import { getSWRIsLoading } from '@nm-utils-lib-web/network/swr';

import { getSystemPots } from '../../services/PotViewService/potViewService';

export const useGetSystemPots = ({
  customerUuid,
  type,
  onError,
  onSuccess,
  shouldMakeRequest = true,
  swrOptions = {}
} = {}) => {
  const cacheKey = `useGetSystemPots/${customerUuid}/system_pots/${type}`;
  const { data, error } = useSWR(shouldMakeRequest ? cacheKey : null, () => getSystemPots({ customerUuid, type }), {
    revalidateOnFocus: false,
    onSuccess: data => onSuccess && onSuccess(data),
    onError: error => onError && onError(error),
    ...swrOptions
  });
  const isLoading = getSWRIsLoading(data, error, shouldMakeRequest);

  return {
    data,
    isLoading,
    error
  };
};

export default useGetSystemPots;
