import React from 'react';
import { SupportArticles, Pension } from '@nm-utils-lib-web/routes';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Heading, Text } from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { JOURNEY_TYPES, POT_ID, WRAPPER_TYPES } from '@nm-pot/common/constants';
import { linkSizes } from '@nutkit/component-link';
import Bold, { boldWeights } from '@nutkit/component-bold';
import Section from '@nutkit/component-section';

import { productListEmptyProductClickedPayload } from '../../../../../tracking/events/productList';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.pensionTab';

export const Pensions = () => {
  const { t } = useTranslation();

  return (
    <Section stackSpacing="lg">
      <Panel data-qa="product-list__pension-empty-state">
        <Heading level="3" noStack>
          {t(`${TRANSLATION_NAMESPACE}.emptyState.title`)}
        </Heading>

        <Text>
          <Trans
            i18nKey={`${TRANSLATION_NAMESPACE}.emptyState.description`}
            components={[<Bold weight={boldWeights.BOLD} />]}
          />{' '}
          <LinkWithTracking
            data-qa="product-list__pension-empty-state__learn-more"
            isExternal
            href={SupportArticles.FIND_OUT_MORE_PENSION}
            eventPayload={productListEmptyProductClickedPayload({ wrapperType: WRAPPER_TYPES.PENSION })}
          >
            {t(`${TRANSLATION_NAMESPACE}.emptyState.learnMore`)}
          </LinkWithTracking>
        </Text>

        <ButtonGroup align={buttonGroupAlignments.RIGHT} stackUp stackOnMobile>
          <LinkWithTracking
            button
            buttonCta="primary"
            size={linkSizes.SM}
            aria-label={t(`${TRANSLATION_NAMESPACE}.emptyState.link.ariaLabel`)}
            data-qa="product-list__pension-empty-state__create-new"
            href={`${global.NutmegConfig.DASHBOARD_HOST}${Pension.getPensionPotJourneyPath({
              potUuid: POT_ID.NEW_POT_UUID,
              journeyType: JOURNEY_TYPES.CREATE_POT
            })}`}
            eventPayload={productListEmptyProductClickedPayload({
              option: 'open_or_transfer',
              wrapperType: WRAPPER_TYPES.PENSION
            })}
          >
            {t(`${TRANSLATION_NAMESPACE}.emptyState.link.label`)}
          </LinkWithTracking>
        </ButtonGroup>
      </Panel>
    </Section>
  );
};
