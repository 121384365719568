import ROUTES from '../constants/routes';

export const getTheRoute = (baseUrl, view) => {
  if (!ROUTES.hasOwnProperty(view)) throw 'view was not found in provided routes';
  if (!ROUTES[view].hasOwnProperty('path')) throw `path was not found in ${view} view`;

  const path = ROUTES[view].path;

  return baseUrl ? `${baseUrl}${path}` : path;
};

export default getTheRoute;
