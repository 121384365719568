import handleError from '../errorHandler';

import basePotHoldingsService from './basePotHoldingsService';

export const getPortfolioAllocationBreakdown = async ({ customerUuid }) => {
  const URL = `/customers/${customerUuid}/allocation_breakdown`;

  try {
    const { data } = await basePotHoldingsService.get(URL);

    return data;
  } catch (error) {
    handleError(error);
  }
};
