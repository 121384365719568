import React from 'react';
import { PotsOverview } from '@nm-pot/overview';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { Heading, headingLevels } from '@nutkit/component-text';

const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const LISATab = ({ t, isPresent, pots, drafts, updatePots, updateDrafts, userUuid, onError, withHeading }) => {
  const visibleDrafts = isPresent ? [] : drafts;
  const isPotsAvailable = pots.length > 0 || visibleDrafts.length > 0;

  if (!isPotsAvailable) {
    return null;
  }

  return (
    <>
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__lisa-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.lisa.label`)}
        </Heading>
      )}
      <PotsOverview
        customerUuid={userUuid}
        pots={pots}
        drafts={visibleDrafts}
        onClosePot={updatePots}
        updateDrafts={updateDrafts}
        onError={onError}
      />
    </>
  );
};

LISATab.propTypes = {
  t: func.isRequired,
  isPresent: bool.isRequired,
  withHeading: bool,
  pots: arrayOf(object).isRequired,
  updatePots: func.isRequired,
  userUuid: string.isRequired,
  onError: func,
  drafts: arrayOf(object),
  updateDrafts: func.isRequired
};

LISATab.defaultProps = {
  drafts: [],
  withHeading: false,
  onError: undefined
};

export default LISATab;
