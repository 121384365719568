import React, { useMemo } from 'react';
import { arrayOf, func, object, bool, string } from 'prop-types';
import { PotsOverview } from '@nm-pot/overview';
import { Heading } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { isActivePot } from '@nm-pot/common/helpers';

import PendingWithdrawal from '../PendingWithdrawal';
import PendingTransfers from '../PendingTransfers';
import { OUTGOING_GIA_WITHDRAWAL, OUTGOING_ISA_WITHDRAWAL } from '../../constants/WrapperTransactions';
import SystemPotOverview from '../SystemPotOverview/SystemPotOverview';

const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const IncomeTab = ({ pots, systemPot, updatePots, onError, userUuid, withGroupHeading }) => {
  const { t } = useTranslation();
  const pendingWithdrawalPots = pots.filter(({ pending: { withdrawals } }) => withdrawals > 0);
  const totalPendingWithdrawals = pendingWithdrawalPots
    .map(pot => pot.pending.withdrawals)
    .reduce((prev, next) => prev + next, 0);
  const hasPendingWrapperWithdrawals = useMemo(() => {
    return pots.some(pot => {
      const wrapperTransactions = pot && pot.pending && pot.pending.wrapperTransactions;

      return (
        wrapperTransactions &&
        (wrapperTransactions.includes(OUTGOING_ISA_WITHDRAWAL) || wrapperTransactions.includes(OUTGOING_GIA_WITHDRAWAL))
      );
    });
  }, [pots]);
  const hasPendingWithdrawals = pendingWithdrawalPots.length > 0 || hasPendingWrapperWithdrawals;
  const hasActiveSystemPot = systemPot && isActivePot(systemPot.status);

  return (
    <>
      {withGroupHeading && (
        <Heading level="3" data-qa="product-list__income-title">
          {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.income.label`)}
        </Heading>
      )}
      {hasPendingWithdrawals && (
        <PendingWithdrawal
          pendingWithdrawalPots={pendingWithdrawalPots}
          totalPendingWithdrawals={totalPendingWithdrawals}
          updatePots={updatePots}
          userUuid={userUuid}
          hasPendingWrapperWithdrawals={hasPendingWrapperWithdrawals}
          data-qa="product-list__income-tab__pending-withdrawal"
        />
      )}
      <PendingTransfers userUuid={userUuid} onCancel={updatePots} pots={pots} />
      {hasActiveSystemPot && <SystemPotOverview systemPot={systemPot} />}
      {!!pots.length && <PotsOverview customerUuid={userUuid} pots={pots} onClosePot={updatePots} onError={onError} />}
    </>
  );
};

IncomeTab.propTypes = {
  pots: arrayOf(object).isRequired,
  systemPot: object,
  updatePots: func.isRequired,
  onError: func,
  userUuid: string.isRequired,
  withGroupHeading: bool
};

IncomeTab.defaultProps = {
  onError: undefined,
  withGroupHeading: false,
  systemPot: undefined
};

export default IncomeTab;
