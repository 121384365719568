import React from 'react';
import { func, string } from 'prop-types';
import Notification, { notificationLevels, notificationSizes } from '@nutkit/component-notification';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import useGetPots from '@nm-pot/common/hooks/useGetPots';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';

const TRANSLATION_NAMESPACE = 'dashboard.common.banner.welcome';

const isNewJISACustomer = pots => {
  if (!pots || pots.length !== 1) {
    return null;
  }

  const MINUTES_TO_SHOW_BANNER = 30;
  const dateTimeCreatedAt = parseISO(pots[0].createdAt);
  const dateTimeToShowBannerUntil = addMinutes(dateTimeCreatedAt, MINUTES_TO_SHOW_BANNER);
  const dateTimeNow = new Date();

  return isBefore(dateTimeNow, dateTimeToShowBannerUntil);
};

const WelcomeBanner = ({ customerUuid, t }) => {
  const { data: pots } = useGetPots({ customerUuid, wrapper: WRAPPER_TYPES.JISA });

  return (
    isNewJISACustomer(pots) && (
      <Notification dismissable={false} level={notificationLevels.INFORMATION} size={notificationSizes.XS}>
        {t(`${TRANSLATION_NAMESPACE}.text`)}
      </Notification>
    )
  );
};

WelcomeBanner.propTypes = {
  customerUuid: string.isRequired,
  t: func.isRequired
};

export default WelcomeBanner;
