import { handleServerError } from '@nm-portfolio-lib-web/common/utils';

import basePerformanceService from '../basePerformanceService';

export const getCustomerPerformanceSummary = async ({ customerUuid, timeframe, fromDate, toDate, indicatorType }) => {
  const URL = `/customers/${customerUuid}/performance/summary`;

  try {
    const { data } = await basePerformanceService.get(URL, {
      params: {
        ...(timeframe && { offset_type: timeframe.join(',') }),
        ...(fromDate && { offset_start: fromDate }),
        ...(toDate && { offset_end: toDate }),
        ...(indicatorType && { type: indicatorType.join(',') })
      }
    });

    return data;
  } catch (error) {
    handleServerError(error);
  }
};
