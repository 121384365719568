export const TIMEFRAME_OFFSETS = {
  THIRTY_DAYS: '30D',
  ONE_MONTH: '1M',
  THREE_MONTHS: '3M',
  SIX_MONTHS: '6M',
  ONE_YEAR: '1Y',
  CURRENT_YEAR_TO_DATE: 'CYTD',
  CUSTOM: 'CUSTOM',
  ALL: 'ALL'
};

export const PERFORMANCE_INDICATORS = {
  SR: 'SR',
  TWRR: 'TWRR'
};
