import { event as trackEvent } from '@nm-utils-lib-web/analytics';

import * as PORTFOLIO_DIG_DEEPER_EVENT_TYPES from './eventTypes';

const {
  DIG_DEEPER_VIEW,
  SELECT_PERFORMANCE_TIMEFRAME,
  DIG_DEEPER_ALLOCATION_TYPE,
  DIG_DEEPER_INVESTMENTS_SHOW_MORE_LESS
} = PORTFOLIO_DIG_DEEPER_EVENT_TYPES;
const DIG_DEEPER_LOCATION = 'Portfolio Dig Deeper';
const POT_TYPE = 'portfolio';

export const eventMap = {
  [DIG_DEEPER_VIEW]: ({ event }) =>
    trackEvent({
      name: DIG_DEEPER_VIEW,
      properties: {
        location: DIG_DEEPER_LOCATION,
        view: event
      }
    }),
  [SELECT_PERFORMANCE_TIMEFRAME]: ({ timeFrame }) =>
    trackEvent({
      name: SELECT_PERFORMANCE_TIMEFRAME,
      properties: {
        location: DIG_DEEPER_LOCATION,
        timeFrame,
        potType: POT_TYPE
      }
    }),
  [DIG_DEEPER_ALLOCATION_TYPE]: ({ name }) =>
    trackEvent({
      name: DIG_DEEPER_ALLOCATION_TYPE,
      properties: {
        location: DIG_DEEPER_LOCATION,
        allocationType: name,
        potType: POT_TYPE
      }
    }),
  [DIG_DEEPER_INVESTMENTS_SHOW_MORE_LESS]: () =>
    trackEvent({
      name: DIG_DEEPER_INVESTMENTS_SHOW_MORE_LESS,
      properties: {
        location: DIG_DEEPER_LOCATION
      }
    })
};

export default eventMap;
