import * as analytics from '@nm-utils-lib-web/analytics';

export const eventNames = {
  UPDATE_CURRENT_ISA_ALLOWANCE: 'UPDATE_CURRENT_ISA_ALLOWANCE',
  UPDATE_NEXT_ISA_ALLOWANCE: 'UPDATE_NEXT_ISA_ALLOWANCE',
  UPDATE_ISA_ALLOWANCE_CHANGES: 'UPDATE_ISA_ALLOWANCE_CHANGES',
  CLICK_PAY_IN_ISA_ALLOWANCE: 'CLICK_PAY_IN_ISA_ALLOWANCE'
};
export const journeyEvents = {
  UPDATE_CURRENT_ISA_ALLOWANCE: 'Update_Current_Isa_Allowance',
  UPDATE_NEXT_ISA_ALLOWANCE: 'Update_Next_Isa_Allowance',
  CANCEL_CURRENT_ISA_ALLOWANCE: 'Cancel_Current_Isa_Allowance',
  CANCEL_NEXT_ISA_ALLOWANCE: 'Cancel_Next_Isa_Allowance',
  ALLOWANCE_PAYIN: 'Allowance_Payin'
};

export const ISA_ALLOWANCE_TRACKING_CATEGORY = 'isa-allowance';

const DEFAULT_PROPERTIES = {
  category: ISA_ALLOWANCE_TRACKING_CATEGORY
};

const trackUpdateCurrentIsaAllowance = ({ eventProperties }) => {
  analytics.event({
    name: journeyEvents.UPDATE_CURRENT_ISA_ALLOWANCE,
    properties: { ...DEFAULT_PROPERTIES, eventProperties }
  });
};

const trackUpdateNextIsaAllowance = ({ eventProperties }) => {
  analytics.event({
    name: journeyEvents.UPDATE_NEXT_ISA_ALLOWANCE,
    properties: { ...DEFAULT_PROPERTIES, eventProperties }
  });
};

const trackCancelUpdateCurrentIsaAllowance = ({ eventProperties }) => {
  analytics.event({
    name: journeyEvents.CANCEL_CURRENT_ISA_ALLOWANCE,
    properties: { ...DEFAULT_PROPERTIES, eventProperties }
  });
};

const trackCancelUpdateNextIsaAllowance = ({ eventProperties }) => {
  analytics.event({
    name: journeyEvents.CANCEL_NEXT_ISA_ALLOWANCE,
    properties: { ...DEFAULT_PROPERTIES, eventProperties }
  });
};

export {
  trackUpdateCurrentIsaAllowance,
  trackUpdateNextIsaAllowance,
  trackCancelUpdateCurrentIsaAllowance,
  trackCancelUpdateNextIsaAllowance
};
