import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import ProgressBar from '@nutkit/component-progress-bar';

const PortfolioAllocationDisplay = ({ allocation }) => {
  return allocation.map(({ color, name, percentage }) => (
    <ProgressBar
      color={color}
      key={name}
      labelLeft={name}
      labelRight={`${percentage}%`}
      value={percentage}
      aria-label={`${name} ${percentage}%`}
      role="meter" // eslint-disable-line jsx-a11y/aria-role
    />
  ));
};

PortfolioAllocationDisplay.propTypes = {
  allocation: arrayOf(shape({ color: string, name: string, value: number })).isRequired
};

export default PortfolioAllocationDisplay;
