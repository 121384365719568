import { usePrismic } from '@nm-marketing/react-utils';

import { getBannerDashboard } from '../services/PrismicService/PrismicService';

const useGetBannerDashboardFromPrismic = ({ onError }) => {
  const { isLoading, error, response: bannerDashboardResponse } = usePrismic(getBannerDashboard);

  if (error) {
    onError && onError(error);
  }

  return {
    bannerDashboard: bannerDashboardResponse && bannerDashboardResponse.data && bannerDashboardResponse.data.body,
    isLoading,
    error
  };
};

export default useGetBannerDashboardFromPrismic;
