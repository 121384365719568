import { getTokens } from '@nutkit/react-utils';

import { ALLOCATION_CATEGORIES } from '../constants';

import getAssetColor from './getAssetColor';

const getColorByCategory = (category, name) =>
  category === ALLOCATION_CATEGORIES.ASSET ? getAssetColor(name) : getTokens().NK_ALLOCATION_OTHER_COLOR;

export default getColorByCategory;
